'use strict';

import React, { Component } from 'react';
import classNames from 'classnames';
import FontAwesomeIcon from '../components/FontAwesomeIcon';

import StarsRating from '../components/StarsRating';
import BtnPrint from '../components/BtnPrint';

export default class DemandCheck2 extends Component {
    constructor(props) {
        super(props);

        // initialize all demands with false
        var demands = {};
        var mouseOvers = {};
        props.demands.forEach(group =>
            Object.keys(group.items).forEach(id => {
                demands[id] = false;
                mouseOvers[id] = false;
            })
        );
        this.state = {
            demands,
            mouseOvers,
            openProductId: null,
            allOpen: false,
        };
    }

    toggleDemand(id) {
        let { demands } = this.state;
        demands[id] = !demands[id];

        this.setState({
            demands,
        });
    }

    mouseOver(id, isEnter = true) {
        let { mouseOvers } = this.state;
        mouseOvers[id] = isEnter;

        this.setState({ mouseOvers });
    }

    toggleOpen(productId) {
        this.setState({
            openProductId: this.state.openProductId === productId ? null : productId,
            allOpen: false,
        });
    }

    toggleAllOpen() {
        this.setState({
            allOpen: !this.state.allOpen,
            openProductId: null,
        });
    }

    /**
     * Checks if a passed product is displayed at the moment
     *
     * @param product
     */
    isProductEnabled(product) {
        if (null === product.on) {
            return false;
        }
        const expression = product.on
            // replace all illegal things (prevents injection)
            .replace(/[^a-z0-9_() -]+/gi, '')
            // replace conditionals
            .replace(/\b(AND|UND)\b/gi, '&&')
            .replace(/\b(OR|ODER)\b/gi, '||')
            .replace(/\b(NOT|NICHT)\b/gi, '!')
            // replace demands with replacements from state
            .replace(/(\w+)/gi, "this.state.demands['$1']");

        return eval(expression);
    }

    render() {
        const { demands, products, groups } = this.props;

        const availableProducts = Object.keys(products).filter(productId => this.isProductEnabled(products[productId]));

        const options = {
            withLines: false,
            ...this.props.options,
            colors: {
                backgroundHeader: '#eee',
                backgroundContent: '#fff',
                iconDefault: '#ccc',
                iconHighlight: '#ff6600',
                tabHeaderDefault: '#0066b3',
                tabHeaderHighlight: '#ff6600',
                ...this.props.options.colors,
            },
        };

        return (
            <div className={classNames('demand-check2', { 'with-lines': options.withLines })}>
                <div className="d-none d-print-block">
                    <div className="row">
                        {demands.map(group => {
                            return (
                                <div key={group.name} className={'col-' + 12 / demands.length}>
                                    <h3>{group.name}</h3>
                                    <ul className="list-unstyled">
                                        {Object.keys(group.items)
                                            .filter(id => this.state.demands[id])
                                            .map(id => {
                                                const demand = group.items[id];
                                                return <li key={id}>{demand.name}</li>;
                                            })}
                                        x
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div style={{ backgroundColor: options.colors.backgroundHeader }}>
                    <div className="d-print-none container-fluid clearfix pb-2" style={{ maxWidth: 1200 }}>
                        {demands.map(group => {
                            return (
                                <div key={group.name} className="float-left mr-5 mt-2 mb-3">
                                    <h3>{group.name}</h3>
                                    <table>
                                        <tbody>
                                            <tr>
                                                {Object.keys(group.items).map(id => {
                                                    const demand = group.items[id];
                                                    const active = this.state.demands[id];
                                                    const isMouseOver = this.state.mouseOvers[id];
                                                    const style = {
                                                        ...{ color: active ? options.colors.iconHighlight : options.colors.iconDefault },
                                                        ...{ width: 85, padding: 5 },
                                                    };
                                                    return (
                                                        <td
                                                            key={id}
                                                            className="text-center"
                                                            style={style}
                                                            onClick={() => this.toggleDemand(id)}
                                                            onMouseEnter={() => this.mouseOver(id, true)}
                                                            onMouseLeave={() => this.mouseOver(id, false)}>
                                                            <span className={isMouseOver ? 'd-none' : ''}>
                                                                <FontAwesomeIcon icon={demand.icon} size="3x" spin={false} />
                                                            </span>
                                                            <span className={isMouseOver ? '' : 'd-none'}>
                                                                <FontAwesomeIcon icon={demand.icon} size="3x" spin={true} />
                                                            </span>
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                            <tr>
                                                {Object.keys(group.items).map(id => {
                                                    const demand = group.items[id];
                                                    return (
                                                        <td
                                                            key={id}
                                                            className="text-center"
                                                            style={{ width: 85, padding: 3, fontSize: 11, lineHeight: '11px' }}
                                                            onClick={() => this.toggleDemand(id)}
                                                            onMouseEnter={() => this.mouseOver(id, true)}
                                                            onMouseLeave={() => this.mouseOver(id, false)}>
                                                            {demand.name}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="container-fluid" style={{ backgroundColor: options.colors.backgroundContent, maxWidth: 1200 }}>
                    <section className="mt-3">
                        <h2>Unsere Empfehlung für Sie</h2>

                        {0 === availableProducts.length && <p>Bitte wählen Sie aus der Liste oben.</p>}

                        {groups.map(group => {
                            const productIds = group.products.filter(productId => this.isProductEnabled(products[productId]));

                            if (0 === productIds.length) {
                                return <div />;
                            }

                            return (
                                <div key={group.name} className="mb-2">
                                    <h3 className="mb-0">{group.name}</h3>

                                    <ul className="list-unstyled">
                                        {productIds.map(productId => {
                                            const product = products[productId];
                                            const isOpen = this.state.allOpen || this.state.openProductId === product.id;
                                            return (
                                                <li key={product.id} onClick={() => this.toggleOpen(product.id)}>
                                                    <a style={{ color: isOpen ? options.colors.tabHeaderHighlight : options.colors.tabHeaderDefault }} href="#">
                                                        <span className={isOpen ? 'd-none' : ''}>
                                                            <FontAwesomeIcon icon="fa-plus-square" />
                                                        </span>
                                                        <span className={isOpen ? '' : 'd-none'}>
                                                            <FontAwesomeIcon icon="fa-minus-square" />
                                                        </span>{' '}
                                                        {product.name}
                                                    </a>
                                                    <div className={classNames({ 'd-none d-print-block': !isOpen })}>
                                                        <span dangerouslySetInnerHTML={{ __html: product.description }}></span>
                                                        {product.url ? (
                                                            <a href={product.url} className="d-print-none" target="_parent">
                                                                Weitere Informationen
                                                            </a>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </section>

                    {0 !== availableProducts.length && (
                        <div className="text-sm-right d-print-none">
                            <button className="btn btn-outline-success" onClick={() => this.toggleAllOpen()}>
                                {this.state.allOpen ? 'Alle zuklappen' : 'Alle aufklappen'}
                            </button>{' '}
                            <BtnPrint />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
