'use strict';

import React, { Component } from 'react';
import classNames from 'classnames';

export default class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = { tc: false };
    }

    tc(tc) {
        this.setState({ tc });
    }

    submit(e) {
        e.stopPropagation();
        e.preventDefault();

        if (!this.props.onData) {
            return;
        }

        if (!this.state.tc) {
            return;
        }

        this.props.onData({
            name: this.refs.name.value,
            street: this.refs.street.value,
            city: this.refs.city.value,
            phone: this.refs.phone.value,
            email: this.refs.email.value,
            remark: this.refs.remark.value,
        });
    }

    render() {
        return (
            <form onSubmit={e => this.submit(e)}>
                <div className="form-group row">
                    <label for="input1" className="col-sm-4 form-control-label required">
                        Vor- und Nachname
                    </label>
                    <div className="col-sm-8">
                        <input type="name" className="form-control" id="input1" required="required" ref="name" />
                    </div>
                </div>
                <div className="form-group row">
                    <label for="input2" className="col-sm-4 form-control-label">
                        Straße/Hausnummer
                    </label>
                    <div className="col-sm-8">
                        <input type="text" className="form-control" id="input2" ref="street" />
                    </div>
                </div>
                <div className="form-group row">
                    <label for="input3" className="col-sm-4 form-control-label">
                        PLZ/Ort
                    </label>
                    <div className="col-sm-8">
                        <input type="text" className="form-control" id="input3" ref="city" />
                    </div>
                </div>
                <div className="form-group row">
                    <label for="input4" className="col-sm-4 form-control-label">
                        Telefon
                    </label>
                    <div className="col-sm-8">
                        <input type="text" className="form-control" id="input4" ref="phone" />
                    </div>
                </div>
                <div className="form-group row">
                    <label for="input5" className="col-sm-4 form-control-label required">
                        E-Mail
                    </label>
                    <div className="col-sm-8">
                        <input type="email" className="form-control" id="input5" required="required" ref="email" />
                    </div>
                </div>
                <div className="form-group row">
                    <label for="input6" className="col-sm-4 form-control-label">
                        Bemerkung
                    </label>
                    <div className="col-sm-8">
                        <textarea className="form-control" id="input6" ref="remark" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" required="required" onClick={() => this.tc(!this.state.tc)} /> Ich willige ein, dass sämtliche von mir im
                            Zuge dieser Abfrage eingegebenen Daten durch die Bank erhoben, verarbeitet und genutzt (verwendet) werden können. Es gelten unsere
                            aktuellen Datenschutzbestimmungen (siehe Datenschutzhinweis).
                        </label>
                    </div>
                </div>

                <div className="form-group text-sm-right mt-2">
                    <button type="submit" className={classNames('btn btn-success', { disabled: !this.state.tc })}>
                        Anfrage abschicken
                    </button>
                </div>
            </form>
        );
    }
}
