import React from 'react';
import uppershape from '../../assets/account-finder/svg/uppershape.svg';
import undershape from '../../assets/account-finder/svg/undershape.svg';
import undershapeSummaryHighlighted from '../../assets/account-finder/svg/undershape_summary_highlighted.svg';

export default ({ accountName, disabled, summary }) => (
    <div className={`kontosiegel-rounded ${disabled ? 'gray' : ''} ${summary ? '' : 'account-preview-siegel'}`}>
        <span>{accountName}</span>
        <img src={uppershape} className="uppershape" />
        {!disabled && summary ? <img src={undershapeSummaryHighlighted} className="undershape" /> : <img src={undershape} className="undershape" />}
    </div>
);
