'use strict';

import React from 'react';
import classNames from 'classnames';

import InfoPopover from '../../components/InfoPopover';
import NumberInput from '../../components/NumberInput';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import Button from './IconChoice';

export default class BookingsIconList extends React.Component {
    constructor(props) {
        super(props);

        const { setTrigger, getEmptyTrigger, options } = this.props;

        var values = {};
        options.elements.forEach(id => (values[id] = 0));
        this.state = { values };

        setTrigger(getEmptyTrigger(), this.state.values);

        // handler to init values
        props.initValues(values => this.initValues(values));
    }

    initValues(values) {
        Object.keys(values).forEach(id => this.setValue(id, values[id]));
    }

    setValue(id, value) {
        const { setTrigger, getEmptyTrigger, data, options } = this.props;
        let { values } = this.state;

        values[id] = value;

        this.setState({ values });

        var trigger = getEmptyTrigger();
        trigger.summary.bookings = [];
        options.elements.forEach(bookingTypeKey => {
            const bookingType = data.bookingTypes[bookingTypeKey];
            let summary = {
                key: bookingTypeKey,
                name: bookingType.name,
                info: bookingType.info,
                qty: values[bookingTypeKey],
                price: {},
            };
            Object.keys(data.accounts).forEach(accountKey => {
                const price = bookingType.cost[accountKey] * values[bookingTypeKey];
                trigger.cost[accountKey] += price;
                summary.price[accountKey] = price;
            });
            if (values[bookingTypeKey] > 0) {
                trigger.summary.bookings.push(summary);
            }
        });

        setTrigger(trigger, this.state.values);
    }

    render() {
        const { data, options } = this.props;

        return (
            <ul className="centered-list content-accounts-preview">
                {options.elements.map(id => {
                    const bookingType = data.bookingTypes[id];
                    return (
                        <li key={id}>
                            {bookingType.icon.startsWith('http') ? (
                                <img src={bookingType.icon} alt="" className="img-fluid" />
                            ) : (
                                <FontAwesomeIcon icon={bookingType.icon} size="5x" className="fa-border blue" />
                            )}
                            <br />
                            {bookingType.name} <InfoPopover>{bookingType.info}</InfoPopover>
                            <NumberInput value={this.state.values[id]} onChange={value => this.setValue(id, value)} />
                        </li>
                    );
                })}
            </ul>
        );
    }
}
