'use strict';

import React, {Component} from 'react';
import classNames from 'classnames';

const DEFAULT_OPTIONS = {
    fields   : {},
};

const FIELD_OPTIONS = {
    label    : '',
    required : false,
    help     : '',
    // display function which enables field if available
    display  : (values) => true,
};

export default class FileUpload extends Component
{
    constructor(props) {
        super(props);

        // initialize fields with default value
        this.fields = {};
        this.getFields().forEach(field => {
            this.fields[field.name] = React.createRef();
            props.appendFileRef(field.name, this.fields[field.name])
        });
    }

    getOptions() {
        return {
            ...DEFAULT_OPTIONS,
            ...this.props.options
        }
    }

    getField(name) {
        const { fields } = this.getOptions();
        return {
            name,
            ...FIELD_OPTIONS,
            ...fields[name]
        };
    }

    /**
     * @returns {Array}
     */
    getFields() {
        return Object.keys(this.getOptions().fields)
            // fetch fields
            .map(name => this.getField(name))
            // apply display function on fields
            .filter(field => field.display(this.props.values));
    }

    renderFields(fields) {
        return fields.map(field => (
            <div className="form-group" key={field.name}>
                <label className={classNames({ required: field.required })}>{field.label}</label>
                <input type="file" ref={this.fields[field.name]} className="form-control" required={field.required ? 'required' : ''} />
                {field.help && <small className="form-text text-muted">{field.help}</small>}
            </div>
        ));
    }

    render() {
        return this.renderFields(this.getFields());
    }
}
