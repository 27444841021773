import React from 'react';
import { SelectForm, Btn, FormControl, YesNoBtnGroup, getChoices, SelectFormSummarySchema, SelectFormPriceFormat, filterArray } from '../Helper/FormControl';
import TypedNumberInput from '../../../components/TypedNumberInput';

export const label = 'Bookings Grid Table';

export const DEFAULT_OPTIONS = {
    groups: [],
    sumGroup: [],
    summary: '',
};

const EMPTY_VALUE = {
    name: '',
    grid: null,
    canToggle: false,
    types: [],
};

const EMPTY_SUMGROUP = {
    name: '',
    on: null,
    included: {},
    priceFormat: 'monthly',
    cost: {},
    costX: {},
};

export default ({ data, setValue, removeKey, choices, summarySchemaOptions }) => {
    return (
        <React.Fragment>
            <SelectFormSummarySchema options={summarySchemaOptions} onChange={choice => setValue(`summary`, choice.value)} value={data.summary} />

            <hr />

            <h3>Buchungsgruppen</h3>
            {data.groups.map((group, i) => (
                <div key={i} className="card card-body mb-2">
                    <div className="d-flex justify-content-between">
                        <h2>{group.name}</h2>
                        <Btn delBtn={true} onClick={() => filterArray(setValue, `groups`, data.groups, group, group.id)} />
                    </div>
                    <div className="row align-items-baseline">
                        <div className="col-10">
                            <FormControl label="Name:" setValue={value => setValue(`groups.${i}.name`, value)} value={group.name} />
                        </div>
                        <div className="col-2">
                            <FormControl nmbrInput={true} value={group.grid} label="Grid:" setValue={value => setValue(`groups.${i}.grid`, value)} />
                        </div>
                        <div className="col-12">
                            <YesNoBtnGroup active={group.canToggle} label="can toggle ?" setValue={value => setValue(`groups.${i}.canToggle`, value)} />
                        </div>
                        <div className="col-12">
                            <SelectForm
                                multi={true}
                                label="Buchungstypen"
                                options={getChoices(choices.bookingTypes)}
                                onChange={choices => setValue(`groups.${i}.types`, choices.map(choice => choice.value))}
                                clearable={true}
                                value={group.types}
                            />
                        </div>
                    </div>
                </div>
            ))}
            <Btn label="Gruppe Hinzufügen" btnText="+" className="btn btn-primary mr-3" onClick={() => addGroup(setValue, data, Math.random())} />

            <hr />

            <h3>Summarygruppen</h3>
            {data.sumGroup.map((sumGrp, sumGrpIndex) => {
                return (
                    <div key={sumGrpIndex} className="card card-body mb-3">
                        <div className="d-flex justify-content-between">
                            <h2>{sumGrp.name}</h2>
                            <Btn delBtn={true} onClick={() => filterArray(setValue, `sumGroup`, data.sumGroup, sumGrp, sumGrp.id)} />
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <FormControl label="Name:" setValue={value => setValue(`sumGroup.${sumGrpIndex}.name`, value)} value={sumGrp.name} />
                            </div>
                            <div className="col-6">
                                <SelectForm
                                    multi={true}
                                    label="On: "
                                    options={getChoices(choices.bookingTypes)}
                                    onChange={choices => setValue(`sumGroup.${sumGrpIndex}.on`, choices.map(choice => choice.value))}
                                    clearable={true}
                                    value={sumGrp.on}
                                />
                            </div>
                        </div>
                        <div className="row">
                            {getChoices(choices.accounts).map(account => (
                                <div key={account.value} className="col-2 d-flex flex-column justify-content-end">
                                    <TypedNumberInput
                                        label={`includiert in ${account.label} ?`}
                                        value={sumGrp.included[account.value]}
                                        setValue={value => setValue(`sumGroup.${sumGrpIndex}.included.${account.value}`, value)}
                                        nmbrInput={true}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="d-flex flex-column">
                            <h3>Preise:</h3>
                            <div className="row">
                                <div className="col-12">
                                    <SelectForm
                                        multi={true}
                                        label="Cost X on :"
                                        options={getChoices(choices.bookingTypes)}
                                        onChange={choices => setCostXBookingTypes(setValue, data, choices, sumGrpIndex)}
                                        clearable={true}
                                        value={Object.keys(sumGrp.costX).map(key => key)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {getChoices(choices.accounts).map(accKey => (
                                    <React.Fragment key={accKey.value}>
                                        <div key={accKey.value} className="col-2 d-flex flex-column justify-content-end">
                                            <TypedNumberInput
                                                key={accKey.value}
                                                label={accKey.label}
                                                value={sumGrp.cost[accKey.value]}
                                                setValue={value => setValue(`sumGroup.${sumGrpIndex}.cost`, { ...sumGrp.cost, [accKey.value]: value })}
                                            />
                                        </div>
                                        {getChoices(choices.bookingTypes).map((bookingType, bookingTypeIndex) => (
                                            <div
                                                key={bookingType.value}
                                                className={`col-2 d-flex flex-column justify-content-end order-${bookingTypeIndex + 1}`}>
                                                {Object.keys(sumGrp.costX).includes(bookingType.value) && (
                                                    <TypedNumberInput
                                                        key={accKey.value}
                                                        label={`Cost X of ${bookingType.label} on ${accKey.label}`}
                                                        value={sumGrp.costX[bookingType.value][accKey.value]}
                                                        setValue={value =>
                                                            setAccountCostXfromBookingType(setValue, bookingType, sumGrp, accKey, value, sumGrpIndex)
                                                        }
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <SelectFormPriceFormat
                            label="Preis (Format)"
                            onChange={choices => setValue(`sumGroup.${sumGrpIndex}.priceFormat`, choices.value)}
                            options="default"
                            value={sumGrp.priceFormat}
                        />
                    </div>
                );
            })}
            <Btn label="Summarygroup Hinzufügen" btnText="+" className="btn btn-primary" onClick={() => addSumGroup(setValue, data, Math.random())} />
        </React.Fragment>
    );
};

const addGroup = (setValue, data, id) => {
    setValue(`groups`, [
        ...data.groups,
        {
            ...EMPTY_VALUE,
            id,
        },
    ]);
};

const addSumGroup = (setValue, data, id) => {
    setValue(`sumGroup`, [
        ...data.sumGroup,
        {
            ...EMPTY_SUMGROUP,
            id,
        },
    ]);
};

const setCostXBookingTypes = (setValue, data, choices, sumGrpIndex) => {
    let bookingTypes = {};
    choices.forEach(choice => {
        bookingTypes = {
            ...data.sumGroup[sumGrpIndex].costX,
            [choice.value]: {},
        };
    });
    setValue(`sumGroup.${sumGrpIndex}.costX`, bookingTypes);
};

const setAccountCostXfromBookingType = (setValue, bookingType, sumGrp, accKey, value, sumGrpIndex) => {
    setValue(`sumGroup.${sumGrpIndex}.costX`, {
        ...sumGrp.costX,
        [bookingType.value]: { ...sumGrp.costX[bookingType.value], [accKey.value]: value },
    });
};
