import React, {Component} from 'react';

export default class Button extends Component
{
    click(evt) {
        evt.stopPropagation();
        this.props.onClick();
    }

    render() {
        const {
            active = false,
            color = 'light',
            className,
            children,
            withCheck = false
        } = this.props;

        return (
            <button className={`btn btn-${color} ${className} ${active ? 'active':''}`} style={{position:'relative'}} onClick={this.click.bind(this)} ref={e => this.button = e} onFocus={() => this.button.blur()}>
                {withCheck && <span className={active ? '' : 'd-none'}><span className="fa fa-check" style={{position:'absolute', right:5, top:5}}/></span>}
                {children}
            </button>
        );
    }
}
