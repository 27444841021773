import React from 'react';

class ChoiceInput extends React.Component {
    click(value) {
        // empty state if it already was set
        if (this.props.value === value) {
            value = '';
        }

        this.setState({ value });
        this.props.setValue(value);
    }

    render() {
        const { choices, value } = this.props;

        const isActive = choice => value === choice;

        return (
            <div className="row no-gutters">
                {choices.map(choice => (
                    <div key={choice} className="col text-center">
                        <button
                            className={`btn btn-block ${isActive(choice) ? 'btn-primary active' : 'btn-outline-secondary'}`}
                            onClick={() => this.click(choice)}>
                            {choice}
                        </button>
                    </div>
                ))}
            </div>
        );
    }
}
ChoiceInput.defaultProps = {
    choices: [],
    setValue: value => {},
};

export default ChoiceInput;
