'use strict';

import React from 'react';
import classNames from 'classnames';

export default class Debug extends React.Component {
    render() {
        const { display, state } = this.props;

        if (!display) {
            return <div/>;
        }

        return (
            <pre>
                {state.activePage} ({state.lastPages.join(', ')})<br/>
                {JSON.stringify(state.values, null, '\t')}<br/>
                {JSON.stringify(state.triggers, null, '\t')}
            </pre>
        );
    }
}
