'use strict';

import React from 'react';
import classNames from 'classnames';

import { AccountPrice, FORMAT_ANNUAL } from '../Model/Price';
import SliderWithInput from '../../components/SliderWithInput';
import * as Analytics from '../analytics';

const DEFAULT_OPTIONS = {
    min: 0,
    max: 1200,
    step: 100,
    value: 0,
    marks: { 0: '0 EUR', 200: '200 EUR', 400: '400 EUR', 600: '600 EUR', 800: '800 EUR', 1000: '1.000 EUR', 1200: '1.200 EUR' },
    refund: function(value) {
        return value;
    },
    summary: 'cards',
    summaryCreditTurnover: `Rückerstattung bei Kreditkarten-Umsatz von € $REFUND$ p. M.`,
    info: '',
};

export default class Refund extends React.Component {
    constructor(props) {
        super(props);

        const { setTrigger } = this.props;

        // initialize state
        this.state = { value: 0 };
        setTrigger({}, this.state.value);

        // handler to init values
        props.initValues(value => this.setValue(value));
    }

    getOptions() {
        return {
            ...DEFAULT_OPTIONS,
            ...this.props.options,
        };
    }

    setValue(value) {
        this.setState({ value });

        const { setTrigger, getEmptyTrigger, data } = this.props;
        const options = this.getOptions();

        const refund = options.refund(value);

        // set the trigger based upon the selected items
        let trigger = getEmptyTrigger();
        // if summary flag is set, add current values to this summary namespace
        if (false !== options.summary) {
            // if summary flag is set, add current values to this summary namespace
            let prices = {};
            Object.keys(data.accounts).forEach(accountId => (prices[accountId] = -1 * refund));

            const price = new AccountPrice(prices, FORMAT_ANNUAL);
            trigger.price.addPrice(price);
            trigger.summary[options.summary] = [
                {
                    key: 'refund',
                    name: options.summaryCreditTurnover.replace(/\$REFUND\$/, value.toFixed(2).replace(/\./, ',')),
                    info: options.info,
                    text: `€ ${refund.toFixed(2).replace(/\./, ',')} p. a.`,
                    price: price,
                },
            ];
        }
        setTrigger(trigger, this.state.value);

        Analytics.trackEvent('Component_Refund', 'set_value', 'value', value);
    }

    render() {
        const options = this.getOptions();

        return (
            <div>
                <div className="row justify-content-center">
                    <div className="col-lg-8 mb-2">
                        <SliderWithInput
                            min={options.min}
                            max={options.max}
                            step={options.step}
                            marks={options.marks}
                            value={this.state.value}
                            setValue={value => this.setValue(value)}
                            label="Kreditkarten-Umsatz p. M."
                            labelInfo={options.info}
                            unit="EUR"
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-8 text-sm-center">
                        <big>Ihre mögliche Rückerstattung bis zu {options.refund(this.state.value)} EUR</big>
                    </div>
                </div>
            </div>
        );
    }
}
