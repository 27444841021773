'use strict';

import React from 'react';
import classNames from 'classnames';

export default class Price extends React.Component {
    render() {
        const { price } = this.props;
        return <span>{formatPrice(price)}</span>;
    }
}

export const formatPrice = price => price.toFixed(2).replace(/\./, ',');
