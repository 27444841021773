'use strict';

import React, { Component } from 'react';
import classNames from 'classnames';
import Slider from 'rc-slider';
import numeral from 'numeral';
import de from 'numeral/locales/de';

numeral.locale('de', de);
numeral.locale('de');

export default class ContactForm extends Component
{
    render() {
        const { values } = this.props;

        return (
            <table className="table table-sm table-result">
                <tbody>
                <tr>
                    <th>Ihre monatliche Rate:</th>
                    <td className="text-right"><nobr>{numeral(values.monatlicheRate).format('0,0.00')}</nobr></td>
                    <td>EUR</td>
                </tr>
                <tr className="highlight">
                    <th>Effektiver Jahreszins:</th>
                    <td className="text-right"><nobr>{numeral(values.effektivzins).format('0,0.00')}</nobr></td>
                    <td>%</td>
                </tr>
                <tr className="highlight">
                    <th>Sollzins gebunden p.a.:</th>
                    <td className="text-right"><nobr>{numeral(values.sollzins).format('0,0.00')}</nobr></td>
                    <td>%</td>
                </tr>
                <tr className="highlight">
                    <th>Nettodarlehensbetrag:</th>
                    <td className="text-right"><nobr>{numeral(values.darlehensHoehe).format('0,0.00')}</nobr></td>
                    <td>EUR</td>
                </tr>
                <tr>
                    <th>Restschuld nach {values.zinsbindungJahre} Jahren:</th>
                    <td className="text-right"><nobr>{numeral(values.restschuld).format('0,0.00')}</nobr></td>
                    <td>EUR</td>
                </tr>
                <tr>
                    <th>Voraussichtliche Laufzeit:</th>
                    <td className="text-right"><nobr>{numeral(values.laufzeit).format('0,0.0')}</nobr></td>
                    <td>Jahre</td>
                </tr>
                <tr>
                    <th>Anzahl der Raten:</th>
                    <td className="text-right"><nobr>{numeral(values.anzahlRaten).format('0,0')}</nobr></td>
                    <td></td>
                </tr>
                <tr>
                    <th>Zu zahlender Gesamtbetrag</th>
                    <td className="text-right"><nobr>{numeral(values.gesamtBetrag).format('0,0.00')}</nobr></td>
                    <td>EUR</td>
                </tr>
                </tbody>
            </table>
        );
    }
}
