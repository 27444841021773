import $ from "jquery";

/**
 * Saves a config for a given bank/accountFinder
 *
 * @param bankSlug
 * @param accountFinderId
 * @param config
 * @param code
 *
 * @returns {Promise}
 */
export const saveConfig = ({bankSlug, accountFinderId, config, code = null}) => {
    const url = `/tools/${bankSlug}/account-finder/${accountFinderId}/config-code-update/${code || '_new'}/`;
    return new Promise((resolve, reject) => {
        $.post(url, { config }, data => {
            resolve(data);
        });
    });
};

export const loadConfig = ({bankSlug, accountFinderId, code}) => {
    const url = `/tools/${bankSlug}/account-finder/${accountFinderId}/config-code/${code}/`;

    return new Promise((resolve, reject) => {
        $.get(url, data => {
            resolve(data);
        }).fail(() => {
            reject();
        });
    });
};
