import React from "react";
import debounce from "lodash/debounce";

class DetailsInput extends React.Component
{
    enter(value) {
        // empty state if it already was set
        if (this.props.value === value) {
            value = '';
        }

        this.setState({value});
        this.props.setValue(value);
    }

    render() {
        const debouncedEnter = debounce(value => this.enter(value), 500);

        return (
            <textarea className="form-control w-100 h-100" onChange={e => debouncedEnter(e.target.value)} placeholder="Hinweise"/>
        );
    }
}
DetailsInput.defaultProps = {
    setValue: value => {}
};

export default DetailsInput;