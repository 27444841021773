import React from 'react';
import TabList from './Helper/TabList';
import { getNextKey } from './Helper/nextKey';

const EMPTY_VALUE = {
    required: false,
    defaultValue: null,
    h1: 'Seitenname',
    h2: '',
    h3: '',
    print: false,
};

export default ({ pages, setValue, removeKey, activeTab, setActiveTab }) => {
    const addPage = () => {
        const nextKey = getNextKey(pages, 'page');
        setValue(nextKey, {
            types: [],
            ...EMPTY_VALUE,
        });
        setActiveTab(nextKey);
    };
    return (
        <React.Fragment>
            <TabList
                tabs={Object.keys(pages).map(key => ({
                    value: key,
                    label: pages[key].h1 || pages[key].h2 || pages[key].h3 || 'no page title',
                }))}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                removeKey={removeKey}
            />
            <button className="btn btn-outline-secondary mt-2" onClick={() => addPage()}>
                Add Page
            </button>
        </React.Fragment>
    );
};
