'use strict';

import React from 'react';
import ImageGrayscaled from '../../components/ImageGrayscaled';
import DefaultKontoSiegel from '../../components/DefaultKontoSiegel';

export default class Account extends React.Component {
    render() {
        const { account, disabled, summary } = this.props;
        return (
            <React.Fragment>
                {account.image ? (
                    <ImageGrayscaled src={account.image} gray={disabled} className="img-fluid mx-auto" />
                ) : (
                    <DefaultKontoSiegel accountName={account.name} disabled={disabled} summary={summary} />
                )}
            </React.Fragment>
        );
    }
}
