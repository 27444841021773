'use strict';

import React, { Component } from 'react';
import InputSliders from '../InputSliders';
import ResultTable from '../ResultTable';
import InfoPopover from '../../components/InfoPopover';

export default class Calculator extends Component {
    render() {
        const { values, setValue, setPage, cf_button_inquiry, cf_legal, cf_can_contact, cf_legal_tooltip } = this.props;

        return (
            <div>
                <h2>Ihre Eingabe</h2>

                <InputSliders
                    values={values}
                    setValue={(key, value) => setValue(key, value)}
                    yaml={this.props.yaml}
                    cf_headline_buying_price={this.props.cf_headline_buying_price}
                />

                {values.ueberBeleihwert && (
                    <div className="alert alert-info">
                        <p>
                            Ihr Finanzierungsbedarf ist im Verhältnis zum Eigenkapital hoch - wir weisen darauf hin, dass unser Experte in einem späteren
                            Schritt vor zustande kommen eines Darlehensvertrages prüft, ob das Objekt (als Sicherheit) ausreichend werthaltig ist. Ist dies der
                            Fall, erhalten Sie die bestmögliche Kondition wie angegeben. Fordern Sie jetzt ein unverbindliches Angebot an und notieren ggf. Ihre
                            Wünsche und Anmerkungen unter "Bemerkung" - wir berücksichtigen dies und geben Ihnen Bescheid.
                        </p>
                    </div>
                )}

                {values.kaufUeberEigkap && (
                    <div className="alert alert-danger">
                        <p>Das oben angegebene Eigenkapital ist höher als der Kaufpreis. Es kann kein Finanzierungsbedarf ermittelt werden.</p>
                    </div>
                )}

                {!values.kaufUeberEigkap && (
                    <div className="mt-3">
                        <h2>Ergebnis bei o.g. Annahmen</h2>

                        {cf_can_contact && (
                            <div className="row">
                                <div className="col-sm-8">
                                    <ResultTable values={values} />
                                </div>
                                <div className="col-sm-4 text-sm-left text-md-right">
                                    <button className="btn btn-success" onClick={() => setPage('contact')}>
                                        {cf_button_inquiry || 'Anfrage stellen'}
                                    </button>
                                </div>
                            </div>
                        )}
                        {cf_can_contact || <ResultTable values={values} />}

                        {cf_legal_tooltip && (
                            <span>
                                Rechtliche Hinweise <InfoPopover>{cf_legal}</InfoPopover>
                            </span>
                        )}
                        {cf_legal_tooltip || <small className="text-muted">{cf_legal || 'Annahmen siehe *'}</small>}
                    </div>
                )}
            </div>
        );
    }
}
