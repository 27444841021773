import React from 'react';
import Select from 'react-select';

export const RadioField = ({ choice, type, typeOptions }) => (
    <React.Fragment>
        {typeOptions.choices.map(optionChoice => (
            <div className={`addon-${type} d-flex`} key={`choice_${optionChoice}`}>
                <label>{optionChoice}</label>
                <input
                    placeholder={typeOptions.placeholder}
                    type={type}
                    id={`${choice.choiceKey}_addon_${optionChoice}`}
                    className={`form-control ${typeOptions.className}`}
                    checked={choice.addonValue === optionChoice}
                    onChange={() => choice.setAddonValue(optionChoice)}
                />
            </div>
        ))}
    </React.Fragment>
);

export const CheckBoxField = ({ choice, type, typeOptions }) => (
    <React.Fragment>
        {typeOptions.choices.map(optionChoice => (
            <div className={`addon-${type} d-flex`} key={`choice_${optionChoice}`}>
                <label>{optionChoice}</label>
                <input
                    placeholder={typeOptions.placeholder}
                    type={type}
                    id={`${choice.choiceKey}_addon_${optionChoice}`}
                    className={`form-control ${typeOptions.className}`}
                    checked={choice.addonValue.includes(optionChoice)}
                    onChange={() =>
                        choice.setAddonValue(
                            choice.addonValue.includes(optionChoice)
                                ? choice.addonValue.filter(choice => choice !== optionChoice)
                                : [...choice.addonValue, optionChoice]
                        )
                    }
                />
            </div>
        ))}
    </React.Fragment>
);

export const InputField = ({ choice, typeOptions }) => (
    <React.Fragment>
        <div className={`addon-input d-flex`}>
            <input
                placeholder={typeOptions.placeholder}
                type="text"
                id={`${choice.choiceKey}_addon_input`}
                className={`form-control ${typeOptions.className}`}
                value={choice.addonValue}
                onChange={e => choice.setAddonValue(e.target.value)}
            />
        </div>
    </React.Fragment>
);

export const SelectField = ({ typeOptions, choice }) => (
    <Select
        simpleValue={true}
        cache={false}
        options={typeOptions.choices.map(choice => ({ label: choice, value: choice }))}
        value={choice.addonValue}
        onChange={selectedChoice => choice.setAddonValue(selectedChoice)}
        multi={typeOptions.multiple}
        placeholder={typeOptions.placeholder}
        className={`w-100 ${typeOptions.className}`}
    />
);
