'use strict';

import React from 'react';

import InfoPopover from '../../components/InfoPopover';
import NumberInput from '../../components/NumberInput';
import * as Analytics from '../analytics';

const DEFAULT_OPTIONS = {
    max: null,
    summary: 'cards',
};

export default class Cards extends React.Component {
    constructor(props) {
        super(props);

        let values = {};
        Object.keys(props.data.cardTypes).forEach(id => (values[id] = 0));
        this.state = { values };

        const { setTrigger } = this.props;
        setTrigger({}, this.state.values);

        // handler to init values
        props.initValues(values => this.initValues(values));
    }

    initValues(values) {
        Object.keys(values).forEach(id => this.setValue(id, values[id]));
    }

    getOptions() {
        const { options } = this.props;
        return {
            ...DEFAULT_OPTIONS,
            ...options,
        };
    }

    setValue(id, value) {
        const options = this.getOptions();
        if (null !== options.max) {
            value = Math.min(value, options.max);
        }

        let { values } = this.state;
        values[id] = value;
        this.setState({ values });

        const { setTrigger, data, getEmptyTrigger, getPriceCalculator } = this.props;

        const trigger = getEmptyTrigger();
        trigger.summary[options.summary] = [];

        Object.keys(data.cardTypes).forEach(cardTypeKey => {
            const cardType = data.cardTypes[cardTypeKey];

            if (0 === values[cardTypeKey]) {
                return;
            }

            const price = getPriceCalculator(cardType.price).getPrice(values[cardTypeKey]);
            const summary = {
                key: cardTypeKey,
                name: cardType.name,
                info: cardType.info,
                price: price,
                qty: values[cardTypeKey],
            };
            trigger.price.addPrice(price);
            trigger.summary[options.summary].push(summary);
        });
        setTrigger(trigger, this.state.values);

        Analytics.trackEvent('Component_Cards', 'set_value', id, value);
    }

    getCards() {
        const { cardTypes } = this.props.data;
        const { use } = this.props.options;

        return (
            Object.keys(cardTypes)
                // construct object
                .map(id => ({ id, ...cardTypes[id] }))
                // filter cards to display
                .filter(card => !use || use.indexOf(card.id) > -1)
        );
    }

    render() {
        return (
            <div className="row justify-content-center">
                {this.getCards().map(card => (
                    <div className="col-auto text-center" key={card.id}>
                        <h4>
                            {card.name} <InfoPopover>{card.info}</InfoPopover>
                        </h4>
                        <img className="img-fluid" src={card.image} style={{ display: 'inline-block', maxWidth: 300 }} />

                        <NumberInput value={this.state.values[card.id]} onChange={value => this.setValue(card.id, value)} />
                    </div>
                ))}
            </div>
        );
    }
}
