import React from 'react';
import { FormControl, YesNoBtnGroup, Btn } from '../Helper/FormControl';
import { getNextKey } from '../Helper/nextKey';
import Schema from './Summary/Schema';

export const label = 'Summary';

export const DEFAULT_OPTIONS = {
    schema: {},
    accountsGrid: 2,
    accountsAlignment: 'center',
    displayAccountPreview: true,
    highlightActiveAccount: true,
    displayTotalSum: false,
    displayBaseFee: true,
    baseFeeName: 'Grundgebühr',
    ratingGrid: 0, // is deprecated. use "sidebarGrid"
    sidebarGrid: 0,
    displayPrintSummary: false,
    displayAccountPreviewText: null,
    withRemark: false,
    remarkLabel: 'Haben Sie noch weitere Hinweise?',
    totalSumText: '',
};

export const DEFAULT_OPTIONS_INNER = {
    name: 'Können wir etwas besser machen?',
    summation: false,
    priceFormat: 'monthly',
    includeBaseFee: false,
    sidebarType: 'ratings', // or: 'details' for textarea input
    starsAmount: 3,
    ratings: [], // choices used in ratings
    // define own component for rendering info page {@see getSummary()}
    type: null,
};

export default ({ data, setValue, pageKey, removeSchema }) => {
    const addSchema = () => {
        const nextKey = getNextKey(data.schema, `schema`);
        setValue(`schema.${nextKey}`, {
            ...DEFAULT_OPTIONS_INNER,
        });
    };

    const sideBarTypeOptions = [
        {
            value: 'ratings',
            label: 'ratings',
        },
        {
            value: 'details',
            label: 'details',
        },
        {
            value: 'stars',
            label: 'stars',
        },
    ];
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-3 d-flex flex-column">
                    <YesNoBtnGroup label="Grundpreis anzeigen ?" setValue={value => setValue(`displayBaseFee`, value)} active={data.displayBaseFee} />
                    {data.displayBaseFee ? (
                        <FormControl label="Grundpreis Label:" value={data.baseFeeName} setValue={value => setValue(`baseFeeName`, value)} />
                    ) : null}
                </div>
                <div className="col-3">
                    <YesNoBtnGroup label="Gesamtsumme anzeigen ?" setValue={value => setValue(`displayTotalSum`, value)} active={data.displayTotalSum} />
                    {data.displayTotalSum ? (
                        <FormControl label="Gesamtsumme Label:" value={data.totalSumText} setValue={value => setValue(`totalSumText`, value)} />
                    ) : null}
                </div>
                <div className="col-3">
                    <YesNoBtnGroup label="with remark ?" setValue={value => setValue(`withRemark`, value)} active={data.withRemark} />
                    {data.withRemark ? <FormControl label="remark Label" value={data.remarkLabel} setValue={value => setValue(`remarkLabel`, value)} /> : null}
                </div>
                <div className="col-3">
                    <YesNoBtnGroup
                        label="Kontenvorschau anzeigen ?"
                        setValue={value => setValue(`displayAccountPreview`, value)}
                        active={data.displayAccountPreview}
                    />
                    {data.displayAccountPreview ? (
                        <FormControl
                            label="Kontenvorschautext:"
                            value={data.displayAccountPreviewText}
                            setValue={value => setValue(`displayAccountPreviewText`, value)}
                        />
                    ) : null}
                </div>
            </div>

            <hr />

            <div className="row mt-3">
                <div className="col-4">
                    <FormControl label="Konten Grid" value={data.accountsGrid} setValue={value => setValue(`accountsGrid`, value)} nmbrInput={true} />
                </div>
                <div className="col-4">
                    <FormControl label="Sidebar Grid" value={data.sidebarGrid} setValue={value => setValue(`sidebarGrid`, value)} nmbrInput={true} />
                </div>
                <div className="col-4">
                    <FormControl label="Konten Alignment" value={data.accountsAlignment} setValue={value => setValue(`accountsAlignment`, value)} />
                </div>
            </div>

            <hr />

            <div className="row mt-3">
                <div className="col-12 d-flex">
                    <YesNoBtnGroup
                        label="aktives Konto highlighten ?"
                        setValue={value => setValue(`highlightActiveAccount`, value)}
                        active={data.highlightActiveAccount}
                    />
                    <YesNoBtnGroup
                        className="ml-5"
                        label="druckbares Ergebnis anzeigen ?"
                        setValue={value => setValue(`displayPrintSummary`, value)}
                        active={data.displayPrintSummary}
                    />
                </div>
            </div>

            <hr />

            <h3>Schemata</h3>
            {Object.keys(data.schema).map((schema, i) => (
                <Schema
                    data={data}
                    schema={schema}
                    key={i}
                    i={i}
                    sideBarTypeOptions={sideBarTypeOptions}
                    setValue={setValue}
                    removeSchema={removeSchema}
                    pageKey={pageKey}
                />
            ))}
            <Btn label="Schema hinzufügen" btnText="+" className="btn btn-primary" onClick={() => addSchema()} />
        </React.Fragment>
    );
};
