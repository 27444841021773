'use strict';

import React from 'react';

import { FORMAT_MONTHLY, FORMAT_ANNUAL } from './Model/Price';

export default class AccountPriceView extends React.Component {
    render() {
        const { accountPrice, accountKey, format, suffix } = this.props;
        return (
            <span>
                {accountPrice
                    .getPrice(accountKey, format)
                    .toFixed(2)
                    .replace(/\./, ',')}
                {suffix && format === FORMAT_MONTHLY && ' p. M.'}
                {suffix && format === FORMAT_ANNUAL && ' p. a.'}
            </span>
        );
    }
}
