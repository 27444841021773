'use strict';

import React, { Component } from 'react';
import classNames from 'classnames';
import FontAwesomeIcon from '../components/FontAwesomeIcon';

export default class FAQ extends Component {
    constructor(props) {
        super(props);

        this.state = {
            levels: [0, 0],
            item: null,
            search: '',
        };
    }

    setActiveLevel(level, position) {
        let { levels } = this.state;
        levels[level] = position;

        // force level1 to be 0 when level0 is changed
        if (0 === level) {
            levels[1] = 0;
        }

        this.setState({
            levels,
            item: null,
        });
    }

    setActiveItem(id) {
        this.setState({
            item: id,
        });
    }

    searchMatch(item, query) {
        const headline = item.headline.toLowerCase();
        const description = item.description.toLowerCase();
        query = query.toLowerCase();

        return headline.indexOf(query) > -1 || description.indexOf(query) > -1;
    }

    setSearch(search) {
        this.setState({ search });
    }

    render() {
        const { categories, items, headline } = this.props;
        const { levels, search } = this.state;

        const level0 = categories[levels[0]];
        const level1 = level0.categories[levels[1]];

        const itemIds = search.length > 0 ? Object.keys(items).filter(id => this.searchMatch(items[id], search)) : level1.items;

        return (
            <div className="container-fluid">
                <div className="tool-faq">
                    {headline.length !== 0 && <h2>{headline}</h2>}

                    <div className="input-group searchbox">
                        <input type="search" placeholder="Suche..." className="form-control" onChange={e => this.setSearch(e.target.value)} />
                        <div className="input-group-btn">
                            <button className="btn btn-outline-secondary">
                                <FontAwesomeIcon icon="fa-search" />
                            </button>
                        </div>
                    </div>

                    <div className={classNames('row', { 'd-none': this.state.search.length > 0 })}>
                        <div className="col-sm-6">
                            <div className="list-group category" style={{ overflow: 'scroll', height: '259' }}>
                                {categories.map((category, i) => (
                                    <a
                                        key={i}
                                        href="#"
                                        className={classNames('list-group-item', { active: this.state.levels[0] === i })}
                                        onClick={() => this.setActiveLevel(0, i)}>
                                        <div className="float-right">
                                            <FontAwesomeIcon icon="fa-angle-right" size="lg" />
                                        </div>
                                        {category.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="list-group subcategory" style={{ overflow: 'scroll', height: '259' }}>
                                {level0.categories.map((category, i) => (
                                    <a
                                        key={i}
                                        href="#faq-items"
                                        className={classNames('list-group-item', { active: this.state.levels[1] === i })}
                                        onClick={() => this.setActiveLevel(1, i)}>
                                        <div className="float-right">
                                            <FontAwesomeIcon icon="fa-angle-right" size="lg" />
                                        </div>
                                        {category.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="items mt-2" id="faq-items">
                        {itemIds.map((id, i) => {
                            const item = items[id];
                            const active = this.state.item === id || (this.state.item === null && i === 0);
                            return (
                                <div key={id} className={classNames({ active: active })} onClick={() => this.setActiveItem(id)}>
                                    <h3 style={{ cursor: 'pointer' }}>{item.headline}</h3>
                                    {active && <p dangerouslySetInnerHTML={{ __html: item.description }} />}
                                    {active && item.link && (
                                        <p>
                                            <a href={item.link} target="_blank">
                                                Weitere Informationen
                                            </a>
                                        </p>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
