'use strict';

import React from 'react';
import Slider from 'react-rangeslider';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';

import { mergeTrigger, normalizeTrigger } from '../trigger';
import { AccountPrice } from '../Model/Price';
import * as Analytics from '../analytics';

const DEFAULT_OPTIONS = {
    from: '',
    to: '',
    question: 'Was ist Ihnen wichtig?',
    choices: [],
    summary: '',
    trigger: {},
    orientation: 'vertical',
};

const CHOICE_OPTIONS = {
    // name/headline of the info window
    name: '',
    // what gets displayed in the info window
    text: '',
    // what gets displayed next to the slider minus (-)
    label: '',
    // icon which is displayed in the info window
    icon: '',
    // text under the icon in the info window
    iconText: '',
    // defines the text displayed on summary page
    summary: '',
    // by default, the display function always returns true to display the item
    display: values => true,
};

export default class TrendSlider extends React.Component {
    constructor(props) {
        super(props);

        const { defaultValue, setTrigger } = this.props;

        this.state = {
            value: defaultValue !== null && defaultValue !== undefined ? defaultValue : 0,
        };

        setTrigger({}, this.state.value);

        // handler to init values
        props.initValues(value => this.setValue(value));
    }

    getOptions() {
        return {
            ...DEFAULT_OPTIONS,
            ...this.props.options,
        };
    }

    getChoices() {
        const { values } = this.props;
        let options = this.getOptions();
        const choices = options.choices;
        return (
            choices
                .map((choice, i) => ({
                    choiceKey: i,
                    ...CHOICE_OPTIONS,
                    ...choices[i],
                    hasValue: () => this.hasValue(i),
                    setValue: () => this.setValue(i),
                    sliderLabel: choices[i].label ? choices[i].label : i === 0 ? options.from : i === choices.length - 1 ? options.to : '',
                }))
                // display only when display function allows it
                .filter(choice => choice.display(values))
        );
    }

    getActiveChoice() {
        const options = this.getOptions();
        return options.choices[this.state.value];
    }

    setValue(value) {
        const { setTrigger, getEmptyTrigger } = this.props;
        const options = this.getOptions();

        // skip handling when value did not change
        if (this.state.value === value) {
            return;
        }

        this.state.value = value;

        // set the trigger based upon the selected items
        let trigger = getEmptyTrigger();
        // if summary flag is set, add current values to this summary namespace
        if (false !== options.summary) {
            trigger.summary[options.summary] = [];
        }

        const choiceOption = options.choices[value];

        const choiceTrigger = normalizeTrigger(choiceOption.trigger) || getEmptyTrigger();
        trigger = mergeTrigger(trigger, choiceTrigger);

        if (null !== options.summary) {
            trigger.summary[options.summary].push({
                key: choiceOption.choiceKey,
                name: choiceOption.name,
                // info         : choiceOption.info,
                price: new AccountPrice({}),
                text: choiceOption.summary,
                icon: choiceOption.icon,
            });
        }

        this.setState(this.state);
        setTrigger(trigger, this.state.value);

        Analytics.trackEvent('Component_TrendSlider', 'set_value', 'value', value);
    }

    hasValue(value) {
        return this.state.value === value;
    }

    render() {
        const options = this.getOptions();
        const choices = this.getChoices();

        const activeChoice = this.getActiveChoice();

        return (
            <div className={`trend-slider row align-items-center ${options.orientation}`}>
                <div className="trend-slider--left col-sm-4 col-lg-3 p-4">
                    <div className="question">{options.question}</div>
                    <Slider
                        value={this.state.value}
                        min={0}
                        max={choices.length - 1}
                        tooltip={false}
                        orientation={options.orientation}
                        labels={choices.map(choice => choice.sliderLabel)}
                        onChange={value => this.setValue(value)}
                    />
                </div>
                <div className="trend-slider--right col-sm-8 col-lg-9">
                    <div className="row align-items-center">
                        <div className="col-4 text-right icon-text blue">
                            {choices.map(choice => (
                                <span key={choice.name} className={`${activeChoice.icon == choice.icon ? '' : 'd-none'}`}>
                                    <FontAwesomeIcon key={choice.icon} icon={choice.icon} size="5x" />
                                </span>
                            ))}
                            <div>
                                <b>{activeChoice.iconText}</b>
                            </div>
                        </div>
                        <div className="col-8 slider-text">
                            <h3>{activeChoice.name}</h3>
                            <div dangerouslySetInnerHTML={{ __html: activeChoice.text }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
