'use strict';

import React, { Component } from 'react';

import ResultTable from '../ResultTable';
import ContactForm from '../ContactForm';

export default class Contact extends Component {
    render() {
        const { values, setPage, onContactFormData } = this.props;

        return (
            <div>
                <h2>{this.props.cf_contact_headline}</h2>
                <p>{this.props.cf_contact_intro}</p>

                <h3>Ihr Ergebnis</h3>

                <div className="row">
                    <div className="col-sm-8">
                        <ResultTable values={values} />
                    </div>
                    <div className="col-sm-4 text-right">
                        <button className="btn btn-outline-secondary" onClick={() => setPage('calculator')}>
                            Zurück
                        </button>
                    </div>
                </div>

                <hr className="my-2" />

                <h3>Ihre Eingabe</h3>

                <ContactForm redirectUrl={this.props.defaults.redirectUrl} onData={data => onContactFormData(data)} />

                <small className="text-muted">
                    Ihre Angaben und persönlichen Daten werden SSL-verschlüsselt übermittelt. Wir gehen stets vertrauensvoll mit Ihren Daten um.
                </small>
            </div>
        );
    }
}
