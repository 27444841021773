import React from 'react';
import Select from 'react-select';
import TypedNumberInput from '../../../components/TypedNumberInput';

export const FormControl = ({
    label,
    setValue,
    value,
    textArea = false,
    nmbrInput = false,
    onBlur = false,
    defaultValue,
    onFocus,
    className = '',
    cols = '30',
    rows = '10',
}) => (
    <div className="form-group">
        <label>{label}</label>
        {textArea ? (
            <textarea
                cols={cols}
                rows={rows}
                className={`form-control ${className}`}
                onChange={onBlur ? null : e => setValue(e.target.value)}
                value={value}
                onBlur={e => (e.target.value === '' ? null : setValue(e.target.value))}
            />
        ) : (
            <input
                type={`${nmbrInput ? `number` : `text`}`}
                className={`form-control ${className}`}
                onChange={onBlur ? null : e => setValue(e.target.value)}
                value={value}
                defaultValue={defaultValue}
                onBlur={e => (e.target.value === '' ? null : setValue(e.target.value))}
                onFocus={onFocus}
            />
        )}
    </div>
);

export const SelectForm = ({
    onValueClick,
    value,
    onChange,
    options,
    multi,
    placeholder = 'Bitte Wählen',
    className = '',
    label,
    clearable = false,
    autoBlur = false,
    onClick,
    disabled,
}) => {
    return (
        <div className="form-group">
            <label>{label}</label>
            <Select
                value={value}
                autoBlur={autoBlur}
                onChange={onChange}
                options={options}
                multi={multi}
                placeholder={placeholder}
                className={className}
                clearable={clearable}
                onValueClick={onValueClick}
                onClick={onClick}
                disabled={disabled}
            />
        </div>
    );
};

export const AddRatingFormControl = ({ label, value, textArea = false, nmbrInput = false, inputRef, onKeyEnter, readOnly = false }) => (
    <div className="form-group">
        <label>{label}</label>
        {textArea ? (
            <textarea cols="30" rows="10" className="form-control" value={value} />
        ) : (
            <input readOnly={readOnly} onKeyDown={onKeyEnter} ref={inputRef} type={`${nmbrInput ? `number` : `text`}`} className="form-control" value={value} />
        )}
    </div>
);

export const YesNoBtnGroup = ({ label, setValue, active, className }) => (
    <div className={`d-flex flex-column form-group ${className}`}>
        <label>{label}</label>
        <div className="btn-group-justified">
            <div className={`yes-no-btn btn mr-3 ${active ? `btn-primary` : `btn-outline-primary`}`} onClick={() => setValue(true)}>
                Ja
            </div>
            <div className={`yes-no-btn btn ${active ? `btn-outline-primary` : `btn-primary`}`} onClick={() => setValue(false)}>
                Nein
            </div>
        </div>
    </div>
);

export const SelectFormPriceFormat = ({ value, onChange, options, label }) => {
    const defaultOptions = [{ value: 'annual', label: 'jährlich' }, { value: 'monthly', label: 'monatlich' }];
    return <SelectForm value={value} onChange={onChange} options={options === 'default' ? defaultOptions : options} label={label} />;
};

export const SelectFormSummarySchema = ({ value, onChange, options, label = 'Summary' }) => {
    return <SelectForm value={value} onChange={onChange} options={options} label={label} />;
};

export const Btn = ({ label, setValue, active, className = '', btnText, onClick, delBtn = false }) => (
    <div className="d-flex align-items-end form-group">
        {label && <label>{label}</label>}
        <div className="ml-3 btn-group-justified">
            <button
                className={`${className} ${active ? `btn-outline-primary` : `btn-primary`} ${delBtn ? `badge badge-danger del-btn` : ``}`}
                onClick={onClick}>
                {delBtn ? `x` : btnText}
            </button>
        </div>
    </div>
);

export const RemovePageTypeBtn = ({ data, removePageType, pageKey }) => {
    return (
        <React.Fragment>
            <Btn onClick={() => removePageType(pageKey, data.name)} delBtn={true} />
        </React.Fragment>
    );
};

export const PriceRow = ({ setValue, data, item }) => (
    <div className="card-body">
        <h3>Preise</h3>
        <div className="row align-items-baseline">
            {Object.keys(data.accounts).map(acc => (
                <div key={acc} className="col-2 d-flex flex-column justify-content-end">
                    <TypedNumberInput
                        label={`Preis (Wert) von ${data.accounts[acc].name}`}
                        value={item.price.cost[acc]}
                        setValue={value => setValue(`${item.key}.price.cost.${acc}`, value)}
                    />
                </div>
            ))}
            <div className="col-2">
                <SelectFormPriceFormat
                    onChange={choice => setValue(`${item.key}.price`, { ...item.price, priceFormat: choice.value })}
                    options="default"
                    label="Zeitraum"
                    value={item.price.priceFormat}
                />
            </div>
        </div>
    </div>
);

export const getChoices = choices =>
    Object.keys(choices).map(key => ({
        value: key,
        label: choices[key].name,
    }));

export const getChoicesWithoutLabel = choices =>
    Object.keys(choices).map(key => ({
        value: key,
        label: key,
    }));

export const filterArray = (setValue, valueToSet, array, filterEl, id) => {
    const filteredData = array.filter(filterEl => filterEl.id !== id);
    setValue(`${valueToSet}`, filteredData);
};
