'use strict';

import React from 'react';

import PageType_AccountsPreview from './AccountsPreview';
import PageType_BookingsGridTable from './BookingsGridTable';
import PageType_BookingsIconList from './BookingsIconList';
import PageType_CallToAction from './CallToAction';
import PageType_Cards from './Cards';
import PageType_ChoiceMatrix from './ChoiceMatrix';
import PageType_ConfigCodeSelector from './ConfigCodeSelector';
import PageType_ContactForm from './ContactForm';
import PageType_ContactForm2 from './ContactForm2';
import PageType_CreditCards from './CreditCards';
import PageType_DispoCredit from './DispoCredit';
import PageType_FileUpload from './FileUpload';
import PageType_Form from './Form';
import PageType_Html from './Html';
import PageType_IconChoice from './IconChoice';
import PageType_ImageList from './ImageList';
import PageType_Refund from './Refund';
import PageType_ServicePackages from './ServicePackages';
import PageType_Slider from './Slider';
import PageType_Sortable from './Sortable';
import PageType_Summary from './Summary';
import PageType_TrendSlider from './TrendSlider';
import PageType_ConstructionFinancing, { Summary as Summary_ConstructionFinancing } from './ConstructionFinancing';
import PageType_YesNo from './YesNo';

const Views = {
    ACCOUNTS_PREVIEW: 'account_preview',
    BOOKINGS_GRID_TABLE: 'bookings_grid_table',
    BOOKINGS_ICON_LIST: 'bookings_icon_list',
    CALL_TO_ACTION: 'call_to_action',
    CARDS: 'cards',
    CHOICE_MATRIX: 'choice_matrix',
    CONFIG_CODE_SELECTOR: 'config_code_selector',
    CONTACT_FORM: 'contact_form',
    CONTACT_FORM2: 'contact_form2',
    CREDIT_CARDS: 'creditCards',
    DISPO_CREDIT: 'dispo_credit',
    FORM: 'form',
    HTML: 'html',
    ICON_CHOICE: 'icon_choice',
    IMAGE_LIST: 'image_list',
    REFUND: 'refund',
    SERVICE_PACKAGES: 'servicePackages',
    SLIDER: 'slider',
    SORTABLE: 'sortable',
    SUMMARY: 'summary',
    TREND_SLIDER: 'trend_slider',
    CONSTRUCTION_FINANCING: 'construction_financing',
    YES_NO: 'yesno',
    FILE_UPLOAD: 'file_upload',
};

const ViewMapping = {
    [Views.ACCOUNTS_PREVIEW]: PageType_AccountsPreview,
    [Views.BOOKINGS_GRID_TABLE]: PageType_BookingsGridTable,
    [Views.BOOKINGS_ICON_LIST]: PageType_BookingsIconList,
    [Views.CARDS]: PageType_Cards,
    [Views.CHOICE_MATRIX]: PageType_ChoiceMatrix,
    [Views.CALL_TO_ACTION]: PageType_CallToAction,
    [Views.CONFIG_CODE_SELECTOR]: PageType_ConfigCodeSelector,
    [Views.CONTACT_FORM]: PageType_ContactForm,
    [Views.CONTACT_FORM2]: PageType_ContactForm2,
    [Views.CONSTRUCTION_FINANCING]: PageType_ConstructionFinancing,
    [Views.CREDIT_CARDS]: PageType_CreditCards,
    [Views.DISPO_CREDIT]: PageType_DispoCredit,
    [Views.FORM]: PageType_Form,
    [Views.FILE_UPLOAD]: PageType_FileUpload,
    [Views.HTML]: PageType_Html,
    [Views.ICON_CHOICE]: PageType_IconChoice,
    [Views.IMAGE_LIST]: PageType_ImageList,
    [Views.REFUND]: PageType_Refund,
    [Views.SERVICE_PACKAGES]: PageType_ServicePackages,
    [Views.SLIDER]: PageType_Slider,
    [Views.SUMMARY]: PageType_Summary,
    [Views.SORTABLE]: PageType_Sortable,
    [Views.TREND_SLIDER]: PageType_TrendSlider,
    [Views.YES_NO]: PageType_YesNo,
};

const SummariesMapping = {
    [Views.CONSTRUCTION_FINANCING]: Summary_ConstructionFinancing,
};

export const getView = type => {
    if (typeof ViewMapping[type] === 'undefined') {
        throw new Error(`${type} is not a valid PageType.`);
    }
    return ViewMapping[type];
};

export const getSummary = (type, value = {}) => {
    if (typeof SummariesMapping[type] === 'undefined') {
        throw new Error(`${type} is not a valid Summary Identifier.`);
    }

    const Component = SummariesMapping[type];
    return <Component value={value} />;
};
