'use strict';

import React from 'react';
import classNames from 'classnames';

import BtnPrint from '../components/BtnPrint';
import FontAwesomeIcon from '../components/FontAwesomeIcon';

export default class ActionButtons extends React.Component {
    render() {
        const { pageNext, gotoNextPage, pagePrevious, gotoPreviousPage, page, canNext } = this.props;

        return (
            <div>
                {page.print && <BtnPrint/>}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {pagePrevious && <button className="btn btn-primary" onClick={() => gotoPreviousPage()}><FontAwesomeIcon icon="fa-chevron-left"/> Zurück</button>}&nbsp;
                {pageNext && <button className={classNames('btn', 'btn-success', { disabled: !canNext })} onClick={() => canNext && gotoNextPage()}>Weiter <FontAwesomeIcon icon="fa-chevron-right"/></button>}
            </div>
        );
    }
}
