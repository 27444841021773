import React from 'react';
import { FormControl, SelectFormPriceFormat, Btn } from './Helper/FormControl';
import { getNextKey } from './Helper/nextKey';
import TypedNumberInput from '../../components/TypedNumberInput';

const EMPTY_VALUE = {
    name: '',
    image: '',
    url: '',
    price: {
        baseFee: 0,
        priceFormat: 'monthly',
    },
};

export default ({ data, setValue, removeKey }) => {
    return (
        <div>
            {getAccounts(data.accounts).map(account => {
                return (
                    <div key={account.key} className="card mb-3">
                        <div className="card-body border-bottom">
                            <div className="row">
                                <div className="col">
                                    <h2>{account.name}</h2>
                                </div>
                                <div className="col-auto">
                                    <Btn delBtn={true} onClick={() => removeKey(account.key)} />
                                </div>
                            </div>
                        </div>

                        <div className="card-body border-bottom">
                            <FormControl label="Name" value={account.name} setValue={value => setValue(`accounts.${account.key}.name`, value)} />
                            <FormControl label="Bild-URL" value={account.image} setValue={value => setValue(`accounts.${account.key}.image`, value)} />
                            <FormControl label="Produkt-URL" value={account.url} setValue={value => setValue(`accounts.${account.key}.url`, value)} />
                        </div>

                        <div className="card-body">
                            <h3>Preise</h3>
                            <div className="row align-items-baseline">
                                <div className="col-2">
                                    <TypedNumberInput
                                        label="Grundpreis (Wert)"
                                        value={data.accounts[account.key].price.baseFee}
                                        setValue={value => setValue(`accounts.${account.key}.price`, { ...account.price, baseFee: value })}
                                    />
                                </div>
                                <div className="col-2">
                                    <SelectFormPriceFormat
                                        onChange={choice => setValue(`accounts.${account.key}.price`, { ...account.price, priceFormat: choice.value })}
                                        options="default"
                                        label="Preis (Format)"
                                        value={data.accounts[account.key].price.priceFormat}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            <button className="btn btn-outline-secondary mb-5" onClick={() => addAccountAndSetGlobalPrices(setValue, data)}>
                Konto hinzufügen
            </button>
        </div>
    );
};

const addAccountAndSetGlobalPrices = (setValue, data) => {
    const nextKey = getNextKey(data.accounts, 'konto');
    setValue(`accounts.${nextKey}`, { ...EMPTY_VALUE });
    Object.keys(data.creditCardTypes).forEach(type => {
        setValue(`creditCardTypes.${type}.price.cost`, { ...data.creditCardTypes[type].price.cost, [nextKey]: 0 });
    });
    Object.keys(data.cardTypes).forEach(type => {
        setValue(`cardTypes.${type}.price.cost`, { ...data.cardTypes[type].price.cost, [nextKey]: 0 });
    });
    Object.keys(data.bookingTypes).forEach(type => {
        setValue(`bookingTypes.${type}.price.cost`, { ...data.bookingTypes[type].price.cost, [nextKey]: 0 });
    });
};

const getAccounts = accounts =>
    Object.keys(accounts).map(key => ({
        key,
        ...accounts[key],
    }));
