import React from 'react';
import { Btn, filterArray, FormControl, getChoicesWithoutLabel, SelectForm, SelectFormSummarySchema, YesNoBtnGroup } from '../Helper/FormControl';
import { getNextKey } from '../Helper/nextKey';
import { trigger } from './Trigger/TriggerOptions.js';
import { DefaultTriggerOptions } from './Trigger/TriggerOptions';
import SearchAndSelectIconsOverlay from '../Helper/SearchAndSelectIconsOverlay';

export const label = 'Icon Choice';

export const DEFAULT_OPTIONS = {
    multiple: false,
    summary: false,
    clickTriggersPageChange: false,
    layout: '',
    choices: {},
    groups: [],
};

export const CHOICE_OPTIONS = {
    // name of the choice
    name: '',
    // what gets displayed in the info icon
    info: '',
    // icon choice is capable of own html implementation
    html: '',
    // fa class of the icon
    icon: '',
    // download information
    downloadLink: '',
    downloadIcon: '',
    // display this value on summary page @todo refactor/rename this
    summary: '',
    // overwrite summary from DEFAULT_OPTIONS on item basis
    summaryCategory: '',
    // detail information in a info icon used on summary
    summaryInfo: '',
    // the trigger which gets triggered on
    trigger: {},
    // get only used for calculation
    cost: {},
    supplemental: '',
    // by default, the display function always returns true to display the item
    display: values => true,
    // displays on summary on the left side
    summaryLeft: null,
    // displays on summary on the left side
    summaryRight: null,
};

export default ({ data, setValue, summarySchemaOptions, choices, removeKey, pageKey }) => {
    const layoutOptions = [
        {
            value: 'list',
            label: 'Liste',
        },
        {
            value: 'html',
            label: 'HTML',
        },
        {
            value: 'icon',
            label: 'Icon',
        },
    ];

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12 d-flex">
                    <YesNoBtnGroup label="multiple ?" setValue={value => setValue(`multiple`, value)} active={data.multiple} />
                    <YesNoBtnGroup
                        className="ml-5"
                        label="trigger Page change ?"
                        setValue={value => setValue(`clickTriggersPageChange`, value)}
                        active={data.clickTriggersPageChange}
                    />
                </div>
            </div>
            <SelectForm label="Layout:" multi={false} options={layoutOptions} value={data.layout} onChange={choice => setValue(`layout`, choice.value)} />
            <SelectFormSummarySchema options={summarySchemaOptions} onChange={choice => setValue(`summary`, choice.value)} value={data.summary} />

            <h3>Choices</h3>
            {data.choices &&
                Object.keys(data.choices).map((choice, i) => {
                    return (
                        <div key={i} className="card card-body mb-3 card-border">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>{data.choices[choice].name}</h2>
                                <Btn onClick={() => removeKey(`pages.${pageKey}.types.0.choices.${choice}`)} delBtn={true} />
                            </div>
                            <div className="row align-items-end">
                                <div className="col-5">
                                    <FormControl
                                        label="Name:"
                                        value={data.choices[choice].name}
                                        setValue={value => setValue(`choices.${choice}.name`, value)}
                                    />
                                </div>
                                <div className="col-5">
                                    <FormControl
                                        label="Info:"
                                        value={data.choices[choice].info}
                                        setValue={value => setValue(`choices.${choice}.info`, value)}
                                    />
                                </div>
                                <div className="col-2">
                                    <SearchAndSelectIconsOverlay
                                        labelTitle="Choice Icon"
                                        setValue={setValue}
                                        setValuePath={`choices.${choice}.icon`}
                                        currentSelectedIcon={data.choices[choice].icon}
                                    />
                                </div>
                            </div>
                            {data.layout === 'html' ? (
                                <FormControl
                                    value={data.choices[choice].html}
                                    textArea={true}
                                    label="html:"
                                    setValue={value => setValue(`choices.${choice}.html`, value)}
                                />
                            ) : null}
                            <div className="row align-items-end">
                                <div className="col-10">
                                    <FormControl
                                        label="Downloadlink:"
                                        value={data.choices[choice].downloadLink}
                                        setValue={value => setValue(`choices.${choice}.downloadLink`, value)}
                                    />
                                </div>
                                <div className="col-2">
                                    <SearchAndSelectIconsOverlay
                                        labelTitle="Downloadicon"
                                        setValue={setValue}
                                        setValuePath={`choices.${choice}.downloadIcon`}
                                        currentSelectedIcon={data.choices[choice].downloadIcon}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <FormControl
                                        label="Ergebnis Infoicontext:"
                                        value={data.choices[choice].summaryInfo}
                                        setValue={value => setValue(`choices.${choice}.summaryInfo`, value)}
                                    />
                                </div>
                                <div className="col-6">
                                    <FormControl
                                        label="Ergebnis Text:"
                                        value={data.choices[choice].summary}
                                        setValue={value => setValue(`choices.${choice}.summary`, value)}
                                    />
                                </div>
                            </div>
                            <SelectFormSummarySchema
                                label="trigger, Summaryschema & options"
                                options={summarySchemaOptions}
                                onChange={options => trigger(options.value, `choices.${choice}.trigger`, setValue, choices)}
                                value={selectedTriggerSchema(data, choice)}
                            />
                            {data.choices[choice].trigger && Object.keys(data.choices[choice].trigger).length != 0 ? (
                                <div className="card card-body card-border mb-2 mt-2">
                                    <div className="d-flex justify-content-between">
                                        <h2>Trigger Options</h2>
                                        <Btn onClick={() => setValue(`choices.${choice}.trigger`, {})} delBtn={true} />
                                    </div>
                                    {Object.keys(data.choices[choice].trigger.summary).map((key, i) => (
                                        <DefaultTriggerOptions
                                            key={key}
                                            schema={key}
                                            i={i}
                                            globalChoices={choices}
                                            setValue={setValue}
                                            data={data}
                                            currentChoice={choice}
                                            path={`${[choice]}.trigger`}
                                        />
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            <Btn label="Choice Hinzufügen" btnText="+" className="btn btn-primary mt-3" onClick={() => addChoice(setValue, data)} />

            <hr />

            <h3>Choice Gruppen</h3>
            {data.groups &&
                data.groups.map((group, grpIndex) => {
                    if (group.id === undefined) {
                        setValue(`groups.${grpIndex}`, { ...group, id: Math.random() });
                    }
                    return (
                        <div key={grpIndex} className="card card-body mb-3 card-border">
                            <div className="d-flex align-items-center justify-content-end">
                                <Btn onClick={() => filterArray(setValue, `groups`, data.groups, group, group.id)} delBtn={true} />
                            </div>
                            <div className="row align-items-end">
                                <div className="col-10">
                                    <SelectForm
                                        label="Choices:"
                                        multi={true}
                                        clearable={true}
                                        value={group.choices}
                                        options={getChoicesWithoutLabel(data.choices)}
                                        onChange={choices => setValue(`groups.${grpIndex}.choices`, choices.map(choice => choice.value))}
                                    />
                                </div>
                                <div className="col-2">
                                    <FormControl
                                        label="Grid:"
                                        value={data.groups[grpIndex].grid}
                                        nmbrInput={true}
                                        setValue={value => setValue(`groups.${grpIndex}.grid`, value)}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            <Btn label="Choice Gruppe Hinzufügen" btnText="+" className="btn btn-primary mt-3" onClick={() => addGroup(setValue, data)} />
        </React.Fragment>
    );
};

const addChoice = (setValue, data) => {
    const nextKey = getNextKey(data.choices, 'choice');
    setValue(`choices`, {
        ...data.choices,
        [nextKey]: {
            ...CHOICE_OPTIONS,
        },
    });
};

const addGroup = (setValue, data) => {
    setValue(`groups`, [
        ...data.groups,
        {
            grid: 12,
            choices: [],
            id: Math.random(),
        },
    ]);
};

const selectedTriggerSchema = (data, choice) => {
    if (data.choices[choice].trigger === undefined) {
        return;
    }
    let currentTriggerSchema = null;
    const trigger = data.choices[choice].trigger;
    if (Object.keys(trigger).length != 0) {
        Object.keys(trigger.summary).forEach(key => {
            currentTriggerSchema = key;
        });
        return currentTriggerSchema;
    }
};
