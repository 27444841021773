import React from 'react';
import NavigationItems from './Navigation';
import Accounts from './Accounts';
import BookingTypes from './BookingTypes';
import CardTypes from './CardTypes';
import CreditCardTypes from './CreditCardTypes';
import ServicePackages from './ServicePackages';
import ServicePackageContents from './ServicePackageContents';
import objectPath from 'object-path';
import TabList from './Helper/TabList';
import PagesNav from './PagesNav';
import Page from './Page';

const JSON_textarea = React.createRef();

export default class ConfigTool extends React.Component {
    state = {
        config: DEFAULT_CONFIG,
        activeTab: 'accounts',
        activePage: 'pages',
        editJson: false,
    };

    set(path, data = {}) {
        this.setState(state => {
            objectPath.set(state, `config.${path}`, data);
            return state;
        });
    }

    remove(path) {
        this.setState(state => {
            objectPath.del(state, `config.${path}`);
            return state;
        });
    }

    removePageType(page, pageTypeToRemove) {
        const types = this.state.config.pages[page].types;
        types.forEach((pageType, i) => {
            if (pageType.name === pageTypeToRemove) {
                types.splice(i, 1);
                this.setState({
                    ...DEFAULT_CONFIG,
                    types,
                });
            }
        });
    }

    removeSchema(page, schemaToRemove) {
        const types = this.state.config.pages[page].types;
        types.forEach((type, i) => {
            this.setState(state => {
                objectPath.del(state, ['config', 'pages', page, 'types', i, 'schema', schemaToRemove]);
                return state;
            });
        });
    }

    getSummarySchemaOptions() {
        const { pages } = this.state.config;
        let summarySchemas = [];
        Object.keys(pages).forEach(key => {
            pages[key].types.forEach(pageType => {
                if (pageType.name === 'summary') {
                    summarySchemas = Object.keys(pageType.schema).map(key2 => ({
                        label: pageType.schema[key2].name,
                        value: key2,
                    }));
                }
            });
        });
        return summarySchemas;
    }
    setActiveTab = activeTab => this.setState({ activeTab });

    setActivePage = activePage => {
        this.setState({ activePage });

        if (activePage === 'pages') {
            this.editConfigData();
            this.setState({ editJson: false });
        }
    };

    editConfigData = e => {
        if (e) {
            this.setState({ editJson: !this.state.editJson });
        }
        const acc = function(values) {
            const ret = {};
            for (let i in values) {
                ret['acc_' + i] = values[i];
            }
            return ret;
        };
        if (JSON_textarea.current === null) {
            return;
        }
        const configValue = JSON_textarea.current.value;

        const refactorFunctionToString = value => value.toString().replace(/^[^{]*\{\s*(.*?)\s*\}[^}]*$/s, '$1');

        try {
            const externalConfig = eval(`(${configValue})`);
            let mergedConfig = {
                data: {
                    ...DEFAULT_CONFIG.data,
                    ...externalConfig.data,
                },
                navigation: {
                    ...DEFAULT_CONFIG.navigation,
                    ...externalConfig.navigation,
                },
                options: {
                    ...DEFAULT_CONFIG.options,
                    ...externalConfig.options,
                },
                pages: {
                    ...DEFAULT_CONFIG.pages,
                    ...externalConfig.pages,
                },
            };
            Object.keys(mergedConfig.pages).forEach(key => {
                if (mergedConfig.pages[key].display) {
                    mergedConfig = {
                        ...mergedConfig,
                        pages: {
                            ...mergedConfig.pages,
                            [key]: {
                                ...mergedConfig.pages[key],
                                display: refactorFunctionToString(mergedConfig.pages[key].display),
                            },
                        },
                    };
                }
                mergedConfig.pages[key].types.forEach(pageType => {
                    if (pageType.display) {
                        pageType.display = refactorFunctionToString(pageType.display);
                    }
                    if (pageType.choices) {
                        Object.keys(pageType.choices).forEach(choice => {
                            if (pageType.choices[choice].display) {
                                pageType.choices[choice] = {
                                    ...pageType.choices[choice],
                                    display: refactorFunctionToString(pageType.choices[choice].display),
                                };
                            }
                        });
                    }
                });
            });
            this.setState({ config: mergedConfig });
        } catch (e) {
            if (e instanceof SyntaxError) {
                alert(`ERROR: ${e.message} ! all wrong Syntax will not be saved!`);
                return;
            }
        }
    };

    render() {
        const { config, activeTab, activePage, editJson } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <nav className="navbar mb-3">
                        <ul className="nav nav-tabs mr-auto">
                            <li className="nav-item">
                                <a onClick={() => this.setActivePage('json')} className={`nav-link ${activePage === 'json' ? `active navlink-active` : ``}`}>
                                    JSON
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => this.setActivePage('pages')} className={`nav-link ${activePage === 'pages' ? `active navlink-active` : ``}`}>
                                    Konfigurator
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="row">
                        {activePage === 'json' && (
                            <React.Fragment>
                                <div className="col-12 d-flex justify-content-end position-absolute edit-json">
                                    <button className="btn btn-primary mt-3 mr-4" onClick={e => this.editConfigData(e)}>
                                        {editJson ? 'Save' : 'Edit'}
                                    </button>
                                </div>
                                <div className="col-12">
                                    <textarea
                                        disabled={`${editJson ? `` : `disabled`}`}
                                        className="form-control text-monospace json-textarea"
                                        defaultValue={JSON.stringify(config, null, 4)}
                                        onChange={e => this.setState({ config: JSON.parse(e.target.value) })}
                                        ref={JSON_textarea}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                        {activePage === 'pages' && (
                            <>
                                <div className="col-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="mb-4">
                                                <h3>Global</h3>
                                                <TabList
                                                    setActiveTab={activeTab => this.setActiveTab(activeTab)}
                                                    activeTab={activeTab}
                                                    tabs={[
                                                        { value: 'navigation', label: 'Navigation' },
                                                        { value: 'accounts', label: 'Konten' },
                                                        { value: 'bookingTypes', label: 'Buchungstypen' },
                                                        { value: 'cardTypes', label: 'Karten' },
                                                        { value: 'creditCardTypes', label: 'Kreditkarten' },
                                                        { value: 'servicePackages', label: 'Servicepakete' },
                                                        { value: 'servicePackageContents', label: 'Servicepaketattribute' },
                                                    ]}
                                                />
                                            </div>

                                            <h3>Seiten</h3>
                                            <PagesNav
                                                activeTab={activeTab}
                                                setActiveTab={activeTab => this.setActiveTab(activeTab)}
                                                pages={config.pages}
                                                setValue={(key, data) => this.set(`pages.${key}`, data)}
                                                removeKey={key => this.remove(`pages.${key}`)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    {activeTab === 'navigation' && (
                                        <NavigationItems
                                            data={config.navigation}
                                            pages={config.pages}
                                            removeKey={key => this.remove(`navigation.${key}`)}
                                            setValue={(key, data) => this.set(`navigation.${key}`, data)}
                                        />
                                    )}
                                    {activeTab === 'accounts' && (
                                        <Accounts
                                            data={config.data}
                                            accounts={config.data.accounts}
                                            setValue={(key, data) => this.set(`data.${key}`, data)}
                                            removeKey={key => this.remove(`data.accounts.${key}`)}
                                        />
                                    )}
                                    {activeTab === 'bookingTypes' && (
                                        <BookingTypes
                                            data={config.data}
                                            setValue={(key, data) => this.set(`data.bookingTypes.${key}`, data)}
                                            removeKey={key => this.remove(`data.bookingTypes.${key}`)}
                                        />
                                    )}
                                    {activeTab === 'cardTypes' && (
                                        <CardTypes
                                            data={config.data}
                                            setValue={(key, data) => this.set(`data.cardTypes.${key}`, data)}
                                            removeKey={key => this.remove(`data.cardTypes.${key}`)}
                                        />
                                    )}
                                    {activeTab === 'creditCardTypes' && (
                                        <CreditCardTypes
                                            data={config.data}
                                            setValue={(key, data) => this.set(`data.creditCardTypes.${key}`, data)}
                                            removeKey={key => this.remove(`data.creditCardTypes.${key}`)}
                                        />
                                    )}
                                    {activeTab === 'servicePackages' && (
                                        <ServicePackages
                                            data={config.data}
                                            setValue={(key, data) => this.set(`data.servicePackages.${key}`, data)}
                                            removeKey={key => this.remove(`data.servicePackages.${key}`)}
                                        />
                                    )}
                                    {activeTab === 'servicePackageContents' && (
                                        <ServicePackageContents
                                            data={config.data}
                                            addServicePackage={data => this.set(`data.servicePackageContents`, data)}
                                            setValue={(key, data) => this.set(`data.servicePackageContents.${key}`, data)}
                                            removeKey={key => this.remove(`data.servicePackageContents.${key}`)}
                                        />
                                    )}
                                    {0 === activeTab.indexOf(`${activeTab}`) && (
                                        <Page
                                            page={config.pages[activeTab]}
                                            summarySchemaOptions={this.getSummarySchemaOptions()}
                                            choices={config.data}
                                            setValue={(key, data) => this.set(`pages.${activeTab}.${key}`, data)}
                                            setBookingTypeIcon={(key, data) => this.set(`data.bookingTypes.${key}`, data)}
                                            removePageType={(page, pageTypeToRemove) => this.removePageType(page, pageTypeToRemove)}
                                            removeSchema={(page, schemaToRemove) => this.removeSchema(page, schemaToRemove)}
                                            pageKey={activeTab}
                                            navigationData={config.navigation}
                                            setNaviValue={(key, data) => this.set(`navigation.${key}`, data)}
                                            removeKey={key => this.remove(key)}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const DEFAULT_CONFIG = {
    navigation: {
        Seitenname: [],
    },
    pages: {
        page_1: {
            types: [],
            required: false,
            defaultValue: null,
            h1: 'Seitenname',
            h2: '',
            h3: '',
            print: false,
        },
    },
    data: {
        accounts: {
            konto_1: {
                name: 'VR Geschäftskonto',
                hidden: false,
                image: 'https://tools.genolotse.de/uploads/werdenfels/Kontosiegel_blau_1.png?del',
                url: 'https://www.vr-werdenfels.de/girokonto/kontomodelle/konto-fuer-firmen/vr-geschaeftskonto-basic.html',
                price: {
                    baseFee: 5.95,
                    priceFormat: 'monthly',
                },
            },
        },
        bookingTypes: {
            book_1: {
                name: 'Bargeschäfte am Geldautomat',
                info:
                    'Darunter fallen alle Abhebungen am Geldautomat, aber auch die Einzahlungen von Münz- oder Scheingeld, die Sie am SB-Automat mit Einzahlfunktion durchführen. Diese Automaten sind in der Regel deutlich gekennzeichnet bzw. unterscheiden sich leicht von Automaten, die "nur" Geld auszahlen können.',
                price: {
                    cost: {
                        konto_1: 0,
                    },
                    priceFormat: 'monthly',
                },
            },
        },
        cardTypes: {
            card_1: {
                name: 'VR-BankCard',
                info:
                    'Die VR-BankCard ermöglicht es Ihnen oder Ihren Mitarbeitern Bargeld rund um die Uhr an unseren Geldautomaten die Tageseinnahmen einzuzahlen, Bargeld abzuholen, Kontoauszüge auszudrucken, Überweisungen an unseren Selbstbedienungsterminals zu tätigen und Einkäufe bequem mit der Karte zu bezahlen.',
                image: 'https://tools.genolotse.de/uploads/werdenfels/vr-bankcard.png?del',
                price: {
                    cost: {
                        konto_1: 0,
                    },
                    priceFormat: 'annual',
                },
            },
        },
        creditCardTypes: {
            cc_1: {
                name: 'VISA BusinessCard',
                type: 'visa',
                info:
                    'Optimale Abrechnung von Reisekosten und Spesen. Neben einem längeren Zahlungsziel haben Sie auch eine gute Übersicht über die Ausgaben pro Karteninhaber.',
                image: 'https://tools.genolotse.de/uploads/werdenfels/businesscard_visa_classic.png?del',
                price: {
                    cost: {
                        konto_1: 40,
                    },
                    priceFormat: 'annual',
                },
            },
        },
        servicePackages: {
            sp_1: {
                name: 'Premium',
                headerImg: 'https://tools.genolotse.de/uploads/werdenfels/konto_saeulengrafik_premium_o.png?del',
                footerImg: 'https://tools.genolotse.de/uploads/werdenfels/konto_saeulengrafik_u_2.png?del',
                price: {
                    cost: 0,
                    priceFormat: 'monthly',
                },
            },
        },
        servicePackageContents: [
            {
                name: 'VR-ComputerCheck',
                in: [],
                info: 'Der VR-ComputerCheck kann Sicherheitsprobleme auf Ihrem Computer erkennen und hilft bei der Lösung von gefundenen Sicherheitslücken.',
                price: '',
            },
        ],
    },
    options: {
        h1: 'Welches Konto passt zu Ihnen?',
        h2: 'In 6 Schritten zum passenden Girokonto',
        h3: '',
        printSummary: 'Summe kalkulatorische Kontoabrechnung zzgl. rechnerische monatliche Kosten für Bank- und Kreditkarten.',
        imprint: 'https://www.vbkraichgau.de/hilfeundservice/rechtliche-hinweise/impressum.html',
        legal:
            'Die abschließende Kontomodell-Empfehlung des Kontoanbieters gibt Ihnen eine Indikation für ein passendes Zahlungskonto, die auf den von Ihnen getätigten Eingaben in Verbindung mit dem derzeit gültigen Preis- und Leistungsverzeichnis beruht und stellt keine Entgeltinformation nach § 47 Abs. 2 ZKG dar! Unsere vorvertraglichen Entgeltinformationen, ein Glossar sowie Vergleichskriterien gemäß § 14 Absatz 1 Nr. 5 ZKG in Verbindung mit § 14 Absatz 3 ZKG finden Sie <a href="https://www.vbkraichgau.de/hilfeundservice/rechtliche-hinweise/pflichtinformationen/entgeltinformationen.html" target="_blank">hier</a>. Alle Rechte sind vorbehalten. Generell verweisen wir auf unser Preis- und Leistungsverzeichnis. Alle Angaben in Euro. Irrtümer und Druckfehler vorbehalten. Es können auch Entgelte für hier nicht aufgeführte Dienste oder Dienstleistungspakete anfallen. Entgelte werden nur dann erhoben, wenn Buchungen im Auftrag des Kunden fehlerfrei durchgeführt werden. Storno und Berichtigungsbuchungen wegen fehlerhaften Buchungen werden nicht berechnet. Kreditkarten unterliegen unseren Bonitätsvoraussetzungen bzw. eine Volljährigkeit wird vorausgesetzt (bei ClassicCard und GoldCard).',
        height: 400,
        templateAccountPreview: false,
        displayAccountPrice: false,
        priceInfo:
            '<p>Diese Summe sagt Ihnen, was Sie rechnerisch pro Monat zahlen für Entgelte aus <br>Zahlungen (ohne Karten), Karten und Bargeld. Mit dem Zahlungskonto oder etwaigen<br> Überziehungen verbundene Dienste oder Dienstleistungspakete sind nicht enthalten.<br><strong> Beachten Sie bitte die rechtlichen Hinweise.</strong></p>',
        buttonsWithCheck: true,
        debug: true,
    },
};
