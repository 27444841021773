'use strict';

import React from 'react';
import FontAwesomeIcon from '../components/FontAwesomeIcon';

export default class IconChoiceBar extends React.Component {
    getSummariesWithIcons() {
        const { triggers, values } = this.props;

        let summaries = [];

        Object.keys(triggers).forEach(pageTypeKey => {
            Object.keys(triggers[pageTypeKey].summary).forEach(summarySection => {
                Object.keys(triggers[pageTypeKey].summary[summarySection]).forEach(key => {
                    let summary = triggers[pageTypeKey].summary[summarySection][key];
                    if (summary.icon) {
                        summaries.push(summary);
                    }
                });
            });
        });

        return summaries;
    }

    render() {
        const { jumpToPage } = this.props;

        return (
            <ol className="list-inline mb-0">
                {this.getSummariesWithIcons().map((summary, i) => (
                    <li key={`${i}_${summary.icon}`} className="list-inline-item" onClick={jumpToPage}>
                        {console.log(summary.icon)}
                        {summary.icon.startsWith('http') ? (
                            <img src={summary.icon} alt="" className="img-fluid" />
                        ) : (
                            <FontAwesomeIcon icon={summary.icon} size="2x" className="blue" />
                        )}
                    </li>
                ))}
            </ol>
        );
    }
}
