import React from 'react';
import { Btn, FormControl, SelectForm, PriceRow } from './Helper/FormControl';
import { addConfigData } from './Helper/addConfigData';

const EMPTY_VALUE = {
    name: '',
    type: '',
    info: '',
    image: '',
};

export default ({ data, setValue, removeKey }) => {
    const ccOptions = [
        {
            value: 'mc',
            label: 'MasterCard',
        },
        {
            value: 'visa',
            label: 'Visa',
        },
    ];
    return (
        <div>
            {getCreditCardTypes(data.creditCardTypes).map(creditCardType => (
                <div key={creditCardType.key} className="card mb-2">
                    <div className="card-body border-bottom">
                        <div className="row">
                            <div className="col">
                                <h2>{creditCardType.name}</h2>
                            </div>
                            <div className="col-auto">
                                <Btn delBtn={true} onClick={() => removeKey(creditCardType.key)} />
                            </div>
                        </div>
                    </div>

                    <div className="card-body border-bottom">
                        <div className="row">
                            <div className="col-10">
                                <FormControl label="Name" value={creditCardType.name} setValue={value => setValue(`${creditCardType.key}.name`, value)} />
                            </div>
                            <div className="col-2">
                                <SelectForm
                                    label="Typ"
                                    value={creditCardType.type}
                                    onChange={value => setValue(`${creditCardType.key}.type`, value.value)}
                                    options={ccOptions}
                                />
                            </div>
                        </div>
                        <FormControl label="Bild-URL" value={creditCardType.image} setValue={value => setValue(`${creditCardType.key}.image`, value)} />
                        <FormControl
                            textArea={true}
                            label="Info"
                            value={creditCardType.info}
                            setValue={value => setValue(`${creditCardType.key}.info`, value)}
                        />
                    </div>
                    <PriceRow setValue={setValue} data={data} item={creditCardType} />
                </div>
            ))}
            <button className="btn btn-outline-secondary mb-5" onClick={() => addConfigData(setValue, data.creditCardTypes, 'cc', EMPTY_VALUE)}>
                Kartentyp hinzufügen
            </button>
        </div>
    );
};

const getCreditCardTypes = creditCardTypes =>
    Object.keys(creditCardTypes).map(key => ({
        key,
        ...creditCardTypes[key],
    }));
