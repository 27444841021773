import React from 'react';
import { Btn, FormControl, SelectForm } from './Helper/FormControl';
import objectPath from 'object-path';

export default ({ data, pages, setValue, removeNavItem, removeKey }) => {
    const navItemNames = getNavItems(data);
    return (
        <div className="card card-body mb-2">
            <div className="row">
                <div className="col">
                    <h2>Navigationsitems</h2>
                </div>
                <div className="col-auto">
                    <Btn btnText="+" className="btn btn-primary" onClick={() => setValue('', [])} />
                </div>
            </div>
            {navItemNames.map((item, i) => {
                const refactorKey = () => {
                    objectPath.del(data, [item]);
                };
                return (
                    <div key={item} className="container-fluid">
                        <div className="row align-items-end">
                            <div className="col-2">
                                <FormControl
                                    label={`nav item name von Seite ${i + 1}`}
                                    onFocus={() => refactorKey()}
                                    defaultValue={item}
                                    setValue={value => setValue(value, [])}
                                    onBlur={true}
                                />
                            </div>
                            <div className="col-9">
                                <SelectForm
                                    multi={true}
                                    label="Subpages (components) :"
                                    clearable={true}
                                    options={getChoices(pages)}
                                    value={data[item]}
                                    onChange={choices => setPageNavigation(setValue, data, choices.map(choice => choice.value), item)}
                                />
                            </div>
                            <div className="col-auto del-nav-item d-flex align-items-center">
                                <button className="btn btn-secondary" onClick={() => removeKey(item)}>
                                    x
                                </button>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const getNavItems = items => Object.keys(items);

const getChoices = choices =>
    Object.keys(choices).map(key => ({
        value: key,
        label: choices[key].h1,
    }));

const setPageNavigation = (setValue, navigationData, choices, item) => {
    let currentChoice = null;
    choices.forEach(choice => (currentChoice = choice));
    Object.keys(navigationData).forEach(key => {
        if (navigationData[key].includes(currentChoice)) {
            let index = navigationData[key].indexOf(currentChoice);
            if (index > -1) {
                navigationData[key].splice(index, 1);
            }
        }
    });
    setValue(item, choices);
};
