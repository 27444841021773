'use strict';

import React, { Component } from 'react';

export default class Thanks extends Component
{
    render() {
        return (
            <div>
                <h2>Vielen Dank für Ihre Nachricht. Ihre Anfrage wurde erfolgreich versendet!</h2>
                <p>Unsere Baufinanzierungsspezialisten werden sie umgehend bearbeiten. Innerhalb der nächsten Bankarbeitstage meldet sich Ihr persönlicher Baufinanzierungsexperte bei Ihnen, um die Details zu Ihrer Finanzierungsanfrage zu besprechen. Im gemeinsamen Gespräch ermittelt und berücksichtigt Ihr Berater Ihre Wünsche und Anforderungen an die Baufinanzierung und erstellt Ihnen daraus ein persönliches Angebot.</p>
            </div>
        );
    }
}
