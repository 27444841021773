import { getNextKey } from './nextKey';

export const addConfigData = (setValue, data, prefix, emptyValue) => {
    const nextKey = getNextKey(data, `${prefix}`);
    setValue(`${nextKey}`, {
        ...emptyValue,
        price: {
            cost: {
                konto_1: 0,
            },
            priceFormat: 'annual',
        },
    });
};
