import React from 'react';

import * as YesNo from './YesNo';
import * as Cards from './Cards';
import * as CreditCards from './CreditCards';
import * as AccountsPreview from './AccountsPreview';
import * as BookingsGridTable from './BookingsGridTable';
import * as IconChoice from './IconChoice';
import * as ChoiceMatrix from './ChoiceMatrix';
import * as Html from './Html';
import * as ServicePackages from './ServicePackages';
import * as Summary from './Summary';

const formatPageType = pageType => ({
    label: pageType.label,
    emptyValue: pageType.DEFAULT_OPTIONS,
    Component: pageType.default,
});

const PageTypes = {
    account_preview: formatPageType(AccountsPreview),
    bookings_grid_table: formatPageType(BookingsGridTable),
    yesno: formatPageType(YesNo),
    cards: formatPageType(Cards),
    creditCards: formatPageType(CreditCards),
    icon_choice: formatPageType(IconChoice),
    choice_matrix: formatPageType(ChoiceMatrix),
    html: formatPageType(Html),
    servicePackages: formatPageType(ServicePackages),
    summary: formatPageType(Summary),
};

export const getAvailableOptions = () => Object.keys(PageTypes).map(key => ({ value: key, label: PageTypes[key].label }));

export const getComponent = key => {
    if (PageTypes[key] === undefined) {
        return null;
    }
    return PageTypes[key].Component;
};

export const getEmptyValue = key => ({
    name: key,
    ...PageTypes[key].emptyValue,
});

export const getLabel = key => PageTypes[key].label;
