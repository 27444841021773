import React from 'react';
import numeral from 'numeral';

export default class NumberInput extends React.Component {
    state = {
        value: formatNumber(this.props.value),
    };

    setValue = (value, force = false) => {
        this.setState({ value });

        let parsed = parseFloat(value.replace(/,/, '.'));
        if (isNaN(parsed)) {
            parsed = 0;
        }
        clearTimeout(this.timeout);
        // set number directly when the entered value is complete
        if (force || value === formatNumber(parsed)) {
            this.setValueInState(parsed);
            return;
        }
        // otherwise wait for 2 seconds to force input
        if (0 !== value.trim().length) {
            this.timeout = setTimeout(() => {
                this.setValueInState(parsed);
            }, 2000);
        }
    };

    setValueInState(value) {
        this.setState({ value: formatNumber(value) });
        this.props.setValue(value);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.value !== formatNumber(nextProps.value)) {
            this.setState({ value: formatNumber(nextProps.value) });
        }
    }

    render() {
        return (
            <div className={`form-group ${this.props.className}`}>
                <label>{this.props.label}</label>
                <input
                    className="form-control"
                    value={this.state.value}
                    onChange={e => this.setValue(e.target.value)}
                    onBlur={e => this.setValue(e.target.value, true)}
                />
            </div>
        );
    }
}

const formatNumber = value => numeral(value).format('0.00');
