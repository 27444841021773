'use strict';

import React from 'react';
import classNames from 'classnames';
import InfoPopover from './InfoPopover';
import Slider from 'rc-slider';

export default class SliderWithInput extends React.Component {
    render() {
        const { label, label_info, min, max, step, marks, unit, value, setValue } = this.props;

        return (
            <div className="slider-with-input">
                <div className="row mb-3">
                    <div className="slider-element col-sm-9">
                        {label && (
                            <p>
                                {label} <InfoPopover>{label_info}</InfoPopover>
                            </p>
                        )}
                        <Slider min={min} max={max} step={step} marks={marks} tipFormatter={null} value={value} onChange={value => setValue(value)} />
                    </div>
                    <div className={classNames('col-sm-3 input-with-suffix pl-3')}>
                        <nobr>
                            <input
                                type="number"
                                min={min}
                                max={max}
                                step={step}
                                className="form-control"
                                value={value}
                                onChange={e => setValue(parseInt(e.target.value) || 0)}
                            />{' '}
                            {unit}
                        </nobr>
                    </div>
                </div>
            </div>
        );
    }
}
