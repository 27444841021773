'use strict';

import React from 'react';
import classNames from 'classnames';

import InfoPopover from '../../components/InfoPopover';
import Price from '../../components/Price';
import * as Analytics from '../analytics';

const DEFAULT_OPTIONS = {
    summary: 'servicePackages',
};

export default class ServicePackages extends React.Component {
    constructor(props) {
        super(props);

        const { setTrigger, options } = this.props;

        // initialize state
        this.state = { value: null };
        setTrigger({}, this.state.value);

        // handler to init values
        props.initValues(value => this.setValue(value));
    }

    getOptions() {
        const { options } = this.props;
        return {
            ...DEFAULT_OPTIONS,
            ...options,
        };
    }

    setValue(value) {
        const { setTrigger, getEmptyTrigger, getPriceCalculator } = this.props;
        const options = this.getOptions();
        value = this.state.value === value || value === null ? null : value;

        // set the trigger based upon the selected items
        let trigger = getEmptyTrigger();

        const activePackage = this.getServicePackage(value);
        if (null !== activePackage) {
            const price = getPriceCalculator(activePackage.price).getPrice();
            trigger.price.addPrice(price);
            trigger.summary[options.summary] = [
                {
                    key: activePackage.key,
                    name: `Zusatzpaket ${activePackage.name}`,
                    info: '',
                    price: price,
                },
            ];
        }
        this.setState({ value });
        setTrigger(trigger, this.state.value);

        Analytics.trackEvent('Component_ServicePackages', 'set_value', 'value', value);
    }

    /**
     * @param key
     * @returns {*}
     */
    getServicePackage(key) {
        if (!key) {
            return null;
        }

        let servicePackage = this.props.data.servicePackages[key];
        servicePackage.contents = this.props.data.servicePackageContents.filter(content => content.in.indexOf(key) > -1);

        return {
            key,
            ...servicePackage,
        };
    }

    render() {
        const { servicePackages } = this.props.data;

        return (
            <div className="text-sm-center">
                <ul className="list-inline">
                    {Object.keys(servicePackages).map(servicePackageKey => {
                        return (
                            <li className="list-inline-item" key={servicePackageKey} onClick={() => this.setValue(servicePackageKey)}>
                                <ServicePackage servicePackage={this.getServicePackage(servicePackageKey)} active={this.state.value === servicePackageKey} />
                            </li>
                        );
                    })}
                </ul>
                <button className={classNames('btn', 'btn-outline-secondary', { active: !this.state.value })} onClick={() => this.setValue(null)}>
                    Ich möchte kein Paket mit Zusatzleistungen zum Onlinebanking
                </button>
            </div>
        );
    }
}

class ServicePackage extends React.Component {
    render() {
        const { servicePackage, active, withActionButton } = this.props;
        return (
            <div className={classNames('service-package', { active })} align="center" style={{ width: '280px' }}>
                <div className="header">{servicePackage.name}</div>
                <div className="body">
                    <ul>
                        {servicePackage.contents.map((servicePackageContent, i) => {
                            return (
                                <li key={servicePackage.name + i}>
                                    <div className="infotab inline">
                                        {servicePackageContent.name} <InfoPopover>{servicePackageContent.info}</InfoPopover>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="text-sm-center d-print-none">
                        <small>Einzelpreis pro Paket</small>
                        <InfoPopover html={false}>
                            <table className="table table-condensed table-striped">
                                <tbody>
                                    {servicePackage.contents.map((servicePackageContent, i) => {
                                        return (
                                            <tr key={servicePackage.name + i}>
                                                <td>{servicePackageContent.name}</td>
                                                <td>{servicePackageContent.price || '-'}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </InfoPopover>
                    </div>
                </div>
                <div className="footer">
                    <div className="price">
                        <Price price={servicePackage.price.cost} />
                        <br />
                        <small>EUR p. M.</small>
                    </div>
                    {withActionButton && (
                        <div className="button">
                            <button className="btn btn-success">
                                {servicePackage.name} {active ? 'entfernen' : 'hinzufügen'}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
ServicePackage.defaultProps = { withActionButton: true };
