'use strict';

import $ from 'jquery';
import React, { Component } from 'react';

import AccountFinderTool from '../AccountFinder/index';

export default class AccountFinder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    loadData(props) {
        const acc = values => {
            var ret = {};
            for (var i in values) {
                ret['acc_' + i] = values[i];
            }
            return ret;
        };

        this.setState({ loading: true });
        $.get(`/tools/${props.match.params.bankSlug}/account-finder/${props.match.params.accountFinderId}/configuration/`, data => {
            let x = null;
            eval(`x = ${data};`);
            x.options.bankLogo = this.props.bankLogo || '';

            this.setState({ data: x, loading: false });
        });
    }

    componentDidMount() {
        this.loadData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.accountFinderId !== this.props.match.params.accountFinderId) {
            this.loadData(nextProps);
        }
    }

    render() {
        if (this.state.loading) {
            return <div />;
        }

        return (
            <div className="container mt-2">
                <AccountFinderTool accountFinderId={this.props.match.params.accountFinderId} bankSlug={this.props.match.params.bankSlug} {...this.state.data} />
            </div>
        );
    }
}
