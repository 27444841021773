export default class DisplayHelper
{
    and(x1, x2) {
        return x1 && x2;
    }

    or(x1, x2) {
        return x1 || x2;
    }

    in(needle, haystack) {
        // convert to array
        if (typeof haystack === 'string') {
            haystack = [haystack];
        }

        return (haystack||[]).indexOf(needle) > -1;
    }
}
