'use strict';

import React from 'react';

import InfoPopover from '../../components/InfoPopover';
import NumberInput from '../../components/NumberInput';
import { AccountPrice, PriceCalculator, FORMAT_MONTHLY as PRICE_FORMAT_MONTHLY, FORMAT_ANNUAL as PRICE_FORMAT_ANNUAL } from '../Model/Price';
import * as Analytics from '../analytics';

const DEFAULT_OPTIONS = {
    groups: [],
    includedGroups: [],
    cards: {},
    use: null,
    summary: 'cards',
    max: null,
};

export default class CreditCards extends React.Component {
    constructor(props) {
        super(props);

        // initialize
        let values = {};
        Object.keys(this.getCardStore()).forEach(id => (values[id] = 0));
        this.state = { values };

        const { setTrigger } = this.props;
        setTrigger({}, this.state.values);

        // handler to init values
        props.initValues(values => this.initValues(values));
    }

    // determine where to fetch data from. 1st attempt is cards directly from options.
    // the old but still supported way is look in data.creditCardTypes
    getCardStore() {
        const options = this.getOptions();
        return Object.keys(options.cards).length > 0 ? options.cards : this.props.data.creditCardTypes;
    }

    initValues(values) {
        Object.keys(values).forEach(id => this.setValue(id, values[id]));
    }

    getIncludedGroups() {
        return this.getOptions().includedGroups.map(sumGroup => {
            return {
                on: null,
                included: null,
                priceFormat: 'monthly',
                cost: null,
                costX: null,
                ...sumGroup,
            };
        });
    }
    getOptions() {
        const { options } = this.props;

        return {
            ...DEFAULT_OPTIONS,
            ...options,
        };
    }

    /**
     * Retrieves all booking types
     *
     * @param onlyWithValues
     * @param ids
     */
    getCreditCardTypes(onlyWithValues = false, ids = null) {
        const { values } = this.state;

        const store = this.getCardStore();
        if (null === ids) {
            ids = Object.keys(store);
        }

        // will return only booking types that are chosen by the user
        if (onlyWithValues) {
            ids = ids.filter(id => values[id] > 0);
        }

        return ids.map(id => {
            // construct booking types object
            return {
                id,
                quantity: values[id],
                price: null,
                ...store[id],
            };
        });
    }

    getAccounts() {
        const { data } = this.props;
        return Object.keys(data.accounts).map(accountKey => {
            return {
                id: accountKey,
                ...data.accounts[accountKey],
            };
        });
    }

    calculate(trigger) {
        const { getEmptyPrice, getPriceCalculator } = this.props;
        const options = this.getOptions();

        trigger.summary[options.summary] = [];

        // handle all creditCard types
        this.getCreditCardTypes(true).forEach(creditCardType => {
            if (null === creditCardType.price) {
                return;
            }

            const price = getPriceCalculator(creditCardType.price).getPrice(creditCardType.quantity);
            const summary = {
                key: creditCardType.id,
                name: creditCardType.name,
                info: creditCardType.info,
                qty: creditCardType.quantity,
                price: price,
            };

            trigger.price.addPrice(price);
            trigger.summary[options.summary].push(summary);
        });

        this.getIncludedGroups()
            .filter(includedGroup => null !== includedGroup.included)
            .forEach(includedGroup => {
                const accountPrice = {};
                this.getAccounts().forEach(account => {
                    accountPrice[account.id] = 0;

                    let included = includedGroup.included[account.id];
                    if (0 === included) {
                        return;
                    }

                    this.getCreditCardTypes(true, includedGroup.on).forEach(creditCardType => {
                        // all will go in, decrease included
                        if (creditCardType.quantity <= included) {
                            included -= creditCardType.quantity;
                            // otherwise
                        } else {
                            const rest = creditCardType.quantity - included;
                            included = 0;

                            // are costs defined globally for sumGroup
                            if (null !== includedGroup.cost) {
                                accountPrice[account.id] += includedGroup.cost[account.id] * rest;
                            }
                            // are costs defined globally for bookingType
                            if (null !== includedGroup.costX) {
                                accountPrice[account.id] += includedGroup.costX[creditCardType.id][account.id] * rest;
                            }
                        }
                    });
                });

                const sumGroupPrice = new AccountPrice(accountPrice, includedGroup.priceFormat);
                if (sumGroupPrice.isNotNull()) {
                    trigger.summary[options.summary].push({
                        key: includedGroup.name,
                        name: includedGroup.name,
                        info: includedGroup.info,
                        price: sumGroupPrice,
                    });
                    // add this price to the global price
                    trigger.price.addPrice(sumGroupPrice);
                }
            });
        return trigger;
    }

    setValue(id, value) {
        let { values } = this.state;
        const { setTrigger, getEmptyTrigger } = this.props;

        const options = this.getOptions();

        if (null !== options.max) {
            value = Math.min(value, options.max);
        }

        values[id] = value;

        this.setState({ values });

        let trigger = getEmptyTrigger();
        this.calculate(trigger);

        setTrigger(trigger, this.state.values);
        Analytics.trackEvent('Component_CreditCards', 'set_value', id, value);
    }

    /**
     * Get relevant credit cards for rendering frontend
     * @param type
     * @returns {*}
     */
    getCreditCards(type = null) {
        const creditCards = this.getCreditCardTypes(false, this.getOptions().use);
        if (type !== null) {
            return creditCards.filter(creditCard => creditCard.type === type);
        }

        return creditCards;
    }

    /**
     * Retrieves all available types. this is "mc" (mastercard) or "visa" or anything else
     */
    getUsedTypes() {
        return this.getCreditCardTypes(false)
            .map(card => card.type)
            .filter((value, index, self) => self.indexOf(value) === index);
    }

    /**
     * Renders component
     * @returns {*}
     */
    render() {
        if (this.getCreditCards().length > 3) {
            // sort by types
            return <div>{this.getUsedTypes().map(type => this.renderCardsList(this.getCreditCards(type)))}</div>;
        } else {
            // no sort by types
            return this.renderCardsList(this.getCreditCards());
        }
    }

    renderCardsList(creditCards) {
        return (
            <ul className="centered-list page-type">
                {creditCards.map(creditCard => (
                    <li key={creditCard.id}>
                        <h4>
                            {creditCard.name} <InfoPopover>{creditCard.info}</InfoPopover>
                        </h4>
                        <img className="img-responsive center-block" src={creditCard.image} />
                        <NumberInput value={this.state.values[creditCard.id]} onChange={value => this.setValue(creditCard.id, value)} />
                    </li>
                ))}
            </ul>
        );
    }
}
//<li>
//    <button className="btn btn-outline-secondary center-block" onClick={() => setActiveType(activeType === 'visa' ? 'mastercard' : 'visa')} style={{width:100}}>
//        {activeType === 'visa' ? 'Mastercard' : 'Visa'}
//    </button>
//</li>
