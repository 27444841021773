import React from 'react';
import { Btn, FormControl, PriceRow } from './Helper/FormControl';
import { addConfigData } from './Helper/addConfigData';

const EMPTY_VALUE = {
    name: '',
    info: '',
    icon: '',
};

export default ({ data, setValue, removeKey }) => {
    return (
        <div>
            {getBookingTypes(data.bookingTypes).map(bookingType => (
                <div key={bookingType.key} className="card mb-2">
                    <div className="card-body border-bottom">
                        <div className="row">
                            <div className="col">
                                <h2>{bookingType.name}</h2>
                            </div>
                            <div className="col-auto">
                                <Btn delBtn={true} onClick={() => removeKey(bookingType.key)} />
                            </div>
                        </div>
                    </div>

                    <div className="card-body border-bottom">
                        <FormControl label="Name" value={bookingType.name} setValue={value => setValue(`${bookingType.key}.name`, value)} />
                        <FormControl textArea={true} label="Info" value={bookingType.info} setValue={value => setValue(`${bookingType.key}.info`, value)} />
                    </div>

                    <PriceRow setValue={setValue} data={data} item={bookingType} />
                </div>
            ))}
            <button className="btn btn-outline-secondary mb-5" onClick={() => addConfigData(setValue, data.bookingTypes, 'book', EMPTY_VALUE)}>
                Buchungstyp hinzufügen
            </button>
        </div>
    );
};

const getBookingTypes = bookingTypes =>
    Object.keys(bookingTypes).map(key => ({
        key,
        ...bookingTypes[key],
    }));
