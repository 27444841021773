import $ from 'jquery';
import {inIframe} from "./iframe";

var fid;
var data;
var herkunft;

function setIframeHeight(height) {
    var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    var eventer = window[eventMethod];
    var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    eventer(messageEvent, function (event) {
        data = event.data;
        if (!data || data.length === 0) {
            return
        }
        data = data.split('-');
        if (data.length !== 2) {
            return
        }
        if (data[0] !== 'height') {
            return
        }
        fid = parseInt(data[1], 10);
        if (isNaN(fid)) {
            return
        }
        herkunft = event.origin;
        event.source.postMessage(fid + '-' + height, event.origin)
    }, false)
}

if (inIframe()) {
    var hoehe = $('#app').height() + 10;
    setIframeHeight(hoehe);
    window.setInterval(function () {
        hoehe = $('#app').height() + 10;
        parent.postMessage(fid + '-' + hoehe, herkunft)
    }, 400);
}
