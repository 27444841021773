'use strict';

import React from 'react';

export default class ImageGrayscaled extends React.Component {
    render() {
        const { src, gray, className } = this.props;

        let image;
        if (gray) {
            image = src.replace(/(\.[^\.]+)$/gi, '_gs$1');
        } else {
            image = src;
        }
        return <img src={image} className={className} />;
    }
}
ImageGrayscaled.defaultProps = {
    className: '',
    gray: false,
};
