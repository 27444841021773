'use strict';

import $ from 'jquery';
import React, { Component } from 'react';

import QuickFinderTool from '../QuickFinder/index';

export default class QuickFinder extends Component
{
    componentDidMount() {
        $.getJSON(`/tools/${this.props.match.params.bankSlug}/quick-finder/${this.props.match.params.quickFinderSlug}/configuration/`, data => {
            this.setState({data});
        });
    }

    render() {
        if (null === this.state) {
            return <div/>;
        }

        return (
            <div className="container mt-2">
                <QuickFinderTool {...this.state.data} />
            </div>
        );
    }
}
