import React from 'react';
import { FormControl, SelectForm, getChoices, Btn } from './Helper/FormControl';

const EMPTY_VALUE = {
    name: '',
    in: [],
    info: '',
    price: '',
};

export default ({ data, addServicePackage, setValue, removeKey }) => {
    return (
        <div>
            {getServicePackageContents(data.servicePackageContents).map(servicePackageContent => (
                <div key={servicePackageContent.key} className="card mb-2">
                    <div className="card-body border-bottom">
                        <div className="row">
                            <div className="col">
                                <h2>{servicePackageContent.name}</h2>
                            </div>
                            <div className="col-auto">
                                <Btn delBtn={true} onClick={() => removeKey(servicePackageContent.key)} />
                            </div>
                        </div>
                    </div>

                    <div className="card-body border-bottom">
                        <FormControl label="Name" value={servicePackageContent.name} setValue={value => setValue(`${servicePackageContent.key}.name`, value)} />
                        <SelectForm
                            multi={true}
                            className="mb-2"
                            options={getChoices(data.servicePackages)}
                            onChange={choices => setValue(`${servicePackageContent.key}.in`, choices.map(choice => choice.value))}
                            value={servicePackageContent.in}
                            clearable={true}
                            label="enthalten in:"
                        />
                        <FormControl
                            textArea={true}
                            label="Info"
                            value={servicePackageContent.info}
                            setValue={value => setValue(`${servicePackageContent.key}.info`, value)}
                        />
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <FormControl
                                    label="Preis (Infoicon, String)"
                                    value={servicePackageContent.price}
                                    setValue={value => setValue(`${servicePackageContent.key}.price`, value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <button className="btn btn-outline-secondary mb-5" onClick={() => addServicePackageContent(addServicePackage, data.servicePackageContents)}>
                Servicepaketattribut hinzufügen
            </button>
        </div>
    );
};

const getServicePackageContents = servicePackageContents =>
    Object.keys(servicePackageContents).map(key => ({
        key,
        ...servicePackageContents[key],
    }));

const addServicePackageContent = (addServicePackage, data) => {
    addServicePackage([
        ...data,
        {
            ...EMPTY_VALUE,
        },
    ]);
};
