import React from 'react';
import { FormControl, getChoices, SelectForm, SelectFormSummarySchema } from '../Helper/FormControl';

export const label = 'Cards';

export const DEFAULT_OPTIONS = {
    summary: null,
    max: null,
    trigger: null,
    use: null,
};

export default ({ data, setValue, removeKey, summarySchemaOptions, choices }) => {
    return (
        <React.Fragment>
            <div className="row align-items-baseline">
                <div className="col-10">
                    <SelectForm
                        multi={true}
                        label="Auswahl der angezeigten Karten:"
                        options={getChoices(choices.cardTypes)}
                        onChange={choices => setValue(`use`, choices.map(choice => choice.value))}
                        clearable={true}
                        value={data.use}
                    />
                </div>
                <div className="col-2">
                    <FormControl nmbrInput={true} value={data.max} label="maximale Anzahl an Karten:" setValue={value => setValue(`max`, value)} />
                </div>
            </div>
            <SelectFormSummarySchema options={summarySchemaOptions} onChange={choice => setValue(`summary`, choice.value)} value={data.summary} />
        </React.Fragment>
    );
};
