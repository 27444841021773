import React from 'react';
import { SelectFormSummarySchema } from '../Helper/FormControl';

export const label = 'Servicepackages';

export const DEFAULT_OPTIONS = {
    summary: '',
};

export default ({ data, summarySchemaOptions, setValue }) => {
    return (
        <React.Fragment>
            <SelectFormSummarySchema options={summarySchemaOptions} onChange={choice => setValue(`summary`, choice.value)} value={data.summary} />
        </React.Fragment>
    );
};
