'use strict';

import React, { Component } from 'react';
import classNames from 'classnames';
import FontAwesomeIcon from '../components/FontAwesomeIcon';

export default class QuickFinder extends Component
{
    constructor(props) {
        super(props);

        const categoryIds = Object.keys(props.categories);
        this.state = {
            category: categoryIds.length > 0 ? categoryIds[0] : null,
            search: ''
        };
    }


    highlightSearchTerm(string, tag = 'u') {
        const { search } = this.state;

        if (0 === search.length) {
            return string;
        }

        const regex = new RegExp('('+search+')', 'i');
        string = string.replace(regex, '<' + tag + '>$1</' + tag + '>');
        return string;
    }

    setSearch(search) {
        this.setState({ search:search.toLowerCase() });
    }

    /**
     * @returns {*}
     */
    getActiveCategory() {
        if (null === this.state.category) {
            return null;
        }
        return this.props.categories[this.state.category];
    }

    setActiveCategory(category) {
        this.setState({
            category: category.id
        });
    }

    getCategories() {
        const { categories } = this.props;
        return Object.keys(categories).map(id => categories[id]);
    }

    getItems() {
        const { items } = this.props;

        const { search } = this.state;
        if (search.length > 0) {
            return Object
                .keys(items)
                .map(id => items[id])
                .filter(item => (item.headline+'//'+item.content).toLowerCase().indexOf(search) > -1)
            ;
        }

        const activeCategory = this.getActiveCategory();
        if (null === activeCategory) {
            return [];
        }

        return activeCategory.item_ids.map(id => items[id]);
    }


    render() {
        const { search } = this.state;

        const items = this.getItems().map(item =>
            <div key={item.id} className="card mb-2">
                <div className="card-body">
                    <h3 dangerouslySetInnerHTML={{__html:this.highlightSearchTerm(item.headline)}}/>
                    <span dangerouslySetInnerHTML={{__html:item.content}}/>
                </div>
            </div>
        );

        return (
            <div className="container-fluid">
                <div className="tool-faq">
                    <h2>{this.props.name}</h2>

                    <div className="input-group searchbox">
                        <input type="search" placeholder="Suche..." className="form-control" onChange={(e) => this.setSearch(e.target.value)} />
                        <div className="input-group-btn">
                            <button className="btn btn-outline-secondary">
                                <FontAwesomeIcon icon="fa-search"/>
                            </button>
                        </div>
                    </div>

                    {search.length > 0 && items}
                    {search.length === 0 &&
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="list-group">
                                    {this.getCategories().map(category =>
                                        <a key={category.id}
                                           href="#"
                                           className={classNames('list-group-item', { active: this.state.category == category.id })}
                                           onClick={() => this.setActiveCategory(category)}>
                                            {category.name}
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-8">
                                {items}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
