'use strict';

import React from 'react';
import { AccountPrice, FORMAT_ANNUAL } from '../Model/Price';

const DEFAULT_OPTIONS = {
    icon: 'fa-door-open',
    introHtml: '<p>Gemeinsam führen wir eine 360° Analyse durch. Die nachfolgenden Fragen sind Grundlage für unsere Empfehlungen.</p>',
    errorMessageInvalidCode: 'Ihr eingegebener Zugangscode ist ungültig',
    actionButton: 'Beratung starten',
    label: 'Bitte geben Sie Ihren Zugangscode ein:',
    summary: '',
    info: '',
    withDisclaimer: false,
    disclaimer:
        'Die Speicherung und Weiterverarbeitung Ihrer vorstehend erhobenen personenbezogenen Daten dienen ausschließlich der ordnungsgemäßen Bearbeitung Ihrer Anfrage. Jedwede anderweitige Nutzung ist ausgeschlossen. Nach Beantwortung Ihrer Anfrage werden Ihre Daten gelöscht. Bitte beachten Sie im Übrigen auch unseren Datenschutzhinweis. Zu Ihrer Sicherheit werden Ihre Daten SSL-verschlüsselt übertragen.',
    disclaimerLinks: {},
};

export default class ConfigCodeSelector extends React.Component {
    state = {
        error: false,
    };

    getDisclaimerHtml() {
        const options = this.getOptions();

        let disclaimer = options.disclaimer;

        Object.keys(options.disclaimerLinks).forEach(word => {
            const link = options.disclaimerLinks[word];
            disclaimer = disclaimer.replace(word, `<a href="${link}" target="_blank">${word}</a>`);
        });

        return disclaimer;
    }

    async submit(e, code) {
        e.preventDefault();

        const { loadConfigCode, gotoNextPage, getEmptyTrigger, setTrigger } = this.props;

        const options = this.getOptions();
        let trigger = getEmptyTrigger();

        this.setState({ error: false });
        const data = await loadConfigCode(code);
        if (data.success) {
            gotoNextPage();

            // if summary flag is set, add current values to this summary namespace
            if (false !== options.summary) {
                const price = new AccountPrice({}, FORMAT_ANNUAL);
                trigger.price.addPrice(price);
                trigger.summary[options.summary] = [
                    {
                        key: 'config_code_selector',
                        name: 'Ihr Zugangscode',
                        info: options.info,
                        text: code,
                        price: price,
                    },
                ];
            }
        } else {
            this.setState({ error: true });
        }

        setTrigger(trigger);

        return false;
    }

    getOptions() {
        return {
            ...DEFAULT_OPTIONS,
            ...this.props.options,
        };
    }

    render() {
        const options = this.getOptions();
        return (
            <div className="row align-items-center justify-content-center">
                <div className="col-6 text-center">
                    <form onSubmit={e => this.submit(e, this.input.value)}>
                        {options.icon && <span className={`fas fa-5x ${options.icon} mb-4 blue`} />}
                        <div dangerouslySetInnerHTML={{ __html: options.introHtml }} />
                        <div className="form-group">
                            <label>{options.label}</label>
                            <input type="text" className="form-control form-control-lg text-center" ref={ref => (this.input = ref)} required />
                            {this.state.error && <small className="form-text text-danger">{options.errorMessageInvalidCode}</small>}
                        </div>

                        {options.withDisclaimer && (
                            <div className="form-group small">
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" required="required" /> <span dangerouslySetInnerHTML={{ __html: this.getDisclaimerHtml() }} />
                                    </label>
                                </div>
                            </div>
                        )}

                        <button type="submit" className="btn btn-primary btn-lg">
                            {options.actionButton}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}
