'use strict';

import React, { Component } from 'react';
import numeral from 'numeral';
import de from 'numeral/locales/de';
import $ from 'jquery';
import Slider from 'rc-slider';

import PageCalculator from './Pages/Calculator';
import PageContact from './Pages/Contact';
import PageThanks from './Pages/Thanks';
import InfoPopover from '../components/InfoPopover';

numeral.locale('de', de);
numeral.locale('de');

const PAGE_CALCULATOR = 'calculator';
const PAGE_CONTACT = 'contact';
const PAGE_THANKS = 'thanks';

let PAGES = {};
PAGES[PAGE_CALCULATOR] = PageCalculator;
PAGES[PAGE_CONTACT] = PageContact;
PAGES[PAGE_THANKS] = PageThanks;

export class ConstructionFinancingLarge extends Component {
    constructor(props) {
        super(props);

        let zinsbindungIndex = 0;
        if (props.defaults.zinsbindung) {
            this.getInterests().forEach((interest, i) => {
                if (interest[0] == props.defaults.zinsbindung) {
                    zinsbindungIndex = i;
                }
            });
        }

        this.state = {
            values: {
                kaufpreis: props.defaults.kaufpreis || 100000,
                eigenkapital: props.defaults.eigenkapital || 50000,
                tilgungsrate: props.defaults.tilgungsrate || 4,
                zinsbindung: zinsbindungIndex,
            },
            page: PAGE_CALCULATOR,
        };
    }

    setPage(page) {
        this.setState({ page });
    }

    setValue(name, value) {
        let { values } = this.state;
        values[name] = value;
        this.setState({ values });
    }

    onContactFormData(data) {
        const fullData = {
            contact: data,
            creditData: this.calculateValues(),
        };

        $.post(`/tools/${this.props.bankSlug}/construction-financing/contact-react/`, fullData, result => {
            if (this.props.defaults.redirectUrl && this.props.defaults.redirectUrl.length !== 0) {
                top.location.href = this.props.defaults.redirectUrl;
            } else {
                this.setPage(PAGE_THANKS);
            }
        });
    }

    calculateValues() {
        const { kaufpreis, eigenkapital, tilgungsrate, zinsbindung } = this.state.values;

        const interests = this.getInterests();

        const zinsbindungJahre = interests[this.state.values.zinsbindung][0];
        const sollzins = interests[this.state.values.zinsbindung][1];
        const effektivzins = interests[this.state.values.zinsbindung][2];
        const beleihwert = this.props.cf_loan_value;

        const darlehensHoehe = kaufpreis - eigenkapital;
        const monatlicheRate = (darlehensHoehe * (sollzins + tilgungsrate / 100)) / 12;

        // ln[monatliche Rate + (monatliche Rate-(Darlehenssumme-Eigenkapital)*Sollzins/12)]/ln[1+Sollzins/12]
        const laufzeit = Math.log(monatlicheRate / (monatlicheRate - (darlehensHoehe * sollzins) / 12)) / Math.log(1 + sollzins / 12) / 12;

        //Restschuld=(Darlehensbetrag-Eigenkapital)*(1+Sollzins/12)^Zinsbindung-monatliche Rate *[(1+Sollzins/12)^Zinsbindung-1)/(Sollzins/12)]
        const restschuld = Math.max(
            0,
            darlehensHoehe * Math.pow(1 + sollzins / 12, zinsbindungJahre * 12) -
                (monatlicheRate * (Math.pow(1 + sollzins / 12, zinsbindungJahre * 12) - 1)) / (sollzins / 12)
        );

        const ueberBeleihwert = kaufpreis * beleihwert < darlehensHoehe;

        const kaufUeberEigkap = eigenkapital > kaufpreis;

        const anzahlRaten = Math.ceil(laufzeit * 12);

        const gesamtBetrag = laufzeit * 12 * monatlicheRate;

        return {
            kaufpreis,
            eigenkapital,
            tilgungsrate,
            zinsbindungIndex: zinsbindung,
            zinsbindungJahre,
            darlehensHoehe,
            monatlicheRate,
            sollzins: sollzins * 100,
            effektivzins: effektivzins * 100,
            laufzeit,
            restschuld,
            ueberBeleihwert,
            kaufUeberEigkap,
            anzahlRaten,
            gesamtBetrag,
            interests,
        };
    }

    getInterests() {
        let interests = [];
        if (this.props.cf_interest_portion5 && this.props.cf_effective_interest5) {
            interests.push([5, this.props.cf_interest_portion5, this.props.cf_effective_interest5]);
        }
        if (this.props.cf_interest_portion8 && this.props.cf_effective_interest8) {
            interests.push([8, this.props.cf_interest_portion8, this.props.cf_effective_interest8]);
        }
        if (this.props.cf_interest_portion10 && this.props.cf_effective_interest10) {
            interests.push([10, this.props.cf_interest_portion10, this.props.cf_effective_interest10]);
        }
        if (this.props.cf_interest_portion15 && this.props.cf_effective_interest15) {
            interests.push([15, this.props.cf_interest_portion15, this.props.cf_effective_interest15]);
        }
        if (this.props.cf_interest_portion20 && this.props.cf_effective_interest20) {
            interests.push([20, this.props.cf_interest_portion20, this.props.cf_effective_interest20]);
        }
        return interests;
    }

    render() {
        const values = this.calculateValues();

        const CurrentPage = PAGES[this.state.page];

        return (
            <div className="tool-construction-financing" style={{ maxWidth: 600 }}>
                <CurrentPage
                    {...this.props}
                    values={values}
                    setValue={this.setValue.bind(this)}
                    setPage={this.setPage.bind(this)}
                    onContactFormData={this.onContactFormData.bind(this)}
                />
            </div>
        );
    }
}

export class ConstructionFinancingSmall extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                miete: props.cf_rent_start || 200,
            },
        };
    }

    setValue(name, value) {
        let { values } = this.state;
        values[name] = value;
        this.setState({ values });
    }

    calculateValues() {
        const miete = this.state.values.miete;
        const kaufpreis = (miete * 12) / (this.props.cf_interest_portion + this.props.cf_principle_portion);

        return { miete, kaufpreis };
    }

    render() {
        const values = this.calculateValues();

        const minValue = this.props.cf_rent_min || 100;
        const maxValue = this.props.cf_rent_max || 2000;
        const steps = this.props.cf_rent_steps || 50;

        let marks = {};
        marks[minValue] = minValue;
        marks[maxValue] = maxValue;

        const { cf_legal, cf_legal_tooltip } = this.props;

        return (
            <div className="tool-construction-financing" style={{ maxWidth: 600 }}>
                <h2>So viel Haus können Sie sich leisten</h2>
                <div className="row mb-5 ml-1">
                    <div className="col-sm-8 slider-element">
                        <p>Miete</p>
                        <Slider
                            min={minValue}
                            max={maxValue}
                            step={steps}
                            marks={marks}
                            tipFormatter={null}
                            value={values.miete}
                            onChange={value => this.setValue('miete', value)}
                        />
                    </div>
                    <div className="col-sm-4 d-none d-sm-block input-with-suffix">
                        <input
                            type="number"
                            min={minValue}
                            max={maxValue}
                            step={steps}
                            className="form-control"
                            value={values.miete}
                            onChange={e => this.setValue('miete', e.target.value)}
                        />{' '}
                        EUR
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-8">
                        <table className="table table-sm table-result">
                            <tbody>
                                <tr>
                                    <th>Ihre Miete:</th>
                                    <td className="text-right">{numeral(values.miete).format('0,0.00')}</td>
                                    <td>EUR</td>
                                </tr>
                                <tr className="highlight">
                                    <th>
                                        Nettodarlehensbetrag:
                                        <br />
                                        <small>(gemäß gewähltem Beispiel)</small>
                                    </th>
                                    <td className="text-right">{numeral(values.kaufpreis).format('0,0.00')}</td>
                                    <td>EUR</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-sm-4 text-right"></div>
                </div>

                {cf_legal_tooltip && (
                    <span>
                        Rechtliche Hinweise <InfoPopover>{cf_legal}</InfoPopover>
                    </span>
                )}
                {cf_legal_tooltip || <small className="text-muted">{cf_legal || 'Annahmen siehe *'}</small>}
            </div>
        );
    }
}

export class ConstructionFinancingLoan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            miete: props.miete,
            mieteAddon: props.mieteAddon,
            eigenkapital: props.eigenkapital,
            laufzeit: props.laufzeit,
            zins: props.zins,
        };
    }

    calculate(input) {
        const { zins } = this.state;

        const kaufnebenkostenProzent = 12.07;
        const kaufnebenkostenOhneMarklerProzent = 12.07 - 3.57;

        const rate = input.miete + input.mieteAddon;
        const darlehen = (rate * (Math.pow(1 + zins / 12, input.laufzeit * 12) - 1)) / ((zins / 12) * Math.pow(1 + zins / 12, input.laufzeit * 12));

        const gesamtbudget = darlehen + input.eigenkapital;
        const kaufnebenkosten = (gesamtbudget * kaufnebenkostenProzent) / 100;
        const kaufnebenkostenOhneMarkler = (gesamtbudget * kaufnebenkostenOhneMarklerProzent) / 100;
        const kaufpreis = gesamtbudget - kaufnebenkosten;
        const kaufpreisOhneMarkler = gesamtbudget - kaufnebenkostenOhneMarkler;

        return {
            rate,
            darlehen,
            gesamtbudget,
            kaufnebenkosten,
            kaufpreis,
            kaufpreisOhneMarkler,
        };
    }

    /**
     * Set values from outside
     *
     * @param values
     */
    setValues(values) {
        this.setState(values);
    }

    /**
     * Sets a single value (used from this component)
     *
     * @param key
     * @param value
     */
    setValue(key, value) {
        this.setState({ [key]: Math.max(value, 0) }, () => {
            this.props.update(this.getValues());
        });
    }

    /**
     * Fetches all values: those from state and calculated ones
     *
     * @returns {{}}
     */
    getValues() {
        return {
            ...this.state,
            ...this.calculate(this.state),
        };
    }

    render() {
        const values = this.getValues();

        return (
            <div className="tool-construction-financing">
                <div className="row">
                    <div className="col-md-6 p-a-2">
                        <h2>Ihr mögliches Budget</h2>
                        <LabeledInput
                            label="Ihre aktuelle Kaltmiete (pro Monat)"
                            setValue={value => this.setValue('miete', parseInt(value))}
                            value={this.state.miete}
                        />
                        <LabeledInput
                            label="Wieviel können Sie maximal zusätzlich aufbringen?"
                            setValue={value => this.setValue('mieteAddon', parseInt(value))}
                            value={this.state.mieteAddon}
                        />
                        <LabeledInput
                            label="Wieviel Eigenkapital haben Sie schon?"
                            setValue={value => this.setValue('eigenkapital', parseInt(value))}
                            value={this.state.eigenkapital}
                        />

                        <h2>Gewünschte Laufzeit</h2>
                        <LabeledInput
                            label="Wann soll die Immobilie abgezahlt sein?"
                            setValue={value => this.setValue('laufzeit', parseInt(value))}
                            unit="Jahre"
                            value={this.state.laufzeit}
                        />
                    </div>
                    <div className="col-md-6 p-a-2">
                        <h2>Ergebnis/Finanzierung</h2>
                        <ResultTable>
                            <ResultRow label="Mögliche Finanzierung" value={values.darlehen} />
                            <ResultRow label="Vorhandenes Eigenkapital" value={this.state.eigenkapital} />
                            <ResultRow label="Gesamtbudget zur Verfügung" value={values.gesamtbudget} highlight={true} />
                        </ResultTable>
                        <ResultTable>
                            <ResultRow label="Kaufnebenkosten (inkl. Maklercourtage)" value={values.kaufnebenkosten} />
                            <ResultRow label="Kaufpreis / Baukosten (maximal)" value={values.kaufpreis} highlight={true} />
                        </ResultTable>

                        <div className="text-sm-right">
                            <small className="text-muted">*Kaufpreis/Baukosten ohne Maklercourtage: {formatCurrency(values.kaufpreisOhneMarkler)} €</small>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
ConstructionFinancingLoan.defaultProps = {
    miete: 800,
    mieteAddon: 100,
    eigenkapital: 20000,
    laufzeit: 20,
    zins: 0.025,
    update: values => null,
};

export const ConstructionFinancingLoanResult = ({ value }) => {
    if (null === value) {
        return <div />;
    }

    return (
        <div className="tool-construction-financing">
            <ResultTable>
                <ResultRow label="Mögliche Finanzierung" value={value.darlehen} />
                <ResultRow label="Vorhandenes Eigenkapital" value={value.eigenkapital} />
                <ResultRow label="Gesamtbudget zur Verfügung" value={value.gesamtbudget} highlight={true} />
                <ResultRow label="Kaufnebenkosten (inkl. Maklercourtage)" value={value.kaufnebenkosten} />
                <ResultRow label="Kaufpreis / Baukosten (maximal)" value={value.kaufpreis} highlight={true} />
                <ResultRow label="Kaufpreis/Baukosten ohne Maklercourtage" value={value.kaufpreisOhneMarkler} highlight={false} />
            </ResultTable>
        </div>
    );
};

const formatCurrency = currency => numeral(currency).format('0,0.00');

const LabeledInput = ({ label, setValue, unit = '€', ...rest }) => (
    <div className="form-group row">
        <label className="col-sm-7 col-form-label">{label}</label>
        <div className="col-sm-5 input-with-suffix">
            <input type="number" className="form-control" onChange={e => setValue(e.target.value)} {...rest} /> {unit}
        </div>
    </div>
);

const ResultTable = ({ children }) => (
    <table className="table table-sm table-result">
        <tbody>{children}</tbody>
    </table>
);

const ResultRow = ({ label, value, highlight = false }) => (
    <tr className={highlight && 'highlight'}>
        <th>{label}</th>
        <td style={{ width: '10%' }} className="text-right">
            <nobr>{formatCurrency(value)} €</nobr>
        </td>
    </tr>
);
