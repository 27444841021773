import React from 'react';

export default class AccountFinderStarsRating extends React.Component {
    state = {
        starsAmount: this.props.starsAmount,
        starsActive: 0,
    };

    setStarsActive = (amount, index) => {
        const { starsActive } = this.state;
        const { setValue } = this.props;

        if (starsActive === 2 && index === 0) {
            this.setState({ starsActive: 0 });
            setValue(0);
        } else {
            this.setState({ starsActive: amount });
            setValue(amount - 1);
        }
    };

    render() {
        const { starsAmount, starsActive } = this.state;

        let stars = [];
        for (let i = 0; i < starsAmount; i++) {
            stars.push('star');
        }

        return (
            <div className="rating-stars d-flex">
                {stars.map((star, i) => (
                    <div className={`${starsActive > i + 1 ? 'star-active' : ''}`} key={i} onClick={() => this.setStarsActive(i + 2, i)}>
                        <i className="far fa-star fa-2x pointer" />
                    </div>
                ))}
            </div>
        );
    }
}
