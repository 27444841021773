'use strict';

import React, { Component } from 'react';
import classNames from 'classnames';

import StarsRating from '../components/StarsRating';
import BtnPrint from '../components/BtnPrint';

export default class DemandCheck extends Component {
    constructor(props) {
        super(props);

        // initialize all demands with false
        var demands = {};
        props.demands.forEach(group => Object.keys(group.items).forEach(id => (demands[id] = false)));
        this.state = {
            demands,
            openProductId: null,
            allOpen: false,
        };
    }

    toggleDemand(id) {
        let { demands } = this.state;
        demands[id] = !demands[id];

        this.setState({
            demands,
        });
    }

    toggleOpen(productId) {
        this.setState({
            openProductId: this.state.openProductId === productId ? null : productId,
            allOpen: false,
        });
    }

    toggleAllOpen() {
        this.setState({
            allOpen: !this.state.allOpen,
            openProductId: null,
        });
    }

    /**
     * Checks if a passed product is displayed at the moment
     *
     * @param product
     */
    isProductEnabled(product) {
        if (null === product.on) {
            return false;
        }
        const expression = product.on
            // replace all illegal things (prevents injection)
            .replace(/[^a-z0-9_() -]+/gi, '')
            // replace conditionals
            .replace(/\b(AND|UND)\b/gi, '&&')
            .replace(/\b(OR|ODER)\b/gi, '||')
            .replace(/\b(NOT|NICHT)\b/gi, '!')
            // replace demands with replacements from state
            .replace(/(\w+)/gi, "this.state.demands['$1']");

        return eval(expression);
    }

    render() {
        const { demands, products } = this.props;

        const availableProducts = products.filter(product => this.isProductEnabled(product));

        return (
            <div className="container-fluid">
                <div className="row d-print-none">
                    {demands.map(group => {
                        return (
                            <div key={group.name} className={`col-sm-${group.grid} mb-3`}>
                                <h3>{group.name}</h3>
                                <div className="card">
                                    <div className="card-body">
                                        <ul className="centered-list">
                                            {Object.keys(group.items).map(id => {
                                                const demand = group.items[id];
                                                const active = this.state.demands[id];
                                                return (
                                                    <li key={id} className={classNames('text-sm-center', 'demand--item')} onClick={() => this.toggleDemand(id)}>
                                                        <img src={active ? demand.imgHighlight : demand.imgDefault} className="img-fluid" />
                                                        <span>{demand.name}</span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="d-none d-print-block">
                    <div className="row">
                        {demands.map(group => {
                            return (
                                <div key={group.name} className={'col-' + 12 / demands.length}>
                                    <h3>{group.name}</h3>
                                    <ul className="list-unstyled">
                                        {Object.keys(group.items)
                                            .filter(id => this.state.demands[id])
                                            .map(id => {
                                                const demand = group.items[id];
                                                return <li key={id}>{demand.name}</li>;
                                            })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <section className="mt-2">
                    <h2>Unsere Empfehlung für Sie</h2>

                    {0 === availableProducts.length ? (
                        <p>Bitte wählen Sie aus der Liste oben.</p>
                    ) : (
                        <p className="text-muted">(Bitte klicken Sie auf die nachfolgenden Überschriften um weitere Informationen zu erhalten)</p>
                    )}

                    {availableProducts.map(product => {
                        const isOpen = this.state.allOpen || this.state.openProductId === product.id;
                        return (
                            <section className="product" key={product.id} onClick={() => this.toggleOpen(product.id)}>
                                {product.stars > 0 && <StarsRating amount={product.stars} of="3" />}
                                <h3>{product.name}</h3>
                                <div className={classNames({ 'd-none d-print-block': !isOpen })}>
                                    <span dangerouslySetInnerHTML={{ __html: product.description }}></span>
                                    {product.url ? (
                                        <a href={product.url} className="d-print-none" target="_parent">
                                            Weitere Informationen
                                        </a>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </section>
                        );
                    })}

                    {0 !== availableProducts.length && (
                        <div className="text-sm-right">
                            <button className="btn btn-outline-success" onClick={() => this.toggleAllOpen()}>
                                {this.state.allOpen ? 'Alle zuklappen' : 'Alle aufklappen'}
                            </button>{' '}
                            <BtnPrint />
                        </div>
                    )}
                </section>
            </div>
        );
    }
}
