'use strict';

import React, { Component } from 'react';
import * as Analytics from '../analytics';

const DEFAULT_OPTIONS = {
    fields: {},
    disclaimer:
        'Die Speicherung und Weiterverarbeitung Ihrer vorstehend erhobenen personenbezogenen Daten dienen ausschließlich der ordnungsgemäßen Bearbeitung Ihrer Anfrage. Jedwede anderweitige Nutzung ist ausgeschlossen. Nach Beantwortung Ihrer Anfrage werden Ihre Daten gelöscht. Bitte beachten Sie im Übrigen auch unseren Datenschutzhinweis. Zu Ihrer Sicherheit werden Ihre Daten SSL-verschlüsselt übertragen.',
    disclaimerLinks: {},
    submitText: 'Anfrage abschicken',
};

const FIELD_OPTIONS = {
    label: '',
    type: 'text',
    required: false,
};

export default class ContactForm extends Component {
    constructor(props) {
        super(props);

        const { defaultValue, setTrigger, initValues } = this.props;

        if (defaultValue) {
            this.state = { values: defaultValue };
        } else {
            const defaultFields = {};
            this.getOptions().fields.forEach(option => (defaultFields[option.key] = ''));
            this.state = { values: defaultFields };
        }

        setTrigger({}, this.state.values);

        // handler to init values
        initValues(values => this.setState({ values }));
    }

    setValue(key, value) {
        const { setTrigger } = this.props;
        this.state.values[key] = value;

        this.setState(this.state, () => setTrigger({}, this.state.values));
    }

    render() {
        const options = this.getOptions();

        return (
            <form onSubmit={e => this.submit(e)} className="contact-form">
                {options.fields.map(field => (
                    <ContactFormField
                        key={field.key}
                        id={field.key}
                        label={field.label}
                        type={field.type}
                        value={this.state.values[field.key]}
                        setValue={value => this.setValue(field.key, value)}
                        required={field.required}
                    />
                ))}

                <div className="form-group">
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" required="required" /> <span dangerouslySetInnerHTML={{ __html: this.getDisclaimerHtml() }} />
                        </label>
                    </div>
                </div>

                <div className="form-group text-sm-right mt-2">
                    <button type="submit" className="btn btn-success">
                        {options.submitText}
                    </button>
                </div>
            </form>
        );
    }

    getOptions() {
        const { options, refCode } = this.props;
        const mergedOptions = {
            ...DEFAULT_OPTIONS,
            ...options,
            refCode,
        };

        return {
            ...mergedOptions,
            fields: Object.keys(mergedOptions.fields).map(fieldKey => ({
                ...FIELD_OPTIONS,
                ...mergedOptions.fields[fieldKey],
                key: fieldKey,
            })),
        };
    }

    getDisclaimerHtml() {
        const options = this.getOptions();

        let disclaimer = options.disclaimer;

        Object.keys(options.disclaimerLinks).forEach(word => {
            const link = options.disclaimerLinks[word];
            disclaimer = disclaimer.replace(word, `<a href="${link}" target="_blank">${word}</a>`);
        });

        return disclaimer;
    }

    submit(e) {
        e.stopPropagation();
        e.preventDefault();

        Analytics.trackEvent('Component_ContactForm2', 'submit');

        this.props.gotoNextPage();
    }
}

const ContactFormField = ({ id, label, type, required, value, setValue }) => (
    <div className="form-group row">
        <label htmlFor={id} className={`col-sm-4 form-control-label ${required ? 'required' : ''}`}>
            {label}
        </label>
        <div className="col-sm-8">
            {type !== 'textarea' && (
                <input
                    type={type}
                    id={id}
                    className="form-control"
                    required={required ? 'required' : ''}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
            )}
            {type === 'textarea' && (
                <textarea id={id} className="form-control" required={required ? 'required' : ''} onChange={e => setValue(e.target.value)}>
                    {value}
                </textarea>
            )}
        </div>
    </div>
);
