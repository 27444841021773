import React from 'react';
import { FormControl } from '../Helper/FormControl';

export const label = 'HTML';

export const DEFAULT_OPTIONS = {
    html: '',
};

export default ({ data, setValue }) => {
    return (
        <React.Fragment>
            <FormControl value={data.html} textArea={true} setValue={value => setValue(`html`, value)} />
        </React.Fragment>
    );
};
