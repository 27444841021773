import React from 'react';
import { Btn, FormControl, SelectFormSummarySchema, YesNoBtnGroup, filterArray } from '../Helper/FormControl';
import SearchAndSelectIconsOverlay from '../Helper/SearchAndSelectIconsOverlay';

export const label = 'Choice Matrix';

export const DEFAULT_OPTIONS = {
    choices: [],
    values: [],
    multiple: false,
    summary: '',
    label: '',
    iconChecked: 'check-circle',
    iconUnchecked: 'circle',
    infoText: 'Bitte treffen Sie eine Auswahl.',
};

export const CHOICE_OPTIONS = {
    label: '',
    small: '',
    info: '',
    summaryLabel: '',
};

export const VALUE_OPTIONS = {
    label: '',
    icon: '',
    summaryText: '',
    width: 0,
};

export default ({ data, setValue, summarySchemaOptions }) => {
    return (
        <React.Fragment>
            <div className="row align-items-end">
                <div className="col-3">
                    <FormControl label="Label" value={data.label} setValue={value => setValue(`label`, value)} />
                </div>
                <div className="col-6">
                    <FormControl label="Infotext" value={data.infoText} setValue={value => setValue(`infoText`, value)} />
                </div>
                <div className="col-3">
                    <YesNoBtnGroup label="multiple ?" setValue={value => setValue(`multiple`, value)} active={data.multiple} />
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-baseline">
                    <SearchAndSelectIconsOverlay
                        labelTitle="Icon Checked"
                        setValue={setValue}
                        setValuePath={`iconChecked`}
                        currentSelectedIcon={data.iconChecked}
                    />
                    <SearchAndSelectIconsOverlay
                        labelTitle="Icon UnChecked"
                        className="ml-3"
                        setValue={setValue}
                        setValuePath={`iconUnchecked`}
                        currentSelectedIcon={data.iconUnchecked}
                    />
                </div>
            </div>

            <SelectFormSummarySchema options={summarySchemaOptions} onChange={choice => setValue(`summary`, choice.value)} value={data.summary} />

            <hr />

            <h3>Choices</h3>
            {data.choices.map((choice, i) => (
                <div className="row" key={i}>
                    <div className="col">
                        <FormControl label="Label" value={choice.label} setValue={value => setValue(`choices.${i}.label`, value)} />
                    </div>
                    <div className="col">
                        <FormControl label="small" value={choice.small} setValue={value => setValue(`choices.${i}.small`, value)} />
                    </div>
                    <div className="col">
                        <FormControl label="Info" value={choice.info} setValue={value => setValue(`choices.${i}.info`, value)} />
                    </div>
                    <div className="col">
                        <FormControl label="sumLabel" value={choice.summaryLabel} setValue={value => setValue(`choices.${i}.summaryLabel`, value)} />
                    </div>
                    <div className="col-auto">
                        <Btn delBtn={true} onClick={() => filterArray(setValue, `choices`, data.choices, choice, choice.id)} />
                    </div>
                </div>
            ))}
            <Btn label="Choice Hinzufügen" btnText="+" className="btn btn-primary mt-3 mr-3" onClick={() => addChoice(setValue, data, Math.random())} />

            <hr />

            <h3>Values</h3>
            {data.values.map((value, i) => (
                <div className="row" key={i}>
                    <div className="col">
                        <FormControl label="Label" value={value.label} setValue={value => setValue(`values.${i}.label`, value)} />
                    </div>
                    <div className="col">
                        <FormControl label="Icon" value={value.icon} setValue={value => setValue(`values.${i}.icon`, value)} />
                    </div>
                    <div className="col">
                        <FormControl label="Summarytext" value={value.summaryText} setValue={value => setValue(`values.${i}.summaryText`, value)} />
                    </div>
                    <div className="col">
                        <FormControl nmbrInput={true} value={value.width} label="Breite in px" setValue={value => setValue(`values.${i}.width`, value)} />
                    </div>
                    <div className="col">
                        <Btn delBtn={true} onClick={() => filterArray(setValue, `values`, data.values, value, value.id)} />
                    </div>
                </div>
            ))}
            <Btn label="Value Hinzufügen" btnText="+" className="btn btn-primary mt-3" onClick={() => addValue(setValue, data, Math.random())} />
        </React.Fragment>
    );
};
const addChoice = (setValue, data, id) => {
    setValue(`choices`, [
        ...data.choices,
        {
            ...CHOICE_OPTIONS,
            id,
        },
    ]);
};

const addValue = (setValue, data, id) => {
    setValue(`values`, [
        ...data.values,
        {
            ...VALUE_OPTIONS,
            id,
        },
    ]);
};
