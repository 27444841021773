'use strict';

import React from 'react';
import classNames from 'classnames';

import InfoPopover from '../../components/InfoPopover';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import * as Analytics from '../analytics';

const DEFAULT_OPTIONS = {
    values: {},
    summary: '',
    summaryTitle: '',
};

const moveArray = (array, old_index, new_index) => {
    while (old_index < 0) {
        old_index += array.length;
    }
    while (new_index < 0) {
        new_index += array.length;
    }
    if (new_index >= array.length) {
        var k = new_index - array.length;
        while (k-- + 1) {
            array.push(undefined);
        }
    }
    array.splice(new_index, 0, array.splice(old_index, 1)[0]);
};

export default class Sortable extends React.Component {
    constructor(props) {
        super(props);

        const { setTrigger } = this.props;

        // initialize state
        this.state = { sort: Object.keys(this.getOptions().values) };
        this.fireTrigger(this.state.sort);

        // handler to init values
        props.initValues(sort => this.setState({ sort }));
    }

    putItemToPosition(from, to) {
        const { sort } = this.state;
        if (to < 0 || to >= sort.length) {
            return;
        }

        moveArray(sort, from, to);
        this.setState({ sort });

        this.fireTrigger(sort);

        Analytics.trackEvent(
            'Component_Sortable',
            'set_value',
            'value',
            this.getValues()
                .map(value => value.name)
                .join(',')
        );
    }

    fireTrigger(sort) {
        const { setTrigger, getEmptyTrigger } = this.props;

        const trigger = getEmptyTrigger();
        const { summary, summaryTitle } = this.getOptions();

        // create key if it does not exist
        if (typeof trigger.summary[summary] === 'undefined') {
            trigger.summary[summary] = [];
        }
        trigger.summary[summary].push({
            key: summaryTitle,
            name: summaryTitle,
            info: '',
            price: null,
            text: this.getValues()
                .map(value => value.name)
                .join('<br/>'),
        });

        setTrigger(trigger, sort);
    }

    getOptions() {
        return {
            DEFAULT_OPTIONS,
            ...this.props.options,
        };
    }

    getValues() {
        const { values } = this.getOptions();
        const { sort } = this.state;

        return sort.map((key, i) => ({
            ...values[key],
            key,
            position: i,
            positionHuman: i + 1,
            first: i === 0,
            last: i === sort.length - 1,
        }));
    }

    render() {
        return (
            <table className="table table-striped">
                <tbody>
                    {this.getValues().map(value => (
                        <tr key={value.key}>
                            <td style={{ fontSize: '22px', width: '10px' }}>{value.positionHuman}.</td>
                            <td style={{ verticalAlign: 'middle' }}>
                                {value.name} {value.info && <InfoPopover>{value.info}</InfoPopover>}
                            </td>
                            <td style={{ width: '120px' }} className="text-sm-right">
                                <div className="btn-group">
                                    <button
                                        className={classNames('btn btn-outline-secondary', { disabled: value.first })}
                                        onClick={() => this.putItemToPosition(value.position, value.position - 1)}>
                                        <FontAwesomeIcon icon="fa-chevron-up" />
                                    </button>
                                    <button
                                        className={classNames('btn btn-outline-secondary', { disabled: value.last })}
                                        onClick={() => this.putItemToPosition(value.position, value.position + 1)}>
                                        <FontAwesomeIcon icon="fa-chevron-down" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}
