import React from 'react';
import { Btn, FormControl, PriceRow } from './Helper/FormControl';
import { addConfigData } from './Helper/addConfigData';

const EMPTY_VALUE = {
    name: '',
    info: '',
    image: '',
};

export default ({ data, setValue, removeKey }) => {
    return (
        <div>
            {getCardTypes(data.cardTypes).map(cardType => (
                <div key={cardType.key} className="card mb-2">
                    <div className="card-body border-bottom">
                        <div className="row">
                            <div className="col">
                                <h2 className="mb-0">{cardType.name}</h2>
                            </div>
                            <div className="col-auto">
                                <Btn delBtn={true} onClick={() => removeKey(cardType.key)} />
                            </div>
                        </div>
                    </div>

                    <div className="card-body border-bottom">
                        <FormControl label="Name" value={cardType.name} setValue={value => setValue(`${cardType.key}.name`, value)} />
                        <FormControl label="Bild-URL" value={cardType.image} setValue={value => setValue(`${cardType.key}.image`, value)} />
                        <FormControl textArea={true} label="Info" value={cardType.info} setValue={value => setValue(`${cardType.key}.info`, value)} />
                    </div>

                    <PriceRow setValue={setValue} data={data} item={cardType} />
                </div>
            ))}
            <button className="btn btn-outline-primary" onClick={() => addConfigData(setValue, data.cardTypes, 'card', EMPTY_VALUE)}>
                Kartentyp hinzufügen
            </button>
        </div>
    );
};

const getCardTypes = cardTypes =>
    Object.keys(cardTypes).map(key => ({
        key,
        ...cardTypes[key],
    }));
