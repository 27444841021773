'use strict';

import $ from 'jquery';
import React, { Component } from 'react';

import DemandCheckTool from '../DemandCheck/index';

export default class DemandCheck extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    loadData(props) {
        this.setState({ loading: true });
        $.getJSON(`/tools/${props.match.params.bankSlug}/demand-check/${props.match.params.demandCheckSlug}/configuration/`, data => {
            this.setState({ data, loading: false });
        });
    }

    componentDidMount() {
        this.loadData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.demandCheckSlug !== this.props.match.params.demandCheckSlug) {
            this.loadData(nextProps);
        }
    }

    render() {
        if (this.state.loading) {
            return <div />;
        }

        return (
            <div className="container mt-2">
                <DemandCheckTool {...this.state.data} />
            </div>
        );
    }
}
