'use strict';

import React, {Component} from 'react';
import moment from 'moment';
import FontAwesomeIcon from '../components/FontAwesomeIcon';

const notify = text => alert(text);

const MODE_CLOSED = 'closed';
const MODE_LOAD = 'load';
const MODE_SAVE = 'save';

export default class ConfigCode extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            code       : '',
            loadedCode : '',
            valid      : false,
            mode       : MODE_CLOSED
        };
    }

    close() {
        this.setState({
            mode: MODE_CLOSED
        });
    }

    /**
     * Saves a config to the database
     */
    openConfig() {
        this.setState({
            mode: MODE_LOAD
        });
    }

    /**
     * Saves a config to the database
     */
    async saveConfig() {
        const { saveConfigCode } = this.props;

        const data = await saveConfigCode();
        this.setState({
            code       : data.code,
            loadedCode : data.code,
            valid      : true,
            mode       : MODE_SAVE,
            error      : ''
        });
    }

    /**
     * Loads a config through its code from the database
     *
     * @param code
     */
    async loadConfig(code) {
        const { loadConfigCode, options } = this.props;

        const data = await loadConfigCode(code);

        if (data.success) {
            this.setState({
                code,
                loadedCode : code,
                valid      : true,
                mode       : MODE_CLOSED
            });

            setTimeout(() => notify(`Ihr ${options.name} vom ${moment(data.date, 'X').format('DD.MM.YYYY HH:mm [Uhr]')} wurde geladen.`), 50);
        } else {
            this.setState({
                code       : '',
                loadedCode : '',
                valid      : false,
                error      : `Ihr ${options.name} ist leider ungültig.`
            });
        }
    }

    render() {
        const { mode } = this.state;
        const { options } = this.props;
        return (
            <div>
                <div className="float-sm-right">
                    {options.withLoad && <button className="btn btn-outline-secondary" onClick={() => this.openConfig()}>{options.labelLoad}</button>}
                    {options.withSave && <button className="btn btn-outline-secondary" onClick={() => this.saveConfig()}>{options.labelSave}</button>}
                </div>
                <div className={`overlay ${MODE_CLOSED !== mode ? 'active' : ''}`} onClick={this.close.bind(this)}></div>
                    <div className={`config-code off-canvas-flyin ${MODE_CLOSED !== mode ? 'active' : ''}`}>
                        <a className="window-close" onClick={this.close.bind(this)}><FontAwesomeIcon icon="fa-times"/></a>
                        {MODE_SAVE === mode &&
                            <div className="config-code-text">
                                <h2>Ihr persönlicher {options.name}</h2>
                                <span className="code">
                                    {this.state.code}
                                </span>
                                <div dangerouslySetInnerHTML={{__html: options.saveTextHtml}} />

                                {/*
                                <h3>Code per E-Mail versenden</h3>
                                <div className="row">
                                    <div className="col-5">
                                        <input type="email" className="form-control" placeholder="E-Mail"/>
                                    </div>
                                    <div className="col-5">
                                        <input type="email" className="form-control" placeholder="Ihr Name"/>
                                    </div>
                                    <div className="col-2">
                                        <button className="btn btn-outline-secondary btn-block">Senden</button>
                                    </div>
                                </div>
                                */}
                            </div>}
                        {MODE_LOAD === mode &&
                            <div>
                                <h2>Ihren {options.name} öffnen</h2>
                                <p>Geben Sie hier Ihren {options.name} ein um Ihre gespeicherte Beratung zu laden.</p>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <input type="email" ref={e => this.code = e} className="form-control" placeholder={options.name} defaultValue={this.state.code}/>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-outline-secondary btn-block" onClick={() => this.loadConfig(this.code.value)}>Laden</button>
                                    </div>
                                </div>
                                {this.state.error && <span className="color-danger">{this.state.error}</span>}
                            </div>}
                    </div>
            </div>
        );
    }
}
