'use strict';

export const FORMAT_ANNUAL = 'annual';
export const FORMAT_MONTHLY = 'monthly';

/**
 * Price abstraction
 *
 * can handle:
 *
 * {
 *      baseFee     : {'acc_0' => 5.99, 'acc_1' => 9.99},
 *      cost        : {'acc_0' => 0.69, 'acc_2' => 0.43},
 *      included    : {'acc_0' => 0, 'acc_2' => 2},
 *      priceFormat : 'annual',
 * }
 */
export class PriceCalculator {
    static create(accountKeys, data = {}) {
        return new PriceCalculator(accountKeys, data.baseFee, data.cost, data.included, data.priceFormat);
    }

    /**
     *
     * @param accountKeys
     * @param baseFee
     * @param cost
     * @param included
     * @param priceFormat
     */
    constructor(accountKeys = [], baseFee = 0, cost = 0, included = 0, priceFormat = FORMAT_MONTHLY) {
        this.accountKeys = accountKeys;

        this.baseFee = {};
        if (typeof baseFee == 'object') {
            this.baseFee = baseFee;
        } else {
            accountKeys.forEach(accountKey => (this.baseFee[accountKey] = baseFee || 0));
        }

        this.cost = {};
        if (typeof cost == 'object') {
            this.cost = cost;
        } else {
            accountKeys.forEach(accountKey => (this.cost[accountKey] = cost || 0));
        }

        this.included = {};
        if (typeof included == 'object') {
            this.included = included;
        } else {
            accountKeys.forEach(accountKey => (this.included[accountKey] = included || 0));
        }

        this.priceFormat = priceFormat;
    }

    getBaseFee(accountKey = null) {
        return accountKey === null ? this.baseFee : this.baseFee[accountKey];
    }

    getCost(accountKey = null) {
        return accountKey === null ? this.cost : this.cost[accountKey];
    }

    getIncluded(accountKey = null) {
        return accountKey === null ? this.included : this.included[accountKey];
    }

    getPriceFormat(accountKey = null) {
        return accountKey === null ? this.priceFormat : this.priceFormat[accountKey];
    }

    /**
     * @param quantity
     * @returns {AccountPrice}
     */
    getPrice(quantity = 1) {
        let prices = {};

        // transform simple values to accountspecific quantities
        // so "1" will become { 'acc_0': 1, 'acc_1': 1, 'acc_2 : 1 }
        if (typeof quantity !== 'object') {
            const newQuantity = quantity;
            quantity = {};
            this.accountKeys.forEach(accountKey => (quantity[accountKey] = newQuantity));
        }

        // calculation code
        this.accountKeys.forEach(accountKey => {
            if (this.included[accountKey] < quantity[accountKey]) {
                prices[accountKey] = this.baseFee[accountKey] + this.cost[accountKey] * Math.max(0, quantity[accountKey] - this.included[accountKey]);
            } else {
                prices[accountKey] = 0;
            }
        });

        return new AccountPrice(prices, this.priceFormat);
    }
}

export class AccountPrice {
    /**
     * Creates an empty pricing
     *
     * @param accountKeys
     * @param priceFormat
     * @returns {AccountPrice}
     */
    static createEmpty(accountKeys, priceFormat = FORMAT_MONTHLY) {
        let prices = {};
        accountKeys.forEach(accountKey => (prices[accountKey] = 0));

        return new AccountPrice(prices, priceFormat);
    }

    /**
     * @param prices
     * @param priceFormat
     */
    constructor(prices, priceFormat = FORMAT_MONTHLY) {
        this.prices = prices;
        this.priceFormat = priceFormat;
    }

    /**
     * Adds a price object to this actual one
     *
     * @param price
     */
    addPrice(price) {
        if (null === price) {
            return;
        }
        Object.keys(this.prices).forEach(accountKey => {
            this.prices[accountKey] += price.getPrice(accountKey, this.priceFormat);
        });
    }

    transform(price, priceFormatFrom, priceFormatTo) {
        if (priceFormatFrom === priceFormatTo) {
            return price;
        }

        // from annual to monthly
        if (FORMAT_ANNUAL === priceFormatFrom && FORMAT_MONTHLY === priceFormatTo) {
            return price / 12;
        }

        // from monthly to annual
        if (FORMAT_MONTHLY === priceFormatFrom && FORMAT_ANNUAL === priceFormatTo) {
            return price * 12;
        }

        throw 'Not possible to transform price';
    }

    /**
     * Gets the price for the given account key
     *
     *
     * @param accountKey
     * @param priceFormat
     * @returns {*}
     */
    getPrice(accountKey, priceFormat = FORMAT_MONTHLY) {
        return this.transform(this.prices[accountKey], this.priceFormat, priceFormat);
    }

    /**
     * Checks if there is at least one value neq 0
     */
    isNotNull() {
        return Object.keys(this.prices)
            .map(key => this.prices[key])
            .reduce((last, current) => last || current > 0, false);
    }
}

/**
 * Creates an empty price
 *
 * @param accounts
 * @param withBaseFee
 * @returns {Price}
 */
export const getEmptyPrice = (accounts, withBaseFee = false) => {
    let accountPrices = AccountPrice.createEmpty(Object.keys(accounts));

    if (withBaseFee) {
        // determine prices
        var basePrice = {};
        Object.keys(accounts).forEach(accountId => {
            // set prices to base fees (grundgebühren)
            basePrice[accountId] = accounts[accountId].price.baseFee || 0;
        });
        accountPrices.addPrice(new AccountPrice(basePrice, 'monthly'));
    }

    return accountPrices;
};

/**
 *
 * @param accounts
 * @param options
 * @returns {PriceCalculator}
 */
export const getPriceCalculator = (accounts, options = {}) => PriceCalculator.create(Object.keys(accounts), options);

/**
 * Retrieves the prices for all accounts and the index of the cheapest account
 *
 * @returns {{accountPrices: {}, cheapest: *}}
 */
export const getPrices = (accounts, triggers) => {
    // when there are no accounts, then of course there will be no prices
    if (0 === Object.keys(accounts).length) {
        return null;
    }

    let accountPrices = getEmptyPrice(accounts, true);
    triggers.forEach(trigger => accountPrices.addPrice(trigger.price));

    // check if trigger has set at least one active key
    const forcedKeys = triggers.filter(trigger => trigger.forceAccount !== null).map(trigger => trigger.forceAccount);
    // check if trigger has set at least one active key
    let excludeKeys = [];
    triggers
        .filter(trigger => trigger.excludeAccount !== null)
        .forEach(trigger => {
            const { excludeAccount } = trigger;
            if ('string' === typeof excludeAccount) {
                // seems to be string
                excludeKeys.push(excludeAccount);
            } else {
                // seems to be array
                excludeKeys = excludeKeys.concat(excludeAccount);
            }
        });

    // determine the correct account to display. if no account is forced, it will be the cheapest one
    let activeKey = null;
    if (0 === forcedKeys.length) {
        // determine index of cheapest price, but skip hidden
        Object.keys(accounts)
            .filter(accountId => {
                // when no accounts are excluded, we will skip hidden by default and take only care of hidden
                // hidden accounts won't be taken into account
                if (0 === excludeKeys.length) {
                    return !accounts[accountId].hidden;
                }

                // ...otherwise the excluded keys will define if an acccount is used for price calculation or not
                return excludeKeys.indexOf(accountId) === -1;
            })
            .forEach(accountId => {
                if (null === activeKey) {
                    activeKey = accountId;
                    return;
                }
                // set activeKey when we found a cheaper ac
                if (accountPrices.getPrice(accountId) < accountPrices.getPrice(activeKey)) {
                    activeKey = accountId;
                }
            });
    } else {
        // get last forced key
        activeKey = forcedKeys[forcedKeys.length - 1];
    }
    return {
        accountPrices,
        activeKey,
        activePrice: accountPrices.getPrice(activeKey),
        activeName: accounts[activeKey].name,
    };
};
