'use strict';

import React from 'react';

import { PriceCalculator, FORMAT_MONTHLY as PRICE_FORMAT_MONTHLY, FORMAT_ANNUAL as PRICE_FORMAT_ANNUAL } from '../Model/Price';
import { getSummary } from './_index';

import Account from './Account';
import AccountPriceView from '../AccountPriceView';
import InfoPopover from '../../components/InfoPopover';
import DisplayPrice, { formatPrice } from '../../components/Price';
import * as Analytics from '../analytics';

import ItemRow from './Summary/ItemRow';
import debounce from 'lodash/debounce';
import ChoiceInput from './Summary/ChoiceInput';
import DetailsInput from './Summary/DetailsInput';

const DEFAULT_OPTIONS = {
    schema: {},
    accountsGrid: 2,
    accountsAlignment: 'center',
    displayAccountPreview: true,
    highlightActiveAccount: true,
    displayTotalSum: false,
    displayBaseFee: true,
    baseFeeName: 'Grundgebühr',
    ratingGrid: 0, // is deprecated. use "sidebarGrid"
    sidebarGrid: 0,
    displayPrintSummary: false,
    displayAccountPreviewText: null,
    withRemark: false,
    remarkLabel: 'Haben Sie noch weitere Hinweise?',
};

const DEFAULT_OPTIONS_INNER = {
    name: 'Können wir etwas besser machen?',
    summation: false,
    priceFormat: 'monthly',
    includeBaseFee: false,
    sidebarType: 'ratings', // or: 'details' for textarea input
    ratings: [], // choices used in ratings
    starsAmount: 3,
    // define own component for rendering info page {@see getSummary()}
    type: null,
};

const colCssClass = gridSize => `col-${gridSize}`;

export default class Summary extends React.Component {
    constructor(props) {
        super(props);

        const { setTrigger } = this.props;

        // initialize state
        this.state = { value: {} };
        setTrigger({}, this.state.value);

        props.initValues(value => Object.keys(value).forEach(key => this.setValue(key, value[key])));
    }

    getStateKey(categoryKey, itemKey) {
        return `${categoryKey}__${itemKey}`;
    }

    setValue(key, value) {
        const { setTrigger, getEmptyTrigger } = this.props;

        // set the trigger based upon the selected items
        let trigger = getEmptyTrigger();

        this.state.value[key] = value;

        this.setState(this.state);
        setTrigger(trigger, this.state.value);

        Analytics.trackEvent('Component_Summary', 'set_value', key, value);
    }

    getValue(key) {
        return this.state.value[key] || '';
    }

    getOptions() {
        return {
            ...DEFAULT_OPTIONS,
            ...this.props.options,
            // deprecated handling. we rely on sidebarGrid when available
            sidebarGrid: this.props.options.sidebarGrid || this.props.options.ratingGrid || DEFAULT_OPTIONS.sidebarGrid,
        };
    }

    getSummaryTriggersForCategory(category) {
        const { triggers } = this.props;
        let response = [];
        triggers.forEach(trigger => {
            response = [...response, ...(trigger.summary[category] || [])];
        });
        return response;
    }

    getAccounts() {
        const { options, data, prices, values } = this.props;

        let accounts = null;
        if (typeof options.accounts === 'function') {
            accounts = options.accounts(values, prices);
        } else {
            accounts = Object.keys(data.accounts).filter(key => !data.accounts[key].hidden);
        }

        // enable sorting of account finders
        accounts = accounts.sort((account1, account2) => (data.accounts[account1].position || 0) - (data.accounts[account2].position || 0));

        return accounts.map(key => {
            const activeKey = typeof prices.activeKey === 'string' ? prices.activeKey : prices.activeKey[0];
            return {
                ...data.accounts[key],
                key,
                active: key === activeKey,
            };
        });
    }

    /**
     * @returns {*}
     */
    getCategories() {
        const { isPageDisplayed, getEmptyPrice, values } = this.props;
        const { schema } = this.getOptions();

        return (
            Object.keys(schema)
                .map(schemaKey => {
                    // fetch all items from summary triggers
                    const items = this.getSummaryTriggersForCategory(schemaKey);

                    let priceSummary = getEmptyPrice(schema[schemaKey].includeBaseFee);
                    items.forEach(item => priceSummary.addPrice(item.price));

                    // merge default props and extract type. type defines own react component to render summary overview
                    const { type, sidebarType, ...rest } = {
                        ...DEFAULT_OPTIONS_INNER,
                        ...schema[schemaKey],
                    };

                    return {
                        // key is unique pagekey
                        key: schemaKey,
                        // schema @see DEFAULT_OPTIONS_INNER
                        schema: rest,
                        // append value for the page (this will be passed to react component
                        value: values[schemaKey],
                        // the type displayed on the summary overview (for rendering own react component)
                        type,
                        // the sidebar type
                        sidebarType,
                        // all the items coming from triggers
                        items,
                        // and if a price needs to be calculated
                        priceSummary,
                    };
                })
                // hide when there are no items OR when type/value is unset
                .filter(category => category.items.length > 0 || (category.type !== null && category.value !== null && isPageDisplayed(category.key)))
        );
    }

    updateStyle() {
        if (false === this.getOptions().highlightActiveAccount) {
            return;
        }

        const { prices } = this.props;
        if (null === prices) {
            return;
        }

        let sickStart = this.refs['sick_start_' + prices.activeKey] && this.refs['sick_start_' + prices.activeKey].getBoundingClientRect();
        let sickEnd = this.refs['sick_end_' + prices.activeKey] && this.refs['sick_end_' + prices.activeKey].getBoundingClientRect();
        if (!sickStart) {
            sickStart = { top: 0, left: 0, height: 0, width: 0 };
            sickEnd = { top: 0, left: 0, height: 0, width: 0 };
        }

        let highlightStyle = {
            backgroundColor: '#ddd',
            position: 'fixed',
            top: sickStart.top - 10,
            left: sickStart.left,
            width: sickStart.width,
            height: sickEnd.top - sickStart.top + 20,
            zIndex: -1,
        };

        this.setState({ highlightStyle });
    }
    //
    componentWillMount() {
        this.setState({ highlightStyle: {} });
        setInterval(() => this.updateStyle(), 100);
    }

    componentDidMount() {
        window.addEventListener('resize', () => this.updateStyle());
    }

    render() {
        const { prices, printSummary } = this.props;
        const options = this.getOptions();
        const accounts = this.getAccounts();

        const gridSizeLeft = 12 - options.sidebarGrid - Math.max(accounts.length, 1) * options.accountsGrid;
        const gridSizeAccount = options.accountsGrid;
        const gridSizeSidebar = options.sidebarGrid;
        const classAccountAlignment = 'text-' + options.accountsAlignment;

        const debouncedRemark = debounce(value => this.setValue('remark', value), 500);

        return (
            <div className="type-summary">
                <div style={this.state.highlightStyle} className="d-print-none" />

                {/* display only when accounts are available */}
                {accounts.length > 0 && options.displayAccountPreview && (
                    <div className="row">
                        <div className={colCssClass(gridSizeLeft)} />
                        {accounts.map(account => {
                            return (
                                <div ref={'sick_start_' + account.key} className={`${colCssClass(gridSizeAccount)} text-center`} key={account.key}>
                                    <Account account={account} disabled={!account.active} summary={true} />
                                    {account.url && (
                                        <a href={account.url} className="btn btn-outline-success mt-2" target="_top">
                                            {account.urlText || 'Jetzt bestellen'}
                                        </a>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
                {/* Text for the displayAccountPreview with selected Account */}
                {options.displayAccountPreviewText && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: options.displayAccountPreviewText
                                .replace(/%account%/g, prices.activeName)
                                .replace(/%activePrice%/, formatPrice(prices.activePrice)),
                        }}></div>
                )}

                {/* display only when accounts are available */}
                {accounts.length > 0 && options.displayBaseFee && (
                    <div>
                        <hr />
                        <div className="row">
                            <div className={colCssClass(gridSizeLeft)}>{options.baseFeeName}</div>
                            {accounts.map(account => (
                                <div className={`${colCssClass(gridSizeAccount)} ${classAccountAlignment}`} key={account.key}>
                                    € <DisplayPrice price={account.price.baseFee} /> p. M.
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* loop all categories */}
                {this.getCategories().map((category, i) => {
                    if (null !== category.type) {
                        return (
                            <SchemaCategory key={category.key} category={category}>
                                {getSummary(category.type, category.value)}
                            </SchemaCategory>
                        );
                    }

                    return (
                        <SchemaCategory key={category.key} category={category}>
                            {category.items.map(item => (
                                <ItemRow
                                    key={`${item.key}_${item.name}`}
                                    item={item}
                                    accounts={this.getAccounts()}
                                    gridSizeLeft={gridSizeLeft}
                                    gridSizeAccount={gridSizeAccount}
                                    gridSizeSidebar={gridSizeSidebar}
                                    sidebarType={category.sidebarType}
                                    classAccountAlignment={classAccountAlignment}
                                    priceFormat={category.schema.priceFormat}
                                    ratings={category.schema.ratings}
                                    starsAmount={category.schema.starsAmount}
                                    htmlify={html => this.props.htmlify(html)}
                                    setValue={(name, value) => this.setValue(this.getStateKey(category.key, name), value)}
                                    value={this.getValue(this.getStateKey(category.key, item.key))}
                                />
                            ))}
                            {accounts.length > 0 && category.schema.summation && (
                                <div className="row row--summary">
                                    <div className={colCssClass(gridSizeLeft)}>
                                        {category.schema.summationText || 'Summe'} <InfoPopover>{category.schema.summationInfo}</InfoPopover>
                                    </div>
                                    {accounts.map(account => (
                                        <div key={account.key} className={`${colCssClass(gridSizeAccount)} ${classAccountAlignment}`}>
                                            €{' '}
                                            <AccountPriceView
                                                accountPrice={category.priceSummary}
                                                accountKey={account.key}
                                                format={category.schema.priceFormat}
                                                suffix={true}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </SchemaCategory>
                    );
                })}

                {options.displayTotalSum && (
                    <div>
                        <hr />
                        <div className="row price">
                            <div className={colCssClass(gridSizeLeft)}>{options.totalSumText}</div>
                            {this.getAccounts().map(account => (
                                <div key={account.key} className={`${colCssClass(gridSizeAccount)} ${classAccountAlignment}`}>
                                    €{' '}
                                    <AccountPriceView
                                        accountPrice={prices.accountPrices}
                                        accountKey={account.key}
                                        format={PRICE_FORMAT_MONTHLY}
                                        suffix={true}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {/* Display the Text from the printSummary with the total Sum from the active Account */}
                {options.displayPrintSummary && (
                    <div className="d-print-none">
                        <hr />
                        <div>
                            {printSummary}
                            <strong>
                                {' '}
                                €{' '}
                                <AccountPriceView
                                    accountPrice={prices.accountPrices}
                                    accountKey={prices.activeKey}
                                    format={PRICE_FORMAT_MONTHLY}
                                    suffix={true}
                                />
                            </strong>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className={colCssClass(gridSizeLeft)} />
                    {this.getAccounts().map(account => (
                        <div key={account.key} className={colCssClass(gridSizeAccount)} ref={'sick_end_' + account.key} />
                    ))}
                </div>

                {options.withRemark && (
                    <div>
                        |
                        <hr />
                        <div className="d-print-none">
                            <div className="form-group">
                                <label>{options.remarkLabel}</label>
                                <textarea className="form-control" onChange={e => debouncedRemark(e.target.value)}>
                                    {this.getValue('remark')}
                                </textarea>
                            </div>
                        </div>
                        <div className="d-none d-print-block">
                            <label>{options.remarkLabel}</label>
                            <p>{this.getValue('remark')}</p>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const SchemaCategory = ({ category, children }) => (
    <section>
        <hr />
        <div className="headline">{category.schema.name}</div>
        {children}
    </section>
);
