export const notifyResult = async ({ bankSlug, accountFinderId, html, contact, files = [], subject = '', values, mailBody }) => {
    const formData = new FormData();

    formData.append('html', html);
    formData.append('contact', contact);
    formData.append('subject', subject);
    formData.append('values', JSON.stringify(values));
    formData.append('mailBody', mailBody);

    // append all files
    files.forEach(file => formData.append(file.name, file.file));
    formData.append('appendedFiles', files.map(file => file.name).join(','));

    return await fetch(`/tools/${bankSlug}/account-finder/${accountFinderId}/notify/`, {
        method: 'POST',
        body: formData,
    });
};

export const pdfFromHtml = ({ html, bankSlug = '' }) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', `/tools/html-to-pdf/${bankSlug}`);
    form.style.cssText = 'display:none';

    // setting form target to a window named 'formresult'
    form.setAttribute('target', 'formresult');

    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('name', 'html');
    hiddenField.setAttribute('value', html);
    form.appendChild(hiddenField);

    document.body.appendChild(form);

    window.open('test.html', 'formresult', '');

    form.submit();
};
