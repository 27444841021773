import React from 'react';

const TabList = ({ tabs = [], activeTab = '', setActiveTab = () => null, removeKey = null }) => {
    if (0 === tabs.length) {
        return null;
    }

    const getTabs = () =>
        tabs.map(tab => ({
            ...tab,
            setActive: () => setActiveTab(tab.value),
            active: activeTab === tab.value,
        }));

    return (
        <div className="nav flex-column nav-pills">
            {getTabs().map(tab => (
                <a
                    key={tab.value}
                    onClick={() => tab.setActive()}
                    className={`nav-link d-flex justify-content-between align-items-center  ${tab.active ? `active` : ``}`}>
                    <span>{tab.label}</span>
                    {tab.value && removeKey !== null && (
                        <span className="delete-page" onClick={() => removeKey(tab.value)}>
                            x
                        </span>
                    )}
                </a>
            ))}
        </div>
    );
};

export default TabList;
