'use strict';

import React from 'react';
import classNames from 'classnames';
import FontAwesomeIcon from '../components/FontAwesomeIcon';

import { NavLink } from 'react-router-dom';

export default class BoundaryFrame extends React.Component {
    getLogo() {
        const { logo, url } = this.props;

        const image = <img src={logo||'/images/genolotse-logo.png'} style={{maxHeight:70}} className="my-2"/>;

        if (!url) {
            return image;
        }

        return <a href={url} target="_blank">{image}</a>;
    }


    render() {
        const { level1, level2, level3, footerLinks, socialMedia, children } = this.props;

        return (
            <div>
                <div id="above-footer">
                    <header>
                        <div className="container">
                            {this.getLogo()}
                        </div>
                        <nav className="navbar navbar-expand-lg navbar-primary navbar-light d-print-none">
                            <div className="container">
                                <ul className="navbar-nav">
                                    {level1.map((link, i) => <li key={i} className={classNames('nav-item', { active: link.active })}><a className="nav-link" href={link.href} target={link.target || '_self'}>{link.name}</a></li>)}
                                </ul>
                            </div>
                        </nav>

                        <nav className="navbar navbar-expand-lg navbar-expand navbar-secondary navbar-light d-print-none">
                            <div className="container">
                                <ul className="navbar-nav">
                                    {level2.map((link, i) => <li key={i} className="nav-item"><NavLink to={link.url} className="nav-link" activeClassName="active">{link.name}</NavLink></li>)}
                                </ul>
                            </div>
                        </nav>
                    </header>

                    {children}
                </div>

                <footer className="d-print-none">
                    {level3.length > 0 &&
                        <nav className="navbar navbar-expand-lg navbar-secondary navbar-light">
                            <div className="container">
                                <ul className="navbar-nav">
                                    {level3.map((link, i) => <li key={i} className="nav-item"><a className="nav-link" href={link.href} target={link.target || '_self'}>{link.text}</a></li>)}
                                </ul>
                            </div>
                        </nav>}
                    <div className="container">
                        <div className="footer__inner">
                            <div className="row">
                                {footerLinks.map(group => (
                                    <div key={group.label} className="col-sm-3">
                                        <h3>{group.label}</h3>
                                        <ul className="list-unstyled">
                                            {group.links.map((link, i) => <li key={i}><FontAwesomeIcon icon={link.icon} fw={true}/> <a href={link.href} target={link.target||'_self'}>{link.text}</a></li>)}
                                        </ul>
                                    </div>
                                ))}
                                {socialMedia.length > 0 &&
                                    <div className="col-sm-3">
                                        <h3>Social Media</h3>

                                        <ul className="list-inline">
                                            <li className="list-inline-item">
                                                {socialMedia.map((link, i) => <a key={i} href={link.href} target={link.target||'_self'}><FontAwesomeIcon icon={link.icon} size="2x" fw={true}/></a>)}
                                            </li>
                                        </ul>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}
