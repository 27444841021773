import React from 'react';
import { FormControl, SelectForm, getChoices, SelectFormSummarySchema, Btn, SelectFormPriceFormat, filterArray, YesNoBtnGroup } from '../Helper/FormControl';
import TypedNumberInput from '../../../components/TypedNumberInput';

export const label = 'CreditCards';

export const DEFAULT_OPTIONS = {
    includedGroups: [],
    on: [],
    use: null,
    summary: '',
    max: null,
};

export const EMPTY_INCLUDED_GROUP = {
    name: '',
    on: null,
    priceFormat: 'monthly',
    cost: {},
    costX: {},
};

export default ({ data, setValue, choices, summarySchemaOptions }) => {
    if (data.includedGroups === undefined) {
        setValue(`includedGroups`, []);
        return <React.Fragment />;
        //@todo hack ????!!!
    } else {
        return (
            <React.Fragment>
                <div className="row align-items-baseline">
                    <div className="col-10">
                        <SelectForm
                            multi={true}
                            label="Auswahl der angezeigten Kreditkarten:"
                            options={getChoices(choices.creditCardTypes)}
                            onChange={choices => setValue(`use`, choices.map(choice => choice.value))}
                            clearable={true}
                            value={data.use}
                        />
                    </div>
                    <div className="col-2">
                        <FormControl value={data.max} nmbrInput={true} label="maximale Anzahl an Karten:" setValue={value => setValue(`max`, value)} />
                    </div>
                </div>
                <SelectFormSummarySchema options={summarySchemaOptions} onChange={choice => setValue(`summary`, choice.value)} value={data.summary} />
                {data.includedGroups &&
                    data.includedGroups.map((includedGroup, includedGroupIndex) => {
                        return (
                            <div key={includedGroupIndex} className="card card-body mb-3">
                                <div className="d-flex justify-content-between">
                                    <h2>{includedGroup.name}</h2>
                                    <Btn
                                        delBtn={true}
                                        onClick={() => filterArray(setValue, `includedGroups`, data.includedGroups, includedGroup, includedGroup.id)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <FormControl
                                            label="Name der Gruppe:"
                                            value={includedGroup.name}
                                            setValue={value => setValue(`includedGroups.${includedGroupIndex}.name`, value)}
                                        />
                                    </div>
                                    <div className="col-8">
                                        <SelectForm
                                            multi={true}
                                            label="On:"
                                            options={getChoices(choices.creditCardTypes)}
                                            onChange={choices => setValue(`includedGroups.${includedGroupIndex}.on`, choices.map(choice => choice.value))}
                                            clearable={true}
                                            value={includedGroup.on}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    {getChoices(choices.accounts).map(account => (
                                        <div key={account.value} className="col-2 mb-3 d-flex flex-column justify-content-end">
                                            <TypedNumberInput
                                                label={`includiert in ${account.label} ?`}
                                                value={includedGroup.included[account.value]}
                                                setValue={value => setValue(`includedGroups.${includedGroupIndex}.included.${account.value}`, value)}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="d-flex flex-column">
                                    <h3>Preise:</h3>
                                    <div className="row">
                                        <div className="col-12">
                                            <SelectForm
                                                multi={true}
                                                label="Cost X on :"
                                                options={getChoices(choices.creditCardTypes)}
                                                onChange={choices => setCostxCreditCardType(setValue, data, choices, includedGroupIndex)}
                                                clearable={true}
                                                value={Object.keys(includedGroup.costX).map(key => key)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        {getChoices(choices.accounts).map(accKey => (
                                            <React.Fragment key={accKey.value}>
                                                <div className="col-2 d-flex justify-content-end flex-column">
                                                    <TypedNumberInput
                                                        key={accKey.value}
                                                        label={`Kosten von ${accKey.label}`}
                                                        value={includedGroup.cost[accKey.value]}
                                                        setValue={value =>
                                                            setValue(`includedGroups.${includedGroupIndex}.cost`, {
                                                                ...includedGroup.cost,
                                                                [accKey.value]: value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                {getChoices(choices.creditCardTypes).map((ccType, ccTypeIndex) => (
                                                    <div key={ccType.value} className={`col-2 d-flex flex-column justify-content-end order-${ccTypeIndex + 1}`}>
                                                        {Object.keys(includedGroup.costX).includes(ccType.value) && (
                                                            <TypedNumberInput
                                                                key={accKey.value}
                                                                label={`Cost X of ${ccType.label} on ${accKey.label}`}
                                                                value={includedGroup.costX[ccType.value][accKey.value]}
                                                                setValue={value =>
                                                                    setAccountCostXfromCreditCardType(
                                                                        setValue,
                                                                        ccType,
                                                                        includedGroup,
                                                                        accKey,
                                                                        value,
                                                                        includedGroupIndex
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                <SelectFormPriceFormat
                                    label="Preis (Format)"
                                    onChange={choices => setValue(`includedGroups.${includedGroupIndex}.priceFormat`, choices.value)}
                                    options="default"
                                    value={includedGroup.priceFormat}
                                />
                            </div>
                        );
                    })}
                <Btn
                    label="Includedgroup Hinzufügen"
                    btnText="+"
                    className="btn btn-primary"
                    onClick={() => addIncludedGroup(setValue, data, choices, Math.random())}
                />
            </React.Fragment>
        );
    }
};
const addIncludedGroup = (setValue, data, choices, id) => {
    let included = {};
    getChoices(choices.accounts).forEach(account => {
        included = {
            ...included,
            [account.value]: 0,
        };
    });
    setValue(`includedGroups`, [
        ...data.includedGroups,
        {
            ...EMPTY_INCLUDED_GROUP,
            included,
            id,
        },
    ]);
};

const setCostxCreditCardType = (setValue, data, choices, includedGroupIndex) => {
    let creditCardTypes = {};
    choices.forEach(choice => {
        creditCardTypes = {
            ...data.includedGroups[includedGroupIndex].costX,
            [choice.value]: {},
        };
    });
    setValue(`includedGroups.${includedGroupIndex}.costX`, creditCardTypes);
};

const setAccountCostXfromCreditCardType = (setValue, ccType, includedGroup, accKey, value, includedGroupIndex) => {
    setValue(`includedGroups.${includedGroupIndex}.costX`, {
        ...includedGroup.costX,
        [ccType.value]: { ...includedGroup.costX[ccType.value], [accKey.value]: value },
    });
};
