'use strict';

import React, { Component } from 'react';
import classNames from 'classnames';
import Slider from 'rc-slider';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

export default class InputSliders extends Component
{
    render() {
        const { values, setValue, yaml } = this.props;

        const conf = {
            kaufpreis: {
                ...yaml.kaufpreis,
                slider: {
                    min: 0,
                    max: 500000,
                    marks: {0: '0', 50000: '50.000', 250000: '250.000', 500000: '500.000'},
                    step: 1000,
                    ...yaml.kaufpreis.slider
                },
                name: yaml.kaufpreis.name || this.props.cf_headline_buying_price
            },
            eigenkapital: {
                ...yaml.eigenkapital,
                slider: {
                    min: 0,
                    max: 250000,
                    marks: {0: '0', 50000: '50.000', 150000: '150.000', 250000: '250.000'},
                    step: 1000,
                    ...yaml.eigenkapital.slider
                }
            },
            tilgungsrate: {
                ...yaml.tilgungsrate,
                slider: {
                    min: 1,
                    max: 8,
                    marks: {1:1, 2:2, 3:3, 4:4, 5:5, 6:6, 7:7, 8:8},
                    step: .5,
                    ...yaml.tilgungsrate.slider
                }
            }
        };

        return (
            <div className="ml-1">
                <div className="row mb-5">
                    <div className="col-sm-8 slider-element">
                        <p>{conf.kaufpreis.name}</p>
                        <Slider
                            min={conf.kaufpreis.slider.min}
                            max={conf.kaufpreis.slider.max}
                            step={conf.kaufpreis.slider.step}
                            marks={conf.kaufpreis.slider.marks}
                            tipFormatter={null}
                            value={values.kaufpreis}
                            onChange={(value) => setValue('kaufpreis', value)} />
                    </div>
                    <div className="col-sm-4 d-none d-sm-block input-with-suffix">
                        <input type="number"
                               className="form-control"
                               min={conf.kaufpreis.slider.min}
                               max={conf.kaufpreis.slider.max}
                               step={conf.kaufpreis.slider.step}
                               value={values.kaufpreis}
                               onChange={(e) => setValue('kaufpreis', parseInt(e.target.value)||conf.kaufpreis.slider.min)} /> EUR
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-sm-8 slider-element">
                        <p>{conf.eigenkapital.name}</p>
                        <Slider
                            min={conf.eigenkapital.slider.min}
                            max={conf.eigenkapital.slider.max}
                            step={conf.eigenkapital.slider.step}
                            marks={conf.eigenkapital.slider.marks}
                            tipFormatter={null}
                            value={values.eigenkapital}
                            onChange={(value) => setValue('eigenkapital', value)} />
                    </div>
                    <div className="col-sm-4 d-none d-sm-block input-with-suffix">
                        <input type="number"
                               className="form-control"
                               min={conf.eigenkapital.slider.min}
                               max={conf.eigenkapital.slider.max}
                               step={conf.eigenkapital.slider.step}
                               value={values.eigenkapital}
                               onChange={(e) => setValue('eigenkapital', parseInt(e.target.value)||conf.eigenkapital.slider.min)} /> EUR
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-sm-8 slider-element">
                        <p>{conf.tilgungsrate.name}</p>
                        <Slider
                            min={conf.tilgungsrate.slider.min}
                            max={conf.tilgungsrate.slider.max}
                            step={conf.tilgungsrate.slider.step}
                            marks={conf.tilgungsrate.slider.marks}
                            tipFormatter={null}
                            value={values.tilgungsrate}
                            onChange={(value) => setValue('tilgungsrate', value)} />
                    </div>
                    <div className="col-sm-4 d-none d-sm-block input-with-suffix">
                        <input type="number"
                               className="form-control"
                               min={conf.tilgungsrate.slider.min}
                               max={conf.tilgungsrate.slider.max}
                               step={conf.tilgungsrate.slider.step}
                               value={values.tilgungsrate}
                               onChange={(e) => setValue('tilgungsrate', parseInt(e.target.value)||conf.tilgungsrate.slider.min)} /> %
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-sm-8 slider-element">
                        <p>Sollzinsbindung in Jahren</p>
                        <Slider
                            min={0}
                            max={values.interests.length - 1}
                            marks={values.interests.map(interest => interest[0])}
                            tipFormatter={null}
                            value={values.zinsbindungIndex}
                            onChange={(value) => setValue('zinsbindung', value)} />
                    </div>
                    <div className="col-sm-4 d-none d-sm-block input-with-suffix">
                        <input type="number"
                               disabled="disabled"
                               min="1"
                               max="10"
                               className="form-control"
                               value={values.zinsbindungJahre} /> Jahre
                    </div>
                </div>
            </div>
        );
    }
}
