'use strict';

import React from 'react';

export default class Navigation extends React.Component {
    click(name) {
        const { items, jumpToPage, linked } = this.props;

        if (!linked) {
            return;
        }

        // jump to first page
        jumpToPage(items[name][0]);
    }
    render() {
        const { items, current, linked } = this.props;

        const classMapping = {};
        let activePassed = false;
        let activeIndex = null;
        Object.keys(items).reverse().forEach((name, i) => {
            if (items[name].indexOf(current) > -1) {
                classMapping[name] = 'active';
                activePassed = true;
                activeIndex = i;
            } else {
                if (activePassed) {
                    if (i === activeIndex + 1) {
                        classMapping[name] = 'last-done done';
                    } else {
                        classMapping[name] = 'done';
                    }
                } else {
                    classMapping[name] = '';
                }
            }
        });

        return (
            <div className="gl-progress-bar">
            <ol>
                {Object.keys(items).map((name, i) => (
                    <li key={`${name}_${i}`} className={classMapping[name]}>
                        <div onClick={() => this.click(name)} style={linked ? {cursor: 'pointer'} : {}}>
                            <span className="number">{i + 1}</span>
                            <span className="txt">{name}</span>
                            {i !== Object.keys(items).length - 1 ? <span className="icons-navbar-separator"></span> : <span className="icons-navbar-last"></span>}
                        </div>
                    </li>
                ))}
            </ol>
            </div>
        );
    }
}
