'use strict';

import React from 'react';
import classNames from 'classnames';

export default class StarsRating extends React.Component {
    render() {
        const { amount, of } = this.props;

        let stars = [];
        for (let i = 0; i < of; i++) {
            stars.push(<div className={classNames({ star: true, active: amount > i })}></div>);
        }

        return <div className="rating-stars">{stars}</div>;
    }
}
