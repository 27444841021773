'use strict';

import $ from 'jquery';
import React, { Component } from 'react';

import DemandCheck2Tool from '../DemandCheck2/index';

export default class DemandCheck2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    loadData(props) {
        this.setState({ loading: true });

        $.getJSON(`/tools/${props.match.params.bankSlug}/demand-check2/${props.match.params.demandCheck2Id}/configuration/`, data => {
            this.setState({ data, loading: false });
        });
    }

    componentDidMount() {
        this.loadData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.demandCheck2Id !== this.props.match.params.demandCheck2Id) {
            this.loadData(nextProps);
        }
    }

    render() {
        if (this.state.loading) {
            return <div />;
        }

        return <DemandCheck2Tool bankSlug={this.props.match.params.bankSlug} {...this.state.data} />;
    }
}
