'use strict';

import React from 'react';

export default class Html extends React.Component {
    render() {
        const { options, htmlify } = this.props;

        return (
            <div dangerouslySetInnerHTML={{__html:htmlify(options.html)}}/>
        );
    }
}
