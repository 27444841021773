import React from 'react';
import * as PageTypes from './PageType';
import { FormControl, getChoicesWithoutLabel, RemovePageTypeBtn, SelectForm, YesNoBtnGroup } from './Helper/FormControl';

const selectRef = React.createRef();

export default class Page extends React.Component {
    state = {
        opened: [],
    };

    toggle = (e, id) => {
        this.setState(state => ({
            [id]: !state[id],
        }));
        e.preventDefault();
    };

    isOpen = id => this.state[id] || false;

    render() {
        const {
            page,
            setValue,
            choices,
            summarySchemaOptions,
            setBookingTypeIcon,
            removePageType,
            pageKey,
            removeSchema,
            navigationData,
            setNaviValue,
            removeKey,
        } = this.props;
        const addPageType = selectedPageType => {
            setValue(`types.${page.types.length}`, { ...PageTypes.getEmptyValue(selectedPageType) });
        };

        const setNavigation = value => {
            let newValues = navigationData[value];
            Object.keys(navigationData).forEach(key => {
                if (navigationData[key].includes(pageKey) === true) {
                    navigationData[key].splice(pageKey, 1);
                }
            });
            if (navigationData[value].includes(pageKey) === false) {
                newValues.push(pageKey);
                setNaviValue(value, newValues);
            }
        };

        let currentNavigationValue = null;
        Object.keys(navigationData).forEach(key => {
            if (navigationData[key].includes(pageKey)) {
                currentNavigationValue = key;
            }
        });

        if (page === undefined) {
            return null;
        }

        const setDisplayFunction = value => (value ? setValue(`display`, value) : removeKey(`pages.${pageKey}.display`));

        return (
            <React.Fragment>
                <div className="card card-body mb-2">
                    <div className="row">
                        <div className="col-xl">
                            <FormControl label="H1" value={page.h1} setValue={value => setValue(`h1`, value)} />
                        </div>
                        <div className="col-xl">
                            <FormControl label="H2" value={page.h2} setValue={value => setValue(`h2`, value)} />
                        </div>
                        <div className="col-xl">
                            <FormControl label="H3" value={page.h3} setValue={value => setValue(`h3`, value)} />
                        </div>
                    </div>
                    <div className="row justify-content-start">
                        <div className="col-12 d-flex">
                            <YesNoBtnGroup label="Pflichtfeld" setValue={value => setValue(`required`, value)} active={page.required} />
                            <YesNoBtnGroup className="ml-5" label="Drucken" setValue={value => setValue(`print`, value)} active={page.print} />
                        </div>
                    </div>
                    <SelectForm
                        label="Navigation"
                        options={getChoicesWithoutLabel(navigationData)}
                        onChange={option => setNavigation(option.value)}
                        value={currentNavigationValue}
                    />
                    <FormControl
                        className="text-monospace"
                        textArea={true}
                        value={page.display}
                        label="Display-Funktion"
                        setValue={value => setDisplayFunction(value)}
                        rows="5"
                    />
                </div>

                <div className="card card-body mb-2">
                    <div className="input-group">
                        <select className="custom-select" ref={selectRef}>
                            <option value="PageType ...">PageType...</option>
                            {PageTypes.getAvailableOptions().map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" onClick={() => addPageType(selectRef.current.value)}>
                                Hinzufügen
                            </button>
                        </div>
                    </div>
                </div>

                {page.types.map((pageType, i) => {
                    if (PageTypes.getComponent(pageType.name) === null) {
                        return (
                            <div className="mb-3 mt-3 w-100 text-center">
                                <h1 className="m-0 text-danger">
                                    ERROR! PageType: <span>{pageType.name}</span> NOT INCLUDED!!
                                </h1>
                                <span className="text-muted">(configure manually in JSON)</span>
                            </div>
                        );
                    }
                    const Component = PageTypes.getComponent(pageType.name);

                    return (
                        <div key={i} className="card card-body mb-2 card-border">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>{PageTypes.getLabel(pageType.name)}</h2>
                                <div className="d-flex btn-grp">
                                    <RemovePageTypeBtn pageKey={pageKey} removePageType={removePageType} data={pageType} />
                                </div>
                            </div>
                            <div className="pagetype">
                                <Component
                                    summarySchemaOptions={summarySchemaOptions}
                                    choices={choices}
                                    data={pageType}
                                    setBookingTypeIcon={setBookingTypeIcon}
                                    setValue={(key, value) => setValue(`types.${i}.${key}`, value)}
                                    removeKey={key => removeKey(key)}
                                    removeSchema={removeSchema}
                                    pageKey={pageKey}
                                    onClick={e => this.toggle(e, i)}
                                    isOpen={() => this.isOpen(i)}
                                />
                            </div>
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }
}

const getChoices = choices =>
    Object.keys(choices).map(key => ({
        value: key,
        label: choices[key].h1,
    }));
