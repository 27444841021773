'use strict';

import React from 'react';
import classNames from 'classnames';

import InfoPopover from '../../components/InfoPopover';
import NumberInput from '../../components/NumberInput';
import { AccountPrice } from './../Model/Price';
import * as Analytics from '../analytics';

const DEFAULT_OPTIONS = {
    summary: 'bookings',
    groups: [],
    sumGroup: [],
};

export default class BookingsGridTable extends React.Component {
    constructor(props) {
        super(props);

        const { setTrigger, getEmptyTrigger } = this.props;
        const options = this.getOptions();

        const values = {};
        options.groups.forEach(group => {
            group.types.forEach(type => {
                values[type] = 0;
            });
        });

        this.state = {
            values,
            display: options.groups.map(group => (group.canToggle ? 0 : 1)),
        };

        setTrigger(getEmptyTrigger(), this.state.values);

        // handler to init values
        props.initValues(values => this.initValues(values));
    }

    initValues(values) {
        Object.keys(values).forEach(id => this.setValue(id, values[id]));
    }

    getOptions() {
        const { options } = this.props;
        return {
            ...DEFAULT_OPTIONS,
            ...options,
        };
    }

    toggleEnable(i) {
        let { display } = this.state;

        display[i] = !display[i];

        // when block gets hidden, all quantities will be set to 0
        if (!display[i]) {
            const { groups } = this.getOptions();
            groups[i].types.forEach(id => this.setValue(id, 0));
        }

        this.setState({ display });
    }

    enabled(i) {
        return this.state.display[i];
    }

    /**
     * Retrieves all booking types
     *
     * @param onlyWithValues
     * @param ids
     */
    getBookingTypes(onlyWithValues = false, ids = null) {
        const { data } = this.props;
        const { values } = this.state;

        if (null === ids) {
            ids = Object.keys(data.bookingTypes);
        }

        // will return only booking types that are chosen by the user
        if (onlyWithValues) {
            ids = ids.filter(id => values[id] > 0);
        }

        return ids.map(id => {
            // construct booking types object
            return {
                id,
                quantity: values[id],
                price: null,
                ...data.bookingTypes[id],
            };
        });
    }

    getAccounts() {
        const { data } = this.props;
        return Object.keys(data.accounts).map(accountKey => {
            return {
                id: accountKey,
                ...data.accounts[accountKey],
            };
        });
    }

    getSumGroups() {
        return this.getOptions().sumGroup.map(sumGroup => {
            return {
                on: null,
                included: null,
                priceFormat: 'monthly',
                cost: null,
                costX: null,
                ...sumGroup,
            };
        });
    }

    calculate(trigger) {
        const { getEmptyPrice, getPriceCalculator } = this.props;
        const options = this.getOptions();

        // handle all booking types
        this.getBookingTypes(true).forEach(bookingType => {
            if (null === bookingType.price) {
                return;
            }

            const price = getPriceCalculator(bookingType.price).getPrice(bookingType.quantity);
            const summary = {
                key: bookingType.name,
                name: bookingType.name,
                info: bookingType.info,
                qty: bookingType.quantity,
                price: price,
            };

            trigger.price.addPrice(price);
            trigger.summary[options.summary].push(summary);
        });

        this.getSumGroups()
            .filter(sumGroup => null !== sumGroup.included)
            .forEach(sumGroup => {
                const accountPrice = {};
                this.getAccounts().forEach(account => {
                    accountPrice[account.id] = 0;

                    let included = sumGroup.included[account.id];
                    if (0 === included) {
                        return;
                    }

                    this.getBookingTypes(true, sumGroup.on).forEach(bookingType => {
                        // all will go in, decrease included
                        if (bookingType.quantity <= included) {
                            included -= bookingType.quantity;
                            // otherwise
                        } else {
                            const rest = bookingType.quantity - included;
                            included = 0;

                            // are costs defined globally for sumGroup
                            if (null !== sumGroup.cost) {
                                accountPrice[account.id] += sumGroup.cost[account.id] * rest;
                            }
                            // are costs defined globally for bookingType
                            if (null !== sumGroup.costX) {
                                accountPrice[account.id] += sumGroup.costX[bookingType.id][account.id] * rest;
                            }
                        }
                    });
                });

                const sumGroupPrice = new AccountPrice(accountPrice, sumGroup.priceFormat);
                if (sumGroupPrice.isNotNull()) {
                    trigger.summary[options.summary].push({
                        key: sumGroup.name,
                        name: sumGroup.name,
                        info: sumGroup.info,
                        price: sumGroupPrice,
                    });
                    // add this price to the global price
                    trigger.price.addPrice(sumGroupPrice);
                }
            });

        return trigger;
    }

    setValue(id, value) {
        const { setTrigger, getEmptyTrigger, getPriceCalculator, data } = this.props;

        const options = this.getOptions();

        let { values } = this.state;

        values[id] = value;

        this.setState({ values });

        const trigger = getEmptyTrigger();
        trigger.summary[options.summary] = [];

        this.calculate(trigger);

        setTrigger(trigger, this.state.values);

        Analytics.trackEvent('Component_BookingGridTable', 'set_value', id, value);
    }

    render() {
        const { groups } = this.getOptions();

        return (
            <div className="row">
                {groups.map((group, i) => {
                    return (
                        <div className={'col-' + group.grid} key={'group' + i}>
                            <table key={group.name} className="table table-fluid table-striped">
                                <thead>
                                    <tr>
                                        <th colSpan="3">
                                            {group.name} &nbsp;{' '}
                                            {group.canToggle ? (
                                                <button
                                                    className={classNames('btn', 'btn-outline-secondary', { active: this.enabled(i) })}
                                                    onClick={() => this.toggleEnable(i)}>
                                                    {this.enabled(i) ? 'Nein, Auswahl entfernen!' : 'Ja, bitte hinzufügen!'}
                                                </button>
                                            ) : (
                                                ''
                                            )}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={classNames({ hide: !this.state.display[i] })}>
                                    {this.getBookingTypes(false, group.types).map(bookingType => {
                                        return (
                                            <tr key={bookingType.id}>
                                                <td style={{ width: 80 }}>
                                                    <NumberInput value={bookingType.quantity} onChange={value => this.setValue(bookingType.id, value)} />
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    {bookingType.name} <InfoPopover>{bookingType.info}</InfoPopover>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>
        );
    }
}
