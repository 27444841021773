import React from 'react';
import objectPath from 'object-path';
import { AddRatingFormControl, Btn, FormControl, SelectForm, SelectFormPriceFormat, YesNoBtnGroup } from '../../Helper/FormControl';

export default class Schema extends React.Component {
    constructor(props) {
        super(props);
        this.inputRefs = [];
    }
    render() {
        const { data, i, sideBarTypeOptions, setValue, schema, pageKey, removeSchema } = this.props;
        const currentSchema = objectPath.get(data.schema, schema);

        const removeRatingsValue =
            currentSchema && currentSchema.ratings && currentSchema.ratings.length > 0
                ? currentSchema.ratings.map(rating => {
                      return {
                          value: rating,
                          label: rating,
                      };
                  })
                : null;
        const newRatings = rating => {
            let newRatings = currentSchema.ratings;
            const i = newRatings.indexOf(rating);
            if (i > -1) {
                newRatings.splice(i, 1);
            }
            return newRatings;
        };
        const onKeyEnter = e => {
            if (e.key === 'Enter') {
                setValue(`schema.${schema}.ratings`, [...currentSchema.ratings, e.target.value]);
                e.target.value = null;
            }
        };

        const setRating = id => setValue(`schema.${schema}.ratings`, [...currentSchema.ratings, this.inputRefs[id].value]);

        return (
            <>
                {currentSchema && (
                    <div key={i} className="card card-body mt-3 mb-3">
                        <div className="d-flex align-items center justify-content-between">
                            <h2>Schema</h2>
                            <Btn delBtn={true} onClick={() => removeSchema(pageKey, schema)} />
                        </div>
                        <FormControl label="Name:" value={currentSchema.name} setValue={value => setValue(`schema.${schema}.name`, value)} />
                        <div className="row">
                            <div className="col-12 d-flex">
                                <YesNoBtnGroup
                                    label="Summation ?"
                                    setValue={value => setValue(`schema.${schema}.summation`, value)}
                                    active={currentSchema.summation}
                                />
                                <YesNoBtnGroup
                                    className="ml-5"
                                    label="Grundpreis includieren ?"
                                    setValue={value => setValue(`schema.${schema}.includeBaseFee`, value)}
                                    active={currentSchema.includeBaseFee}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <SelectFormPriceFormat
                                    label="Preisformat ?"
                                    options="default"
                                    value={currentSchema.priceFormat}
                                    onChange={value => setValue(`schema.${schema}.priceFormat`, value.value)}
                                />
                            </div>
                            <div className="col-6">
                                <SelectForm
                                    label="Sidebartyp"
                                    value={currentSchema.sidebarType}
                                    onChange={value => setValue(`schema.${schema}.sidebarType`, value.value)}
                                    options={sideBarTypeOptions}
                                />
                            </div>
                        </div>
                        {currentSchema.sidebarType === 'stars' && (
                            <FormControl
                                nmbrInput={true}
                                value={currentSchema.starsAmount}
                                label="Anzahl an Sternen:"
                                setValue={value => setValue(`schema.${schema}.starsAmount`, value)}
                            />
                        )}
                        {currentSchema.sidebarType === 'ratings' && (
                            <div className="d-flex">
                                <AddRatingFormControl
                                    onKeyEnter={e => onKeyEnter(e)}
                                    label="Auswahlmöeglichkeit hinzufügen"
                                    inputRef={ref => (this.inputRefs[i] = ref)}
                                />
                                <Btn btnText="+" className="btn btn-primary" onClick={() => setRating(i)} />
                                {currentSchema.ratings != currentSchema.ratings.length && (
                                    <div className="d-flex align-items-center ml-5">
                                        <SelectForm
                                            className="schema-del-choice"
                                            label="Auswahlmöeglichkeiten entfernen"
                                            options={removeRatingsValue}
                                            multi={true}
                                            value={currentSchema.ratings}
                                            onValueClick={value => setValue(`schema.${schema}.ratings`, newRatings(value.value))}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    }
}
