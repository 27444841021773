'use strict';

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { refactorIconName } from '../../vendor/font-awesome';
import InfoPopover from '../../components/InfoPopover';
import * as Analytics from '../analytics';
import Button from './IconChoice';

const DEFAULT_OPTIONS = {
    choices: [],
    values: [],
    multiple: false,
    summary: '',
    label: '',
    iconChecked: 'fa-check-circle',
    iconUnchecked: 'fa-circle',
    infoText: 'Bitte treffen Sie eine Auswahl.',
};

const CHOICE_OPTIONS = {
    label: '',
    small: '',
    info: '',
    summaryLabel: '',
};

const VALUE_OPTIONS = {
    label: '',
    icon: '',
    summaryText: null,
    width: null,
};

export default class ChoiceMatrix extends React.Component {
    state = {
        matrix: {},
    };

    constructor(props) {
        super(props);

        const { defaultValue, setTrigger } = this.props;

        this.state = {
            matrix: defaultValue || {},
        };

        setTrigger({}, this.state.matrix);

        // handler to init values
        props.initValues(value => {
            this.setState({ matrix: value }, () => this.setValue(false, false));
        });
    }

    getOptions() {
        const { options } = this.props;

        const mergedOptions = {
            ...DEFAULT_OPTIONS,
            ...options,
        };

        return {
            ...mergedOptions,
            choices: Object.keys(mergedOptions.choices).map(choiceKey => ({
                ...CHOICE_OPTIONS,
                ...mergedOptions.choices[choiceKey],
                key: choiceKey,
            })),
            values: Object.keys(mergedOptions.values).map(valueKey => ({
                ...VALUE_OPTIONS,
                ...mergedOptions.values[valueKey],
                key: valueKey,
            })),
        };
    }

    setValue(choice, value) {
        const callFromInitValues = choice === false && value === false;

        const options = this.getOptions();
        const { matrix } = this.state;

        if (!callFromInitValues) {
            let matrixKey = key(choice, value);
            matrix[matrixKey] = !matrix[matrixKey];

            // when it is not a multiple select
            if (!options.multiple) {
                // loop all matrix items
                Object.keys(matrix).forEach(key => {
                    // then if it is another key for the same choices ... delete it
                    if (key !== matrixKey && key.includes(`${choice.key}_`)) {
                        delete matrix[key];
                    }
                });
            }
        }

        const { setTrigger, getEmptyTrigger, getPriceCalculator } = this.props;

        // set the trigger based upon the selected items
        let trigger = getEmptyTrigger();
        // if summary flag is set, add current values to this summary namespace
        if (false !== options.summary) {
            trigger.summary[options.summary] = [];

            options.choices.forEach(choice => {
                // group values
                const values = [];
                options.values.forEach(value => {
                    if (!this.isSet(choice, value)) {
                        return;
                    }
                    // use summarytext but make fallback to label
                    values.push(value.summaryText || value.label);
                });

                // skip if there are no values
                if (0 === values.length) {
                    return;
                }
                const price = getPriceCalculator(value.price).getPrice(value);
                trigger.summary[options.summary].push({
                    key: choice.key,
                    name: `${choice.label}`,
                    info: '',
                    price: price,
                    text: values.join(', '),
                    icon: '',
                    summaryInfo: '',
                });
            });
        }

        this.setState({ matrix });
        setTrigger(trigger, this.state.matrix);

        Analytics.trackEvent('Component_ChoiceMatrix', 'set_value', 'value', Object.keys(matrix).join(', '));
    }

    isSet(choice, value) {
        return this.state.matrix[key(choice, value)] || false;
    }

    render() {
        const options = this.getOptions();

        return (
            <div>
                <table className="table table-striped">
                    <thead className="thead-default">
                        <tr>
                            <th>{options.label}</th>
                            {options.values.map(value => (
                                <th className="text-center" key={value.key} style={{ width: value.width }}>
                                    <div>
                                        {value.icon.startsWith('http') ? (
                                            <img src={value.icon} alt="" className="img-fluid" />
                                        ) : (
                                            <FontAwesomeIcon icon={refactorIconName(value.icon)} size="2x" />
                                        )}
                                    </div>
                                    <small dangerouslySetInnerHTML={{ __html: value.label === ' ' ? '&nbsp;' : value.label }}></small>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {options.choices.map(choice => (
                            <tr key={choice.key}>
                                <td style={{ height: 60 }} className="align-middle">
                                    {choice.label} <InfoPopover>{choice.info}</InfoPopover>
                                    <br />
                                    {choice.small && <small>{choice.small}</small>}
                                </td>
                                {options.values.map(value => (
                                    <td
                                        key={key(choice, value)}
                                        className={`text-center align-middle ${this.isSet(choice, value) ? 'blue' : 'text-muted'}`}
                                        onClick={() => this.setValue(choice, value)}>
                                        <div style={{ cursor: 'pointer' }}>
                                            <span className={this.isSet(choice, value) ? '' : 'd-none'}>
                                                <FontAwesomeIcon icon={refactorIconName(options.iconChecked)} size="2x" />
                                            </span>
                                            <span className={this.isSet(choice, value) ? 'd-none' : ''}>
                                                <FontAwesomeIcon icon={refactorIconName(options.iconUnchecked)} size="" />
                                            </span>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="text-right">{options.infoText}</div>
            </div>
        );
    }
}

const key = (choice, value) => `${choice.key}_${value.key}`;
