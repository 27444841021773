'use strict';

import Keymaster from 'keymaster';
import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import InfoPopover from '../components/InfoPopover';
import AccountPriceView from './AccountPriceView';

import ConfigCode from './ConfigCode';
import Navigation from './Navigation';
import Debug from './Debug';
import IconChoiceBar from './IconChoiceBar';
import ActionButtons from './ActionButtons';
import { FORMAT_MONTHLY as PRICE_FORMAT_MONTHLY, getEmptyPrice, getPriceCalculator, getPrices } from './Model/Price';
import DisplayHelper from './DisplayHelper';

import { getView } from './PageType/_index';
import { normalizeTrigger } from './trigger';
import * as Analytics from './analytics';

import { saveConfig, loadConfig } from './Model/ConfigCode';
import objectPath from 'object-path';
import * as api from './api';

const initHandlers = {};
const appendedFileRefs = [];

// saves current prices the getPrice method calculates to put it to isPageDisplayed() because of circular reference
let globalPrices = {};

const DEFAULT_OPTIONS = {
    templateAccountPreview: '<small>%account%</small>',
    displayAccountPrice: true,
    configCode: {
        enabled: false,
        labelLoad: 'Öffnen',
        labelSave: 'Speichern',
        ignore: [],
        name: 'Beratungs-Code',
        saveTextHtml: '<p>Bitte notieren Sie Ihren persönlichen Beratungs-Code. Damit können Sie Ihre gespeicherte Beratung laden.</p>',
        withSave: true,
        withLoad: true,
    },
    h1: null,
    h2: null,
    h3: null,
    legal: false,
    imprint: false,
    imprintText: null,
    printSummary: 'Die Kosten belaufen sich auf',
    printSummaryPrice: true,
    priceInfo: '',
    debug: false,
    iconChoiceBar: {
        display: false,
        hideOn: ['summary'],
        clickJumpTo: 'summary',
    },
    refCode: null,
    buttonsWithCheck: false,
    navigationLinked: false,
    notify: {
        pageTypes: [],
    },
    bankLogo: '',
};

const PAGE_OPTIONS = {
    h1: null,
    h2: null,
    h3: null,
    required: false,
    background: null,
    layoutGrid: null,
    actionButtons: true,
    navigation: true,
};

export default class AccountFinder extends React.Component {
    constructor(props) {
        super(props);

        // this is some sort of stack to handle last pages, if the path changes through the defined pages
        let lastPages = [];
        let activePage = Object.keys(props.pages)[0];

        // create empty triggers for each canvas/widget. the idea is to give each component (widget on a canvas) the
        // posibility to change the corresponding triggers object. all those values are calculated together to get
        // the overall result
        let triggers = {};
        let values = {};
        Object.keys(props.pages).forEach(page => {
            props.pages[page].types.forEach((type, i) => {
                triggers[page + '_' + i] = this.getEmptyTrigger(page);
            });
            values[page] = null;
        });

        this.state = {
            lastPages,
            activePage,
            triggers,
            values,
            valuesInitialized: false,
            code: null,
        };

        this.myrefs = {};

        // for caching only
        this.pages = {};
        this.options = null;
    }

    setValue(key, value) {
        this.setState(state => ({
            ...state,
            values: {
                ...state.values,
                [key]: value,
            },
        }));
    }

    componentDidMount() {
        Keymaster('left', e => {
            if (this.pagePrevious()) {
                this.gotoPreviousPage();
            }
            return false;
        });
        Keymaster('right', e => {
            if (this.pageNext() && this.canNext()) {
                this.gotoNextPage();
            }
            return false;
        });
    }

    /**
     * Creates an empty trigger
     *
     * @returns {{price: *, forceAccount: null, excludeAccount: null, nextPage: null, summary: {}}}
     */
    getEmptyTrigger(originPageKey) {
        return {
            originPageKey,
            price: getEmptyPrice(this.props.data.accounts),
            forceAccount: null,
            excludeAccount: null,
            nextPage: null,
            summary: {},
        };
    }

    /**
     * Checks if a passed page is displayed. Pass the page key
     *
     * @param pageKey
     * @param prices
     */
    isPageDisplayed(pageKey) {
        // addons will be treated differently
        if (pageKey.match(/^__.*__addon$/)) {
            return false;
        }

        const { values, valuesInitialized } = this.state;

        const page = this.getPage(pageKey);

        if (!valuesInitialized) {
            return true;
        }
        // check display function if available
        if (typeof page.display === 'string') {
            const display = eval(`(function (values, prices) {${page.display}})`);
            return display(passedValuesEnricher(values), globalPrices, new DisplayHelper());
        } else if (typeof page.display === 'function') {
            return page.display(passedValuesEnricher(values), globalPrices, new DisplayHelper());
        }
        return true;
    }

    /**
     * Checks if a next page exists and returns the name of the next page (null otherwise)
     *
     * @returns {null}
     */
    pageNext() {
        const { activePage, triggers } = this.state;

        const pages = Object.keys(this.props.pages);
        const index = pages.indexOf(activePage);

        // check if there is a trigger to skip a page
        const currentPageTypeLength = this.props.pages[activePage].types.length;
        for (var i = 0; i < currentPageTypeLength; i++) {
            if (null !== triggers[activePage + '_' + i].nextPage) {
                return triggers[activePage + '_' + i].nextPage;
            }
        }

        // check if next page has a display function. if it has one, execute it and if it returns false
        // check the next page
        for (var i = index + 1; i < pages.length; i++) {
            if (this.isPageDisplayed(pages[i])) {
                return pages[i];
            }
        }

        return null;
    }

    /**
     * Goes to the next page
     */
    gotoNextPage() {
        let { lastPages, activePage } = this.state;

        const lastPage = lastPages.length === 0 ? '' : activePage;

        lastPages.push(activePage);
        activePage = this.pageNext();

        Analytics.trackPageView(lastPage, activePage, activePage);

        this.setState({ lastPages, activePage });
    }

    /**
     * jumps directly to the page
     */
    jumpToPage(page) {
        let { lastPages, activePage } = this.state;

        if (activePage === page) {
            return;
        }

        lastPages.push(activePage);
        Analytics.trackPageView(activePage, page, page);

        activePage = page;

        this.setState({ lastPages, activePage });
    }

    /**
     * Checks if a previous page exists and returns the name of the previous page (null otherwise)
     *
     * @returns {null}
     */
    pagePrevious() {
        let { lastPages } = this.state;

        return 0 === lastPages.length ? null : lastPages[lastPages.length - 1];
    }

    /**
     * Goes to previous page
     */
    gotoPreviousPage() {
        let { lastPages, activePage } = this.state;

        if (lastPages.length > 0) {
            const lastPage = activePage;
            activePage = lastPages.pop();

            this.setState({ lastPages, activePage });

            Analytics.trackPageView(lastPage, lastPages.length === 0 ? '' : activePage, activePage);
        }
    }

    canNext() {
        if (!this.getPage().required) {
            return true;
        }

        const { values, activePage } = this.state;
        const value = values[activePage];

        if (Array.isArray(value)) {
            return value.length > 0;
        }

        return value !== null;
    }

    /**
     * The methods used in the bottom components to trigger behavior in the application
     *
     * @param page
     * @param view
     * @param trigger
     * @param value
     */
    setTrigger(page, view, trigger = {}, value = null) {
        let { triggers, values } = this.state;

        trigger = normalizeTrigger(trigger);

        // merge trigger into empty trigger
        triggers[page + '_' + view] = {
            ...this.getEmptyTrigger(page),
            ...trigger,
        };

        if (null !== value) {
            // merge trigger into empty trigger
            values[page] = value;
        }

        this.setState({
            triggers,
            values,
            valuesInitialized: true,
        });
    }

    /**
     * Retrieves a list with all triggers, but onl
     */
    getTriggers() {
        const { values, valuesInitialized, triggers } = this.state;
        const result = [];

        Object.keys(triggers).forEach(pageView => {
            const trigger = triggers[pageView];
            // of course only display the triggers that are on pages that are displayed
            if (this.isPageDisplayed(trigger.originPageKey)) {
                result.push(trigger);
            }
        });

        // execute global triggers which is some kind of event stuff thingy
        if ((this.props.globalTriggers || []).length > 0 && valuesInitialized) {
            this.props.globalTriggers.forEach(globalTrigger => {
                let trigger = globalTrigger(passedValuesEnricher(values));
                if (null !== trigger) {
                    trigger = {
                        ...this.getEmptyTrigger('__GLOBAL_TRIGGER__'),
                        ...trigger,
                    };

                    result.push(normalizeTrigger(trigger));
                }
            });
        }

        return result;
    }

    getPages() {
        return Object.keys(this.props.pages).map(pageKey => this.getPage(pageKey));
    }

    /**
     * Retrieves a page. Active page by default
     *
     * @param pageKey
     * @returns {{layoutGrid: null, pageKey: *}}
     */
    getPage(pageKey = this.state.activePage) {
        // apply caching
        if (typeof this.pages[pageKey] !== 'undefined') {
            return this.pages[pageKey];
        }

        const { pages } = this.props;
        const page = pages[pageKey];

        const options = this.getOptions();

        return (this.pages[pageKey] = {
            ...PAGE_OPTIONS,
            ...page,
            h1: page.h1 || options.h1 || '',
            h2: page.h2 || options.h2 || '',
            h3: page.h3 || options.h3 || '',
            displayIconChoiceBar: options.iconChoiceBar.display && options.iconChoiceBar.hideOn.indexOf(this.state.activePage) === -1,
            pageKey,
        });
    }

    /**
     * Retrieves all PageTypes for one page
     * @param page
     * @param prices
     * @returns {*}
     */
    getPageTypes(page, prices) {
        return page.types.filter(typeOptions => {
            typeOptions.bankLogo = this.getOptions().bankLogo;
            if (typeof typeOptions.display === 'function') {
                if (!typeOptions.display(passedValuesEnricher(this.state.values), prices, new DisplayHelper())) {
                    return false;
                }
            }
            return true;
        });
    }

    getPageTypeHtml(key) {
        return ReactDOM.findDOMNode(this.myrefs[key]).innerHTML;
    }

    htmlify(text) {
        const { data } = this.props;
        const html = data.html || {};

        // replace all html
        Object.keys(html).forEach(htmlKey => {
            text = text.replace('@@' + htmlKey, html[htmlKey]);
        });

        return text;
    }

    async saveConfigCode(code = null) {
        const data = await saveConfig({
            bankSlug: this.props.bankSlug,
            accountFinderId: this.props.accountFinderId,
            config: this.getValues(this.getOptions().configCode.ignore),
            code: code || this.state.code,
        });

        this.setState({ code: data.code });

        return data;
    }

    async loadConfigCode(code) {
        const data = await loadConfig({
            bankSlug: this.props.bankSlug,
            accountFinderId: this.props.accountFinderId,
            code,
        });

        if (data.success) {
            this.setState({ code });
            this.initValues(data.config);
        }

        return data;
    }

    async notifyResult({ html, contact, subject, mailBody }) {
        // filter all selected files from appendedFileRefs
        const files = appendedFileRefs
            .filter(file => file.fileRef.current != null && file.fileRef.current.files.length > 0)
            .map(file => ({
                name: file.name,
                file: file.fileRef.current.files[0],
            }));

        return await api.notifyResult({
            bankSlug: this.props.bankSlug,
            accountFinderId: this.props.accountFinderId,
            html,
            contact,
            subject,
            files,
            values: this.state.values,
            mailBody,
        });
    }

    renderPage(triggers, page, prices, options) {
        const RenderWidget = (i, typeOptions) => {
            const Widget = getView(typeOptions.name);
            const key = `${page.pageKey}_${i}`;
            return (
                <div className="page-type" ref={element => (this.myrefs[key] = element)} key={key}>
                    <Widget
                        // init values function sets initial values loaded from database
                        initValues={func => (initHandlers[page.pageKey] = func)}
                        appendFileRef={(name, fileRef) => appendedFileRefs.push({ name, fileRef })}
                        bankSlug={this.props.bankSlug}
                        data={this.props.data}
                        triggers={triggers}
                        values={this.state.values}
                        options={typeOptions}
                        prices={prices}
                        setTrigger={(trigger, value) => this.setTrigger(page.pageKey, i, trigger, value)}
                        setTriggerAddon={(trigger, value) => this.setTrigger(`__${page.pageKey}__addon`, i, trigger, value)}
                        getEmptyTrigger={() => this.getEmptyTrigger(page.pageKey)}
                        getEmptyPrice={withBaseFee => getEmptyPrice(this.props.data.accounts, withBaseFee)}
                        getPriceCalculator={options => getPriceCalculator(this.props.data.accounts, options)}
                        defaultValue={page.defaultValue}
                        getPageTypeHtml={key => this.getPageTypeHtml(key)}
                        htmlify={text => this.htmlify(text)}
                        refCode={options.refCode}
                        buttonsWithCheck={options.buttonsWithCheck}
                        printSummary={options.printSummary}
                        loadConfigCode={this.loadConfigCode.bind(this)}
                        saveConfigCode={this.saveConfigCode.bind(this)}
                        notifyResult={this.notifyResult.bind(this)}
                        isPageDisplayed={this.isPageDisplayed.bind(this)}
                        gotoPreviousPage={this.gotoPreviousPage.bind(this)}
                        gotoNextPage={this.gotoNextPage.bind(this)}
                        gotoPage={this.jumpToPage.bind(this)}
                        pageNext={this.pageNext.bind(this)}
                    />
                </div>
            );
        };

        // handle layout grid. if it is not defined, render all components one after the other
        if (null === page.layoutGrid) {
            return this.getPageTypes(page, prices).map((typeOptions, i) => RenderWidget(i, typeOptions));
        }

        // otherwise, take care of layout grid which is passed as .col-sm-<LAYOUT_GRID> into one .row
        return (
            <div className="row align-items-center justify-content-center">
                {this.getPageTypes(page, prices).map((typeOptions, i) => (
                    <div key={i} className={'col-' + page.layoutGrid[i] || 12}>
                        {RenderWidget(i, typeOptions)}
                    </div>
                ))}
            </div>
        );
    }

    initValues(values) {
        Object.keys(values)
            // only apply init handlers that are available
            .filter(id => typeof initHandlers[id] !== 'undefined')
            .forEach(id => initHandlers[id](values[id], values[`__${id}__addon`] || null));
    }

    getValues(ignoreFields = []) {
        if (0 === ignoreFields.length) {
            return this.state.values;
        }

        let result = {};
        Object.keys(this.state.values)
            // filter out all fields on ignore
            .filter(key => !ignoreFields.includes(key))
            .forEach(key => {
                result = {
                    ...result,
                    [key]: this.state.values[key],
                };
            });

        return result;
    }

    getOptions() {
        const { options } = this.props;
        return {
            DEFAULT_OPTIONS,
            ...options,
            iconChoiceBar: {
                ...DEFAULT_OPTIONS.iconChoiceBar,
                ...options.iconChoiceBar,
            },
            configCode: {
                ...DEFAULT_OPTIONS.configCode,
                ...options.configCode,
            },
        };
    }

    render() {
        const options = this.getOptions();
        const page = this.getPage();
        const triggers = this.getTriggers();

        const prices = (globalPrices = getPrices(this.props.data.accounts, triggers));

        return (
            <div
                style={page.background !== null ? { backgroundImage: `url(${page.background})`, backgroundSize: 'cover' } : {}}
                className={page.background !== null ? 'with-background' : ''}>
                <div className="container-fluid" style={{ maxWidth: '1200px' }}>
                    {options.configCode.enabled && (
                        <ConfigCode
                            loadConfigCode={this.loadConfigCode.bind(this)}
                            saveConfigCode={this.saveConfigCode.bind(this)}
                            options={options.configCode}
                        />
                    )}

                    {page.h1 && <h1>{page.h1}</h1>}
                    {page.h2 && <h2>{page.h2}</h2>}

                    <div className="d-print-none clearfix mb-1">
                        {prices === null || (
                            <div className="float-sm-right text-sm-right">
                                <div className="price">
                                    {options.templateAccountPreview && (
                                        <div dangerouslySetInnerHTML={{ __html: options.templateAccountPreview.replace(/%account%/, prices.activeName) }}></div>
                                    )}{' '}
                                    {options.displayAccountPrice && (
                                        <span>
                                            €{' '}
                                            <AccountPriceView
                                                accountPrice={prices.accountPrices}
                                                accountKey={prices.activeKey}
                                                format={PRICE_FORMAT_MONTHLY}
                                                suffix={true}
                                            />
                                            <InfoPopover position="bottom">{options.priceInfo}</InfoPopover>
                                        </span>
                                    )}
                                </div>
                            </div>
                        )}

                        {page.navigation && (
                            <Navigation
                                items={this.props.navigation}
                                current={this.state.activePage}
                                linked={options.navigationLinked}
                                jumpToPage={page => this.jumpToPage(page)}
                            />
                        )}
                    </div>

                    {page.h3 && <h3>{page.h3}</h3>}

                    <div className="main-content mt-2" style={{ height: options.height, display: 'table', width: '100%' }}>
                        <div style={{ display: 'table-cell', verticalAlign: 'middle', width: '100%' }}>
                            {this.getPages().map(page => (
                                <div key={page.pageKey} className={classNames({ hide: page.pageKey !== this.state.activePage })}>
                                    {this.renderPage(triggers, page, prices, options)}
                                </div>
                            ))}
                        </div>
                    </div>

                    {prices === null || (
                        <div className="d-none d-print-block">
                            <p>
                                {options.printSummary}{' '}
                                {options.printSummaryPrice && (
                                    <strong>
                                        €{' '}
                                        <AccountPriceView
                                            accountPrice={prices.accountPrices}
                                            accountKey={prices.activeKey}
                                            format={PRICE_FORMAT_MONTHLY}
                                            suffix={true}
                                        />
                                    </strong>
                                )}
                            </p>
                        </div>
                    )}

                    <div className="row align-items-center d-print-none">
                        <div className="col-auto">
                            {options.refCode && (
                                <div>
                                    Ihr Berater: {options.refCode.firstname} {options.refCode.lastname}
                                </div>
                            )}
                            <span className="highlight-with-background">
                                {options.imprint && (
                                    <a href={options.imprint} target="_blank">
                                        {options.imprintText ? options.imprintText : "Impressum"}
                                    </a>
                                )}{' '}
                                {options.legal && (
                                    <span>
                                        Rechtliche Hinweise <InfoPopover>{options.legal}</InfoPopover>
                                    </span>
                                )}
                            </span>
                        </div>
                        <div className="col text-center">
                            {page.displayIconChoiceBar && (
                                <span className="highlight-with-background">
                                    <IconChoiceBar
                                        triggers={this.state.triggers}
                                        values={this.state.values}
                                        jumpToPage={() => this.jumpToPage(options.iconChoiceBar.clickJumpTo)}
                                    />
                                </span>
                            )}
                        </div>
                        <div className="col-auto text-right">
                            {page.actionButtons && (
                                <ActionButtons
                                    page={page}
                                    pageNext={this.pageNext()}
                                    gotoNextPage={() => this.gotoNextPage()}
                                    pagePrevious={this.pagePrevious()}
                                    gotoPreviousPage={() => this.gotoPreviousPage()}
                                    canNext={this.canNext()}
                                />
                            )}
                        </div>
                    </div>

                    <Debug display={options.debug} state={this.state} />
                </div>
            </div>
        );
    }
}

/**
 * This method enriches all the values with nice functions accessing all the values
 * @param values
 * @returns {{safeValue: (function(*=, *=): (*|string))}}
 */
const passedValuesEnricher = values => {
    return {
        ...values,
        safeValue: (path, defaultValue = '') => objectPath.get(values, path) || defaultValue,
    };
};
