import React from 'react';
import { Btn, getChoices, SelectFormSummarySchema } from '../Helper/FormControl';
import { DEFAULT_TRIGGER, OnTrueOptions, OnFalseOptions } from './Trigger/TriggerOptions';
import SearchAndSelectIconsOverlay from '../Helper/SearchAndSelectIconsOverlay';

export const label = 'Ja/Nein';

export const DEFAULT_OPTIONS = {
    onTrue: {},
    onFalse: {},
    yesIcon: 'thumbs-up',
    noIcon: 'thumbs-down',
};

export default ({ data, setValue, summarySchemaOptions, choices }) => {
    const triggerOnTrue = schema => {
        let cost = {};
        getChoices(choices.accounts).forEach(account => {
            cost = {
                ...cost,
                [account.value]: 0,
            };
        });
        setValue(`onTrue`, {
            summary: {
                [schema]: [
                    {
                        ...DEFAULT_TRIGGER,
                        cost,
                    },
                ],
            },
        });
    };
    const triggerOnFalse = schema => {
        let cost = {};
        getChoices(choices.accounts).forEach(account => {
            cost = {
                ...cost,
                [account.value]: 0,
            };
        });
        setValue(`onFalse`, {
            summary: {
                [schema]: [
                    {
                        ...DEFAULT_TRIGGER,
                        cost,
                    },
                ],
            },
        });
    };

    const selectedOnTrueSchema = () => {
        let currentOnTrueSchema = null;

        if (Object.keys(data.onTrue).length != 0) {
            Object.keys(data.onTrue.summary).forEach(key => {
                currentOnTrueSchema = key;
            });
            return currentOnTrueSchema;
        }
    };

    const selectedOnFalseSchema = () => {
        let currentOnFalseSchema = null;

        if (Object.keys(data.onFalse).length != 0) {
            Object.keys(data.onFalse.summary).forEach(key => {
                currentOnFalseSchema = key;
            });
            return currentOnFalseSchema;
        }
    };
    return (
        <React.Fragment>
            <div className="d-flex align-items-baseline">
                <SearchAndSelectIconsOverlay setValue={setValue} labelTitle="Ja Icon" setValuePath={`yesIcon`} currentSelectedIcon={data.yesIcon} />
                <SearchAndSelectIconsOverlay
                    className="ml-3"
                    setValue={setValue}
                    labelTitle="Nein Icon"
                    setValuePath={`noIcon`}
                    currentSelectedIcon={data.noIcon}
                />
            </div>
            <SelectFormSummarySchema
                label="onTrue Trigger"
                options={summarySchemaOptions}
                onChange={choice => triggerOnTrue(choice.value)}
                value={selectedOnTrueSchema()}
            />
            {Object.keys(data.onTrue).length != 0 ? (
                <div className="card card-body mb-4 mt-2">
                    <div className="d-flex justify-content-between">
                        <h3>On True options</h3>
                        <Btn onClick={() => setValue(`onTrue`, {})} delBtn={true} />
                    </div>
                    {Object.keys(data.onTrue.summary).map((key, i) => (
                        <OnTrueOptions key={key} schema={key} i={i} choices={choices} setValue={setValue} data={data} />
                    ))}
                </div>
            ) : null}
            <SelectFormSummarySchema
                label="onFalse Trigger"
                options={summarySchemaOptions}
                onChange={choice => triggerOnFalse(choice.value)}
                value={selectedOnFalseSchema()}
            />
            {Object.keys(data.onFalse).length != 0 ? (
                <div className="card card-body mb-4 mt-2">
                    <div className="d-flex justify-content-between">
                        <h3>On False options</h3>
                        <Btn onClick={() => setValue(`onFalse`, {})} delBtn={true} />
                    </div>
                    {Object.keys(data.onFalse.summary).map((key, i) => (
                        <OnFalseOptions key={key} schema={key} i={i} choices={choices} setValue={setValue} data={data} />
                    ))}
                </div>
            ) : null}
        </React.Fragment>
    );
};
