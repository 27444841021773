'use strict';

import React, { Component } from 'react';
import Button from '../../components/Button';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import * as Analytics from '../analytics';
import { pdfFromHtml } from '../api';

const DEFAULT_OPTIONS = {
    buttons: [],
    contactFormType: 'contact_form',
};

const BUTTON_OPTIONS = {
    buttonText: '',
    buttonSize: 'lg',
    buttonColor: 'primary',
    iconName: '',
    iconPosition: 'right',
    trigger: {},
    action: 'noop', // 'noop|prev|next|next_with_notify|page|link|print|print_pdf',
    page: '',
    linkUrl: '#',
    linkTarget: '_blank',
    includePageTypes: [],
    saveConfigCode: false,
    subject: '',
    mailBody: 'weitere Details finden Sie im PDF-Anhang',
};

export default class CallToAction extends Component {
    state = {
        value: '',
        submitError: false,
    };

    getOptions() {
        const { options, values } = this.props;

        const mergedOptions = {
            ...DEFAULT_OPTIONS,
            ...options,
        };

        return {
            ...mergedOptions,
            getContactFormValues: () => (mergedOptions.contactFormType ? values[mergedOptions.contactFormType] : {}),
            buttons: mergedOptions.buttons.map(button => ({
                ...BUTTON_OPTIONS,
                ...button,
            })),
        };
    }

    click(button) {
        const { gotoPage, gotoNextPage, gotoPreviousPage, getPageTypeHtml, notifyResult, saveConfigCode, bankSlug } = this.props;
        const options = this.getOptions();

        if (button.saveConfigCode) {
            saveConfigCode();
        }

        switch (button.action) {
            case 'prev':
                gotoPreviousPage();
                break;

            case 'next':
                gotoNextPage();
                break;

            case 'notify':
                notifyResult({
                    html: button.includePageTypes.map(pageType => getPageTypeHtml(pageType)),
                    contact: options.getContactFormValues(),
                    subject: button.subject,
                    mailBody: button.mailBody,
                }).then(async response => {
                    const json = await response.json();
                    this.setState({ submitError: !json.success });
                });
                break;

            case 'next_with_notify':
                Analytics.trackEvent('Component_CallToAction', 'next_with_notify');

                notifyResult({
                    html: button.includePageTypes.map(pageType => getPageTypeHtml(pageType)),
                    contact: options.getContactFormValues(),
                    subject: button.subject,
                    mailBody: button.mailBody,
                }).then(async response => {
                    const json = await response.json();

                    if (button.page && json.success) {
                        gotoPage(button.page);
                    } else if (json.success) {
                        gotoNextPage();
                    }

                    this.setState({ submitError: !json.success });
                });
                break;

            case 'page':
                if (!button.page) {
                    throw new Error(`"button.page" is empty when requested jumping to page.`);
                }
                gotoPage(button.page);
                break;

            case 'print':
                Analytics.trackEvent('Component_CallToAction', 'print');

                window.print();
                break;

            case 'print_pdf':
                Analytics.trackEvent('Component_CallToAction', 'print_pdf');

                pdfFromHtml({
                    html: button.includePageTypes.map(pageType => getPageTypeHtml(pageType)).join('\n'),
                    bankSlug,
                });
                break;

            case 'link':
                Analytics.trackEvent('Component_CallToAction', 'link', 'url', button.linkUrl);

                const a = document.createElement('a');
                a.href = button.linkUrl;
                a.target = button.linkTarget;
                document.body.appendChild(a);
                a.click();
                break;

            default:
                throw new Error(`"${button.action}" is an invalid button action.`);
        }

        // finally, fire trigger if available
        const { setTrigger, getEmptyTrigger } = this.props;
        setTrigger(
            {
                ...getEmptyTrigger(),
                ...button.trigger,
            },
            null
        );
    }

    render() {
        const options = this.getOptions();
        return (
            <ul className={`centered-list ${this.state.submitError ? 'd-flex flex-column' : ''}`}>
                {this.state.submitError && (
                    <span className="text-center bg-info border-danger border mb-5 p-2" style={{ width: 'fit-content', alignSelf: 'center' }}>
                        Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Administrator.
                    </span>
                )}
                {options.buttons.map(button => (
                    <li key={button.buttonText}>
                        <Button
                            className={`${button.buttonSize ? 'btn-' + button.buttonSize : ''} d-print-none`}
                            color={button.buttonColor}
                            onClick={() => this.click(button)}
                            withCheck={false}>
                            <ButtonContent {...button} />
                        </Button>
                    </li>
                ))}
            </ul>
        );
    }
}

const ButtonContent = ({ buttonText, iconName, iconPosition }) => (
    <span>
        {iconName && 'left' === iconPosition && <FontAwesomeIcon icon={iconName} />} {buttonText}{' '}
        {iconName && 'right' === iconPosition && <FontAwesomeIcon icon={iconName} />}
    </span>
);
