import { AccountPrice } from './Model/Price';

/**
 * Merges two triggers together
 *
 * @param trigger1
 * @param trigger2
 */
export const mergeTrigger = (trigger1, trigger2) => {
    return {
        ...trigger1,
        ...trigger2,
        price: mergePrice(trigger1.price, trigger2.price),
        summary: {
            ...trigger1.summary,
            ...trigger2.summary,
        },
    };
};

/**
 *
 * @param trigger
 * @returns {*}
 */
export const normalizeTrigger = trigger => {
    if (null === trigger) {
        return null;
    }

    // workaround for price trigger
    if (trigger.cost) {
        if (!trigger.price) {
            trigger.price = new AccountPrice(trigger.cost);
        } else {
            trigger.price.addPrice(new AccountPrice(trigger.cost));
        }
        delete trigger.cost;
    }

    Object.keys(trigger.summary || []).map(key => {
        for (let i = 0; i < trigger.summary[key].length; i++) {
            // only transform "cost" to price. when there is no cost, no transformation will be done
            if (!trigger.summary[key][i].cost) {
                continue;
            }
            trigger.summary[key][i].price = new AccountPrice(trigger.summary[key][i].cost);
            delete trigger.summary[key][i].cost;
        }
    });

    return trigger;
};

/**
 * Adds prices together
 */
const mergePrice = (price1, price2) => {
    if (!price1 && !price2) {
        return undefined;
    } else if (!price2) {
        return price1;
    } else if (!price1) {
        return price2;
    }

    price1.addPrice(price2);
    return price1;
};
