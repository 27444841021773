'use strict';

import $ from 'jquery';
import React, { Component } from 'react';

import { ConstructionFinancingSmall, ConstructionFinancingLarge, ConstructionFinancingLoan } from '../ConstructionFinancing/index';

export default class ConstructionFinancing extends Component {
    componentDidMount() {
        $.getJSON(`/tools/${this.props.match.params.bankSlug}/construction-financing/configuration/`, data => {
            this.setState({ data });
        });
    }

    render() {
        if (null === this.state) {
            return <div />;
        }
        const Tool = {
            large: ConstructionFinancingLarge,
            small: ConstructionFinancingSmall,
            loan: ConstructionFinancingLoan,
        }[this.props.match.params.size];

        return (
            <div className="container mt-2">
                <Tool {...this.state.data} bankSlug={this.props.match.params.bankSlug} defaults={{}} />;
            </div>
        );
    }
}
