import React from 'react';
import FontAwesomeIcon from '../../../components/FontAwesomeIcon';
import AccountPriceView from '../../AccountPriceView';
import InfoPopover from '../../../components/InfoPopover';

import ChoiceInput from './ChoiceInput';
import DetailsInput from './DetailsInput';
import AccountFinderStarsRating from '../../../components/AccountFinderStarsRating';

const colCssClass = gridSize => `col-${gridSize}`;

class ItemRow extends React.Component {
    displayItem(item, accountKey, priceFormat) {
        const checkSign = (
            <div className="d-inline-block" style={{ width: 21.33 }}>
                <FontAwesomeIcon icon="fa-check" size="lg" className="text-success" />
            </div>
        );

        // if there is a "text" and it behaves like acc([...])
        if (item.text && typeof item.text[accountKey] !== 'undefined') {
            return item.text[accountKey] || checkSign;
        }

        // otherwise display a price
        const price = item.price.getPrice(accountKey);

        if (price > -1) {
            return (
                <span>
                    € <AccountPriceView accountPrice={item.price} accountKey={accountKey} format={priceFormat} suffix={true} />
                </span>
            );
        }

        // and if price is -1, we will display a check sign
        if (-1 === price) {
            return checkSign;
        }
        return <div />;
    }

    renderIcon(item) {
        if (item.qty > -1) {
            return <span className="badge badge-secondary">{item.qty}x</span>;
        }

        if (item.downloadLink) {
            return (
                <a href={item.downloadLink} target="_blank" className="d-inline-block" style={{ width: item.downloadLink.startsWith('http') ? '30%' : 21.33 }}>
                    {item.downloadLink.startsWith('http') ? (
                        <img src={item.downloadLink} alt="" className="img-fluid" />
                    ) : (
                        <FontAwesomeIcon icon={item.downloadIcon || 'fa-download'} size="2x" fw={true} className="text-success" />
                    )}
                </a>
            );
        }

        if (item.icon) {
            return item.icon.startsWith('http') ? (
                <div className="d-inline-block" style={{ width: '30%' }}>
                    <img src={item.icon} alt="" className="img-fluid" />
                </div>
            ) : (
                <div className="d-inline-block" style={{ width: 21.33 }}>
                    <FontAwesomeIcon icon={item.icon} size="2x" className="blue" />
                </div>
            );
        }
    }

    renderDetail(item) {
        const { accounts, gridSizeAccount, classAccountAlignment, priceFormat, htmlify } = this.props;
        return accounts.map(account => (
            <div key={account.key} className={`${colCssClass(gridSizeAccount)} ${classAccountAlignment}`}>
                {'string' === typeof item.text && item.text.length > 0 ? (
                    <div>
                        <span dangerouslySetInnerHTML={{ __html: htmlify(item.text.replace(/\n/g, '<br>')) }} /> <InfoPopover>{item.summaryInfo}</InfoPopover>
                    </div>
                ) : (
                    this.displayItem(item, account.key, priceFormat)
                )}
            </div>
        ));
    }

    render() {
        const { item, gridSizeLeft, gridSizeSidebar, sidebarType, ratings, value, setValue, starsAmount } = this.props;
        return (
            <div className="row row--padding">
                <div className={colCssClass(gridSizeLeft)}>
                    <div className="float-right text-sm-right">{this.renderIcon(item)}</div>
                    {item.name} <InfoPopover>{item.info}</InfoPopover>
                </div>

                {this.renderDetail(item)}

                {gridSizeSidebar > 0 && (
                    <div className={colCssClass(gridSizeSidebar)}>
                        <div className="d-print-none">
                            {'stars' === sidebarType && <AccountFinderStarsRating starsAmount={starsAmount} setValue={value => setValue(item.key, value)} />}
                            {'ratings' === sidebarType && <ChoiceInput choices={ratings} setValue={value => setValue(item.key, value)} value={value} />}
                            {'details' === sidebarType && <DetailsInput setValue={value => setValue(item.key, value)} value={value} />}
                        </div>
                        <div className="d-none d-print-block">{value}</div>
                    </div>
                )}
            </div>
        );
    }
}
ItemRow.defaultProps = {
    setValue: (name, value) => {},
};

export default ItemRow;
