'use strict';

import $ from 'jquery';
import React, { Component } from 'react';

import FAQTool from '../FAQ/index';

export default class FAQ extends Component {
    componentDidMount() {
        $.getJSON(`/tools/${this.props.match.params.bankSlug}/faq/configuration/`, data => {
            this.setState({ data });
        });
    }

    render() {
        if (null === this.state) {
            return <div />;
        }

        return (
            <div className="container mt-2">
                <FAQTool {...this.state.data} />
            </div>
        );
    }
}
