'use strict';

import React from 'react';

import Account from './Account';

export default class AccountsPreview extends React.Component {
    render() {
        const { data, prices, values, options } = this.props;

        let accounts = null;
        if (typeof options.accounts === 'function') {
            accounts = options.accounts(values, prices);
        } else {
            accounts = Object.keys(data.accounts).filter(key => !data.accounts[key].hidden);
        }

        accounts = accounts.sort((account1, account2) => (data.accounts[account1].position || 0) - (data.accounts[account2].position || 0));

        // forced keys can come as array and the comparsion fails so we check first if the key is in array or not then go on
        let activeKey = null;
        if (typeof prices.activeKey === 'string') {
            activeKey = prices.activeKey;
        } else {
            activeKey = prices.activeKey[0];
        }

        return (
            <ul className="list-unstyled row justify-content-center align-items-center content-accounts-preview">
                {accounts.map(key => {
                    const account = data.accounts[key];
                    return (
                        <li key={key} className="col-auto">
                            <Account account={account} disabled={activeKey !== key} summary={false} />
                        </li>
                    );
                })}
            </ul>
        );
    }
}
