export const getNextKey = (obj, prefix) => {
    if (0 === Object.keys(obj).length) {
        return `${prefix}_1`;
    }

    // fetch all indexes of object keys
    const keys = Object.keys(obj).map(key => {
        const result = key.match(/(\d+)$/);
        if (result === null) {
            return 0;
        }
        return parseInt(result[1]) || 0;
    });

    const next = Math.max(...keys) + 1;
    return `${prefix}_${next}`;
};
