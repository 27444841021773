'use strict';

import React from 'react';
import classNames from 'classnames';

export default class ImageList extends React.Component {
    render() {
        const { images } = this.props.options;

        return (
            <ul className="centered-list">
                {images.map((image, i) => {
                    const imgHtml = <img className="img-fluid" src={image.src} style={{'display':'inline'}} />;
                    return (
                        <li key={'image_'+i}>
                            {image.href ? <a href={image.href} target="_blank">{imgHtml}</a> : imgHtml}
                        </li>
                    );
                })}
            </ul>
        );
    }
}
