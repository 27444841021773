'use strict';

import React from 'react';
import classNames from 'classnames';
import SliderWithInput from '../../components/SliderWithInput';
import * as Analytics from '../analytics';

const DEFAULT_OPTIONS = {
    min: 0,
    max: 10000,
    step: 100,
    value: 1000,
    marks: { 0: '0 EUR', 2000: '2.000 EUR', 4000: '4.000 EUR', 6000: '6.000 EUR', 8000: '8.000 EUR', 10000: '10.000 EUR' },
    summary: 'extras',
    info: '',
};

export default class Refund extends React.Component {
    constructor(props) {
        super(props);

        const { setTrigger } = this.props;

        // initialize state
        this.state = { value: 0 };
        setTrigger({}, this.state.value);

        // handler to init values
        props.initValues(value => this.setValue(value));
    }

    getOptions() {
        return {
            ...DEFAULT_OPTIONS,
            ...this.props.options,
        };
    }

    setValue(value) {
        this.setState({ value });

        const { setTrigger, getEmptyTrigger } = this.props;
        const options = this.getOptions();

        // set the trigger based upon the selected items
        let trigger = getEmptyTrigger();
        // if summary flag is set, add current values to this summary namespace
        if (false !== options.summary) {
            trigger.summary[options.summary] = [
                {
                    key: 'dispo',
                    name: 'Gewünschter Dispositionskredit',
                    info: options.info,
                    text: `€ ${value.toFixed(2).replace(/\./, ',')}`,
                    price: null,
                },
            ];
        }
        setTrigger(trigger, value);

        Analytics.trackEvent('Component_DispoCredit', 'set_value', 'value', value);
    }

    render() {
        const options = this.getOptions();

        return (
            <div>
                <div className="row">
                    <div className="col-sm-8 col-sm-offset-2">
                        <SliderWithInput
                            min={options.min}
                            max={options.max}
                            step={options.step}
                            marks={options.marks}
                            value={this.state.value}
                            setValue={value => this.setValue(value)}
                            label="Gewünschter Dispositionskredit"
                            labelInfo={options.info}
                            unit="EUR"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
