'use strict';

import React from 'react';

export default class NumberInput extends React.Component {
    inc(by = 1) {
        const value = Math.max(0, parseInt(this.refs.myinput.value) + by);

        this.props.onChange(value);

        this.refs.myinput.value = value;
    }

    render() {
        const { value, onChange, incBy } = this.props;

        return (
            <div className="number-input mx-auto">
                <input ref="myinput" className="form-control" value={value} onChange={(e) => onChange(parseInt(this.refs.myinput.value) || 0)} />
                <div className="updown">
                    <div onClick={() => this.inc(incBy)} className="up"></div>
                    <div onClick={() => this.inc(-1 * incBy)} className="down"></div>
                </div>
            </div>
        );
    }
}
NumberInput.defaultProps = { incBy: 1 };