'use strict';

import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import BoundaryFrame from '../components/BoundaryFrame';
import AccountFinder from './AccountFinder';
import DemandCheck from './DemandCheck';
import ConstructionFinancing from './ConstructionFinancing';
import QuickFinder from './QuickFinder';
import DemandCheck2 from './DemandCheck2';
import FAQ from './FAQ';

export default function app(node, bankLogo, bankUrl, frameConfig, toolsConfig) {
    frameConfig = {
        logo: bankLogo,
        url: bankUrl,
        level1: [],
        level2: toolsConfig,
        level3: [],
        footerLinks: [],
        socialMedia: [],
        ...frameConfig,
    };

    ReactDOM.render(
        <BrowserRouter>
            <BoundaryFrame {...frameConfig}>
                <Switch>
                    <Route path="/_/:bankSlug/account-finder/:accountFinderId/" component={props => <AccountFinder {...props} bankLogo={frameConfig.logo} />} />
                    <Route path="/_/:bankSlug/demand-check/:demandCheckSlug/" component={DemandCheck} />
                    <Route path="/_/:bankSlug/faq/" component={FAQ} />
                    <Route path="/_/:bankSlug/construction-financing/:size/" component={ConstructionFinancing} />
                    <Route path="/_/:bankSlug/quick-finder/:quickFinderSlug/" component={QuickFinder} />
                    <Route path="/_/:bankSlug/demand-check2/:demandCheck2Id/" component={DemandCheck2} />
                    {frameConfig.level2[0] && <Route path="/*" render={() => <Redirect to={frameConfig.level2[0].url} />} />}
                </Switch>
            </BoundaryFrame>
        </BrowserRouter>,
        node
    );
}
