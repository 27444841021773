'use strict';

import React, { Component } from 'react';
import classNames from 'classnames';
import DatePicker from '../../components/DatePicker';
import FontAwesomeIcon from "../../components/FontAwesomeIcon";

const DEFAULT_OPTIONS = {
    fields: {},
    groups: [],
};

const FIELD_OPTIONS = (multiple = false) => ({
    label: '',
    type: 'text',
    required: false,
    help: '',
    value: multiple ? [] : '',
    // display function which enables field if available
    display: values => true,
    // textarea
    rows: 5,
    // options for select
    options: [],
    expanded: false,
    multiple: false,
});

const GROUP_OPTIONS = {
    name: '',
    fields: [],
    // by default, set grid to 12
    grid: 12,
    // display function which enables group if available
    display: values => true,
};

export default class Form extends Component {
    constructor(props) {
        super(props);

        const { setTrigger } = this.props;

        // initialize fields with default value
        const fields = {};
        this.getFields().forEach(field => (fields[field.name] = field.value));

        // initialize state
        this.state = { fields };
        setTrigger({}, this.state.fields);

        // handler to init values
        props.initValues(newFields => {
            // take empty fields
            const fields = {};
            // apply new values on them or default value if available
            this.getFields().forEach(field => (fields[field.name] = newFields[field.name] || field.value));
            // persist
            this.setState({ fields });
            const { setTrigger, getEmptyTrigger } = this.props;
            setTrigger(getEmptyTrigger(), fields);
        });
    }

    setValue(name, value) {
        const field = this.getField(name);

        const { fields } = this.state;

        if (field.multiple) {
            const index = fields[name].indexOf(value);
            if (-1 === index) {
                fields[name].push(value);
            } else {
                fields[name].splice(index, 1);
            }
        } else {
            fields[name] = value;
        }

        this.setState({ fields });

        const { setTrigger, getEmptyTrigger } = this.props;
        // set the trigger based upon the selected items
        setTrigger(getEmptyTrigger(), fields);
    }

    getOptions() {
        return {
            ...DEFAULT_OPTIONS,
            ...this.props.options,
        };
    }

    getField(name) {
        const fields = this.getOptions().fields;
        return {
            name,
            ...FIELD_OPTIONS(fields[name].multiple || false),
            ...fields[name],
        };
    }

    /**
     * @param names
     * @returns {Array}
     */
    getFields(names = []) {
        let fieldNames = names;

        if (0 === names.length) {
            const fields = this.getOptions().fields;
            fieldNames = Object.keys(fields);
        }

        return (
            fieldNames
                // fetch fields
                .map(name => this.getField(name))
                // apply display function on fields
                .filter(field => field.display(this.props.values))
        );
    }

    renderFields(fields) {
        return fields.map(field => (
            <div className="form-group" key={field.name}>
                <label className={classNames({ required: field.required })}>{field.label}</label>
                {renderField(field, this.state.fields[field.name], value => this.setValue(field.name, value))}
                {field.help && <small className="form-text text-muted">{field.help}</small>}
            </div>
        ));
    }

    getGroups() {
        return this.getOptions()
            .groups.map(group => {
                group = {
                    ...GROUP_OPTIONS,
                    ...group,
                };
                return {
                    ...group,
                    fields: this.getFields(group.fields),
                };
            })
            .filter(group => group.display(this.props.values));
    }

    render() {
        // display groups if available
        const groups = this.getGroups();
        if (0 !== groups.length) {
            return (
                <div className="row">
                    {groups.map(group => (
                        <div key={group.name} className={`col-sm-${group.grid} mb-3`}>
                            <div className="card card-body">
                                <h3>{group.name}</h3>
                                {this.renderFields(group.fields)}
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        // otherwise render all fields
        return <div>{this.renderFields(this.getFields())}</div>;
    }
}

const FIELD__TEXT = 'text';
const FIELD__TEXTAREA = 'textarea';
const FIELD__CHOICE = 'choice';
const FIELD__FILE = 'file';
const FIELD__DATE = 'date';

const renderField = (field, value, setValue) => {
    const hoc = Component => <Component {...field} setValue={setValue} value={value} />;
    switch (field.type) {
        case FIELD__TEXT:
            return hoc(TextField);
        case FIELD__TEXTAREA:
            return hoc(TextareaField);
        case FIELD__CHOICE:
            return hoc(ChoiceField);
        case FIELD__FILE:
            return hoc(FileField);
        case FIELD__DATE:
            return hoc(DateField);
    }
};

const TextField = ({ name, type, required, value, setValue }) => (
    <input type={type} className="form-control" required={required ? 'required' : ''} onChange={e => setValue(e.target.value)} value={value} />
);

const TextareaField = ({ name, type, required, value, setValue, rows }) => (
    <textarea className="form-control" required={required ? 'required' : ''} onChange={e => setValue(e.target.value)} value={value} rows={rows} />
);

const FileField = ({ name, type, required, value, setValue }) => (
    <input type="file" className="form-control" required={required ? 'required' : ''} onChange={e => setValue(e.target.value)} />
);

const Picker = ({ value, onClick }) => (
    <button className="btn btn-primary" onClick={onClick}>
        <span>
           {value.length === 0 ? 'Bitte Wählen' : value}
        </span>
        <FontAwesomeIcon icon="fas fa-calendar ml-2" />
    </button>
);

const DateField = ({ name, type, required, value, setValue }) => (
    <DatePicker
        className="form-control"
        selected={value}
        onChange={value => setValue(value)}
        dateFormat="dd.MM.yyyy"
        withPortal
        showYearDropdown
        customInput={<Picker />}
    />
);

const ChoiceField = ({ name, type, required, expanded, multiple, options, value, setValue }) => (
    <div>
        {false === expanded && (
            <select multiple={multiple ? 'multiple' : ''} className="form-control custom-select" onChange={e => setValue(e.target.value)} value={value}>
                {options.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        )}
        {true === expanded &&
            options.map(option => (
                <div key={option} className="form-check">
                    <label className="form-check-label">
                        <input
                            type={multiple ? 'checkbox' : 'radio'}
                            className="form-check-input"
                            name={name}
                            value={value}
                            onChange={e => setValue(option)}
                            checked={multiple ? value.indexOf(option) > -1 : value === option}
                        />{' '}
                        {option}
                    </label>
                </div>
            ))}
    </div>
);
