import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { Btn } from './FormControl';

const iconList = Object.keys(Icons)
    .filter(key => key !== 'fas' && key !== 'prefix')
    .map(icon => Icons[icon]);

library.add(...iconList);

export default class SearchAndSelectIconsOverlay extends React.Component {
    state = {
        isOverlayOpen: false,
        filter: '',
    };

    render() {
        const { setValue, setValuePath, labelTitle, className, currentSelectedIcon } = this.props;
        const { filter } = this.state;

        const toggleOverlay = () => this.setState({ isOverlayOpen: !this.state.isOverlayOpen });

        const setIcon = name => {
            setValue(setValuePath, name);
            toggleOverlay();
        };

        const handleFilterChange = e => this.setState({ filter: e.target.value });

        const filteredIcons = iconList.filter(icon => icon.iconName.includes(filter));

        const getIconLists = () => (filter ? filteredIcons : iconList);

        return (
            <div className={className}>
                <div className="d-flex align-items-start mb-3 mt-3">
                    <button className="btn btn-primary" onClick={() => toggleOverlay()}>
                        {currentSelectedIcon ? (
                            <FontAwesomeIcon icon={currentSelectedIcon.replace(/(fa-|fa[rsb]\s|fa\s)/g, '')} size="3x" />
                        ) : (
                            <span>{labelTitle} auswählen</span>
                        )}
                    </button>
                    {currentSelectedIcon && <Btn delBtn={true} onClick={() => setValue(setValuePath, '')} />}
                </div>
                {this.state.isOverlayOpen ? (
                    <div className="io-wrapper d-flex align-items-center justify-content-center flex-column">
                        <div className="container">
                            <div className="row bg-light mt-2 flex-column">
                                <div className="col-12 mt-2">
                                    <div className="d-flex w-100 align-items-end justify-content-end">
                                        <Btn onClick={() => toggleOverlay()} delBtn={true} />
                                    </div>
                                    <div className="form-group">
                                        <label>Suche:</label>
                                        <input type="text" onChange={handleFilterChange} className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container bg-light io-container">
                            <div className="align-items-baseline justify-content-start io-row">
                                {getIconLists().map(icon => (
                                    <div
                                        key={icon.iconName}
                                        className="col-2 d-flex align-items-center justify-content-between io-icon flex-column"
                                        title={icon.iconName}>
                                        <FontAwesomeIcon onClick={() => setIcon(icon.iconName)} icon={icon.iconName} size="5x" fixedWidth border />
                                        <span className="text-center small">{icon.iconName}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}
