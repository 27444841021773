'use strict';

import React, { Component } from 'react';
import {ConstructionFinancingLoan, ConstructionFinancingLoanResult} from "../../ConstructionFinancing";

export default class ConstructionFinancing extends Component {
    constructor(props) {
        super(props);

        // handler to init values
        props.initValues(values => {
            // values come as strings, needs to be parsed to floats :-/
            Object.keys(values).forEach(key => values[key] = parseFloat(values[key]));
            // to set them then, 1st as values for the element and then push up to global state
            this.element.setValues(values);
            this.setValues(values);
        });
    }

    setValues(values) {
        // push values to top
        const { setTrigger, getEmptyTrigger } = this.props;
        setTrigger(getEmptyTrigger(), values);
    }

    render() {
        return (
            <ConstructionFinancingLoan update={values => this.setValues(values)} ref={element => this.element = element} {...this.props.defaultValue} />
        );
    }

    /**
     * Initializes
     */
    componentDidMount() {
        this.setValues(this.element.getValues());
    }
}

export const Summary = (props) => (
    <ConstructionFinancingLoanResult {...props} />
);
