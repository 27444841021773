const isTrackingAvailable = () => {
    if (typeof(_paq) === 'undefined') {
        console.error('_paq is undefined, no tracking available');
        return false;
    }

    return true;
};

export const trackPageView = (lastPageType, nextPageType, title) => {
    console.log(`track page transition from "${lastPageType}" to "${nextPageType}"`);

    if (!isTrackingAvailable()) {
        return;
    }

    // remove trailing slash if available
    const location = window.location.href.replace(/\/$/, '');

    _paq.push(['setReferrerUrl', `${location}/${lastPageType}`]);
    _paq.push(['setCustomUrl', `${location}/${lastPageType}`]);
    _paq.push(['setDocumentTitle', title]);

    // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
    _paq.push(['deleteCustomVariables', 'page']);
    _paq.push(['setGenerationTimeMs', 0]);
    _paq.push(['trackPageView']);
};

export const trackEvent = (category, action, name = null, value = null) => {
    console.log(`track event "${category}/${action}": ${name||'_NO_NAME_'}=${value}`);

    if (!isTrackingAvailable()) {
        return;
    }

    _paq.push(['trackEvent', category, action, name, value]);
};
