'use strict';

import React from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';

const types = {
    INFO: 'icon-info',
    EURO: 'icon-euro',
};

const style = {
    style: {
        maxWidth: '800px',
    },
    arrowStyle: {},
};

export default class InfoPopover extends React.Component {
    render() {
        const { children, html, type, position = 'top' } = this.props;

        if (!children) {
            return null;
        }

        const iconCssClass = types[(type || '').toUpperCase()];

        return (
            <StatefulToolTip parent={<div className={`${iconCssClass} icon-custom d-print-none`} />} position={position} arrow="center" style={style}>
                {html ? <span dangerouslySetInnerHTML={{ __html: children }} /> : children}
            </StatefulToolTip>
        );
    }
}
InfoPopover.defaultProps = {
    html: true,
    type: 'info',
};
