'use strict';

import React from 'react';
import FontAwesomeIcon from '../components/FontAwesomeIcon';

export default class BtnPrint extends React.Component {
    render() {
        return (
            <button className="btn btn-outline-secondary d-print-none" onClick={() => window.print()}>
                <FontAwesomeIcon icon="fa-print"/> Drucken
            </button>
        );
    }
}
