'use strict';

import React, { Component } from 'react';
import * as Analytics from '../analytics';

import Button from '../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { refactorIconName } from '../../vendor/font-awesome';

const DEFAULT_OPTIONS = {
    onTrue: {},
    onFalse: {},
    yesIcon: 'fa-thumbs-up',
    noIcon: 'fa-thumbs-down',
    trueLabel: null,
    falseLabel: null,
    isSwitch: false,
};

export default class YesNo extends Component {
    constructor(props) {
        super(props);

        this.state = { value: props.defaultValue };
        this.setValue(props.defaultValue);

        // handler to init values
        props.initValues(value => this.setValue(value));
    }

    getOptions() {
        return {
            ...DEFAULT_OPTIONS,
            ...this.props.options,
        };
    }

    setValue(value) {
        const { setTrigger, getEmptyTrigger } = this.props;
        const options = this.getOptions();

        this.setState({ value, switch: value });

        const emptyTrigger = getEmptyTrigger();

        if (value === true) {
            setTrigger(
                {
                    ...emptyTrigger,
                    ...options.onTrue,
                },
                true
            );
            Analytics.trackEvent('Component_YesNo', 'set_value', 'value', 'ja');
        } else if (value === false) {
            setTrigger(
                {
                    ...emptyTrigger,
                    ...options.onFalse,
                },
                false
            );
            Analytics.trackEvent('Component_YesNo', 'set_value', 'value', 'nein');
        } else {
            setTrigger(emptyTrigger);
            Analytics.trackEvent('Component_YesNo', 'set_value', 'value', '');
        }
    }

    render() {
        const { buttonsWithCheck } = this.props;
        const options = this.getOptions();

        return options.isSwitch ? (
            <div className="d-flex justify-content-center align-items-center">
                <div className="switch-button-wrapper">
                    <div className="switch-button-frame">
                        <div className={`switch-button-item is-left is-${this.state.value ? 'active' : ''}`} onClick={() => this.setValue(true)}>
                            {options.trueLabel ? options.trueLabel : 'Ja'}
                        </div>
                        <div className={`switch-button-item is-right is-${!this.state.value ? 'active' : ''}`} onClick={() => this.setValue(false)}>
                            {options.falseLabel ? options.falseLabel : 'Nein'}
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <ul className="centered-list">
                <li>
                    <Button className="btn-rounded" active={this.state.value === true} onClick={() => this.setValue(true)} withCheck={buttonsWithCheck}>
                        {options.yesIcon.startsWith('http') ? (
                            <img src={options.yesIcon} alt="" className="img-fluid" />
                        ) : (
                            <FontAwesomeIcon icon={refactorIconName(options.yesIcon)} size="5x" className="blue" />
                        )}
                        <br />
                        <br />
                        {options.trueLabel ? options.trueLabel : 'Ja'}
                    </Button>
                </li>
                <li>
                    <Button className="btn-rounded" active={this.state.value === false} onClick={() => this.setValue(false)} withCheck={buttonsWithCheck}>
                        {options.noIcon.startsWith('http') ? (
                            <img src={options.noIcon} alt="" className="img-fluid" />
                        ) : (
                            <FontAwesomeIcon icon={refactorIconName(options.noIcon)} size="5x" className="blue" />
                        )}
                        <br />
                        <br />
                        {options.falseLabel ? options.falseLabel : 'Nein'}
                    </Button>
                </li>
            </ul>
        );
    }
}
