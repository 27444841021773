import React from 'react';

export default ({icon, size = null, fw = false, spin = false, className = ''}) => {
    if (!(icon.includes('fas') || icon.includes('fab') || icon.includes('far'))) {
        // remove single fa icon
        icon = `fas ${icon.replace(/fa($|[^-])/, '$1')}`;
    }

    const props = {
        className: `${icon} ${size ? 'fa-'+size : ''} ${fw ? 'fa-fw': ''} ${spin ? 'fa-spin': ''} ${className}`,
    };

    return <span {...props} />;
};
