'use strict';

import React from 'react';
import classNames from 'classnames';
import SliderWithInput from '../../components/SliderWithInput';
import * as Analytics from '../analytics';

const DEFAULT_OPTIONS = {
    min: 0,
    max: 10,
    step: 1,
    value: 1,
    marks: {},
    summary: 'extras',
    info: '',
    label: '',
    unit: '',
};

export default class Slider extends React.Component {
    constructor(props) {
        super(props);

        const { setTrigger } = this.props;

        // initialize state
        this.state = { value: this.getOptions().value };
        setTrigger({}, this.state.value);

        // handler to init values
        props.initValues(value => this.setValue(value));
    }

    getOptions() {
        return {
            ...DEFAULT_OPTIONS,
            ...this.props.options,
        };
    }

    setValue(value) {
        this.setState({ value });

        const { setTrigger, getEmptyTrigger } = this.props;
        const options = this.getOptions();

        // set the trigger based upon the selected items
        let trigger = getEmptyTrigger();
        // if summary flag is set, add current values to this summary namespace
        if (false !== options.summary) {
            trigger.summary[options.summary] = [
                {
                    key: 'slider',
                    name: options.label,
                    info: options.info,
                    text: `${value} ${options.unit}`,
                    price: null,
                },
            ];
        }
        setTrigger(trigger, value);

        Analytics.trackEvent('Component_Slider', 'set_value', 'value', value);
    }

    render() {
        const options = this.getOptions();

        return (
            <div className="row justify-content-center">
                <div className="col-sm-9">
                    <SliderWithInput
                        min={options.min}
                        max={options.max}
                        step={options.step}
                        marks={options.marks}
                        value={this.state.value}
                        setValue={value => this.setValue(value)}
                        label={options.label}
                        labelInfo={options.info}
                        unit={options.unit}
                    />
                </div>
            </div>
        );
    }
}
