import React from 'react';
import { FormControl, getChoices } from '../../Helper/FormControl';
import TypedNumberInput from '../../../../components/TypedNumberInput';

export const DEFAULT_TRIGGER = {
    key: '',
    name: '',
    info: '',
    qty: -1,
    text: '',
};

export const trigger = (schema, path, setValue, choices) => {
    let cost = {};
    getChoices(choices.accounts).forEach(account => {
        cost = {
            ...cost,
            [account.value]: 0,
        };
    });
    setValue(`${path}`, {
        summary: {
            [schema]: [
                {
                    ...DEFAULT_TRIGGER,
                    cost,
                },
            ],
        },
    });
};

export const DefaultTriggerOptions = ({ schema, i, data, setValue, globalChoices, path, currentChoice }) => {
    const currentSchema = data.choices[currentChoice].trigger.summary[schema][i];
    return (
        <React.Fragment key={schema}>
            <div className="row align-items-baseline">
                <div className="col-3">
                    <FormControl
                        label="Name"
                        setValue={value => setValue(`choices.${path}.summary.${schema}`, [{ ...currentSchema, name: value, key: `${value}_key` }])}
                        value={currentSchema.name}
                    />
                </div>
                <div className="col-3">
                    <FormControl
                        label="Info"
                        setValue={value => setValue(`choices.${path}.summary.${schema}`, [{ ...currentSchema, info: value }])}
                        value={currentSchema.info}
                    />
                </div>
                <div className="col-3">
                    {/*eventuell fliegt das hier noch raus*/}
                    <TypedNumberInput
                        label="Quantity"
                        setValue={value => setValue(`choices.${path}.summary.${schema}`, [{ ...currentSchema, qty: value }])}
                        value={currentSchema.qty}
                    />
                </div>
                <div className="col-3">
                    <FormControl
                        label="Summarytext (Note: wenn das Feld leer bleibt wird der Preis angezeigt!)"
                        setValue={value => setValue(`choices.${path}.summary.${schema}`, [{ ...currentSchema, text: value }])}
                        value={currentSchema.text}
                    />
                </div>
            </div>
            <div className="row align-items-baseline mt-2">
                <div className="col-12">
                    <h3>Preise fuer Konten</h3>
                </div>
                {getChoices(globalChoices.accounts).map(acc => (
                    <div className="col-2">
                        <TypedNumberInput
                            key={acc.value}
                            label={`kosten fuer ${acc.label}`}
                            setValue={value =>
                                setValue(`choices.${path}.summary.${schema}`, [{ ...currentSchema, cost: { ...currentSchema.cost, [acc.value]: value } }])
                            }
                            value={currentSchema.cost[acc.value]}
                        />
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};
export const OnTrueOptions = ({ schema, i, data, setValue, choices }) => {
    const currentSchema = data.onTrue.summary[schema][i];
    return (
        <React.Fragment key={i}>
            <div className="row align-items-baseline">
                <div className="col-3">
                    <FormControl
                        label="Name"
                        setValue={value => setValue(`onTrue.summary.${schema}`, [{ ...currentSchema, name: value, key: `${value}_key` }])}
                        value={currentSchema.name}
                    />
                </div>
                <div className="col-3">
                    <FormControl
                        label="Info"
                        setValue={value => setValue(`onTrue.summary.${schema}`, [{ ...currentSchema, info: value }])}
                        value={currentSchema.info}
                    />
                </div>
                {/*eventuell fliegt das hier noch raus*/}
                <div className="col-3">
                    <TypedNumberInput
                        label="Quantity"
                        setValue={value => setValue(`onTrue.summary.${schema}`, [{ ...currentSchema, qty: value }])}
                        value={currentSchema.qty}
                    />
                </div>
                <div className="col-3">
                    <FormControl
                        label="Summarytext (Note: wenn das Feld leer bleibt wird der Preis angezeigt!)"
                        setValue={value => setValue(`onTrue.summary.${schema}`, [{ ...currentSchema, text: value }])}
                        value={currentSchema.text}
                    />
                </div>
            </div>
            <div className="row align-items-baseline mt-2">
                <div className="col-12">
                    <h3>Preise fuer Konten</h3>
                </div>
                {getChoices(choices.accounts).map(acc => (
                    <div className="col-2" key={acc.value}>
                        <TypedNumberInput
                            label={`kosten fuer ${acc.label}`}
                            setValue={value =>
                                setValue(`onTrue.summary.${schema}`, [{ ...currentSchema, cost: { ...currentSchema.cost, [acc.value]: value } }])
                            }
                            value={currentSchema.cost[acc.value]}
                        />
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};
export const OnFalseOptions = ({ schema, i, data, setValue, choices }) => {
    const currentSchema = data.onFalse.summary[schema][i];
    return (
        <React.Fragment key={i}>
            <div className="row align-items-baseline">
                <div className="col-3">
                    <FormControl
                        label="Name"
                        setValue={value => setValue(`onFalse.summary.${schema}`, [{ ...currentSchema, name: value, key: `${value}_key` }])}
                        value={currentSchema.name}
                    />
                </div>
                <div className="col-3">
                    <FormControl
                        label="Info"
                        setValue={value => setValue(`onFalse.summary.${schema}`, [{ ...currentSchema, info: value }])}
                        value={currentSchema.info}
                    />
                </div>
                <div className="col-3">
                    <TypedNumberInput
                        label="Quantity"
                        setValue={value => setValue(`onFalse.summary.${schema}`, [{ ...currentSchema, qty: value }])}
                        value={currentSchema.qty}
                    />
                </div>
                <div className="col-3">
                    <FormControl
                        label="Summarytext (Note: wenn das Feld leer bleibt wird der Preis angezeigt!)"
                        setValue={value => setValue(`onFalse.summary.${schema}`, [{ ...currentSchema, text: value }])}
                        value={currentSchema.text}
                    />
                </div>
            </div>
            <div className="row align-items-baseline mt-2">
                <div className="col-12">
                    <h3>Preise fuer Konten</h3>
                </div>
                {getChoices(choices.accounts).map(acc => (
                    <div className="col-2" key={acc.value}>
                        <TypedNumberInput
                            label={`kosten fuer ${acc.label}`}
                            setValue={value =>
                                setValue(`onFalse.summary.${schema}`, [{ ...currentSchema, cost: { ...currentSchema.cost, [acc.value]: value } }])
                            }
                            value={currentSchema.cost[acc.value]}
                        />
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};
