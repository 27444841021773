'use strict';

import React, { Component } from 'react';

import { AccountPrice } from '../Model/Price';
import InfoPopover from '../../components/InfoPopover';
import Button from '../../components/Button';
import { mergeTrigger, normalizeTrigger } from '../trigger';
import * as Analytics from '../analytics';
import { refactorIconName } from '../../vendor/font-awesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Field from './IconChoiceAddonFields';

import * as PropTypes from 'prop-types';

const LAYOUT_LIST = 'list';
const LAYOUT_ICON = 'icon';
const LAYOUT_HTML = 'html';
const ADDON_CHOICETYPE_RADIO = 'radio';
const ADDON_CHOICETYPE_CHECKBOX = 'checkbox';
const ADDON_CHOICETYPE_SELECT = 'select';
const SELECTED_ADDON_TYPE_INPUT = 'input';
const SELECTED_ADDON_TYPE_CHOICE = 'choice';

const DEFAULT_OPTIONS = {
    multiple: false,
    groups: [],
    choices: [],
    summary: false,
    clickTriggersPageChange: false,
};

const CHOICE_OPTIONS = {
    // name of the choice
    name: '',
    // what gets displayed in the info icon
    info: '',
    // icon choice is capable of own html implementation
    html: '',
    // download information
    downloadLink: '',
    downloadIcon: '',
    // display this value on summary page @todo refactor/rename this
    summary: null,
    // overwrite summary from DEFAULT_OPTIONS on item basis
    summaryCategory: null,
    // detail information in a info icon used on summary
    summaryInfo: '',
    // the trigger which gets triggered on
    trigger: null,
    // get only used for calculation
    cost: {},
    supplemental: '',
    // by default, the display function always returns true to display the item
    display: values => true,
    // displays on summary on the left side
    summaryLeft: null,
    // displays on summary on the left side
    summaryRight: null,
    // when the icon is chosen, there can be an additional form field to enter some more values
    // selectedAddon: {
    //     label: '',
    //     type: '',
    //     typeOptions: {},
    // },
    selectedAddon: null,
};

export default class IconChoice extends React.Component {
    constructor(props) {
        super(props);

        const { defaultValue, setTrigger } = this.props;

        this.state = {
            value: defaultValue !== null && defaultValue !== undefined ? defaultValue : this.getOptions().multiple ? [] : null,
            valueAddon: {},
        };

        setTrigger({}, this.state.value);

        // handler to init values
        props.initValues((value, addon = {}) => {
            this.reset(true);
            this.state.valueAddon = addon;
            if (typeof value === 'string') {
                this.setValue(value);
            } else {
                // on array, each value has to be set on its own
                value.forEach(v => this.setValue(v));
            }
        });
    }

    getOptions() {
        const { options } = this.props;
        return {
            ...DEFAULT_OPTIONS,
            groups: [
                {
                    grid: 12,
                    choices: Object.keys(options.choices),
                },
            ],
            ...options,
        };
    }

    reset(force = false) {
        const { defaultValue, setTrigger, setTriggerAddon } = this.props;
        const options = this.getOptions();
        if (force) {
            this.state.value = options.multiple ? [] : '';
        } else {
            this.state.value = defaultValue;
        }
        this.state.valueAddon = {};
        this.setState(this.state);

        setTrigger({}, this.state.value);
        setTriggerAddon({}, this.state.addonValue);
    }

    setValueUser(value) {
        this.setValue(value);
        if (this.getOptions().clickTriggersPageChange && this.props.pageNext() != null) {
            this.props.gotoNextPage();
        }
    }

    setValue(value) {
        const options = this.getOptions();

        if (options.multiple) {
            if (!this.hasValue(value)) {
                this.state.value.push(value);
            } else {
                this.state.value.splice(this.state.value.indexOf(value), 1);
            }
        } else {
            if (!this.hasValue(value)) {
                this.state.value = value;
            } else {
                this.state.value = null;
            }
        }

        this.handleTriggers();
    }

    setAddonValue(key, value) {
        this.state.valueAddon[key] = value;
        this.handleTriggers();
    }

    getAddonValue(key, defaultValue = '') {
        if (null === this.state.valueAddon) {
            return defaultValue;
        }

        return this.state.valueAddon[key] || defaultValue;
    }

    handleTriggers() {
        const { setTrigger, setTriggerAddon, getEmptyTrigger, data } = this.props;
        const options = this.getOptions();

        // set the trigger based upon the selected items
        let trigger = getEmptyTrigger();
        // if summary flag is set, add current values to this summary namespace
        if (false !== options.summary) {
            trigger.summary[options.summary] = [];
        }
        Object.keys(options.choices).forEach(choiceKey => {
            if (!this.hasValue(choiceKey)) {
                return;
            }

            const choiceOption = {
                ...CHOICE_OPTIONS,
                ...options.choices[choiceKey],
            };
            const choiceTrigger = normalizeTrigger(choiceOption.trigger) || getEmptyTrigger();
            trigger = mergeTrigger(trigger, choiceTrigger);

            // if summary flag is set, add current values to this summary namespace
            let minusOnePrice = {};
            Object.keys(data.accounts).forEach(accountId => (minusOnePrice[accountId] = -1));

            let summaryKey = null;
            // then check if summaryCategory on item is set, then take this
            if (choiceOption.summaryCategory !== null) {
                // set summaryCategory to false to ignore
                if (false !== choiceOption.summaryCategory) {
                    summaryKey = choiceOption.summaryCategory;
                    // create key if it does not exist
                    if (typeof trigger.summary[summaryKey] === 'undefined') {
                        trigger.summary[summaryKey] = [];
                    }
                }
            } else if (options.summary) {
                // first check if global summary is set, then take this
                summaryKey = options.summary;
            }
            if (null !== summaryKey) {
                const addonReplacement = this.getAddonValue(choiceKey);
                const text = choiceOption.summaryRight || choiceOption.summary;
                trigger.summary[summaryKey].push({
                    key: choiceKey,
                    name: choiceOption.summaryLeft || choiceOption.name,
                    info: choiceOption.info,
                    price: new AccountPrice(minusOnePrice),
                    text: typeof text === 'string' ? text.replace('%addon%', addonReplacement) : text,
                    icon: choiceOption.icon,
                    summaryInfo: choiceOption.summaryInfo.replace('%addon%', addonReplacement),
                    downloadLink: choiceOption.downloadLink,
                    downloadIcon: choiceOption.downloadIcon,
                });
            }
        });

        this.setState(this.state);
        setTrigger(trigger, this.state.value);
        setTriggerAddon(getEmptyTrigger(), this.state.valueAddon);

        Analytics.trackEvent('Component_IconChoice', 'set_value', 'value', options.multiple ? this.state.value.join(',') : this.state.value);
    }

    hasValue(value) {
        if (true === this.getOptions().multiple) {
            return this.state.value.indexOf(value) > -1;
        } else {
            return this.state.value === value;
        }
    }

    getListLayout() {
        const options = this.getOptions();

        if ([LAYOUT_ICON, LAYOUT_LIST, LAYOUT_HTML].indexOf(options.layout) > -1) {
            return options.layout;
        }

        // fallback when list layout is not used
        return Object.keys(options.choices).length <= 5 ? LAYOUT_ICON : LAYOUT_LIST;
    }

    getChoices(choices) {
        const { options, values } = this.props;

        return (
            choices
                .map(choiceKey => ({
                    choiceKey,
                    ...CHOICE_OPTIONS,
                    ...options.choices[choiceKey],
                    hasValue: () => this.hasValue(choiceKey),
                    setValue: () => this.setValueUser(choiceKey),
                    setAddonValue: value => this.setAddonValue(choiceKey, value),
                    addonValue: this.getAddonValue(choiceKey),
                }))
                // display only when display function allows it
                .filter(choice => choice.display(values))
        );
    }

    render() {
        // multiple, choices, value, setValue
        const { prices, buttonsWithCheck } = this.props;
        const options = this.getOptions();

        const ListLayout = {
            [LAYOUT_ICON]: LayoutIcon,
            [LAYOUT_LIST]: LayoutList,
            [LAYOUT_HTML]: LayoutHtml,
        }[this.getListLayout()];

        return (
            <div className="container-fluid">
                <div className="row justify-content-center">
                    {options.groups.map((group, i) => (
                        <ListLayout
                            choices={this.getChoices(group.choices)}
                            prices={prices}
                            buttonsWithCheck={buttonsWithCheck}
                            clickTriggersPageChange={options.clickTriggersPageChange}
                            group={group}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

const LayoutIcon = ({ choices, buttonsWithCheck, clickTriggersPageChange, group }) =>
    choices.map(choice => (
        <div className={`mb-3 ${group.grid !== 12 ? 'd-flex justify-content-center col-sm-' + group.grid : 'col-auto'}`} key={choice.choiceKey}>
            <Button
                className="btn-rounded"
                active={!clickTriggersPageChange && choice.hasValue()}
                onClick={() => choice.setValue()}
                withCheck={buttonsWithCheck}>
                {choice.icon.startsWith('http') ? (
                    <img src={choice.icon} alt="" className="img-fluid" />
                ) : (
                    <FontAwesomeIcon icon={refactorIconName(choice.icon)} size="4x" className="blue" />
                )}
                <small className="d-block mt-2" dangerouslySetInnerHTML={{ __html: choice.name.replace('\n', '<br/>') }} />
                <ChoiceInfoIcons choice={choice} />
            </Button>
            {choice.selectedAddon !== null && choice.hasValue() && <ChoiceSelectedAddon layout={LAYOUT_ICON} choice={choice} />}
        </div>
    ));

const LayoutList = ({ choices, prices, clickTriggersPageChange, group }) => (
    <div className={'col-sm-' + group.grid}>
        <table className="table table-striped" style={{ cursor: 'pointer' }}>
            <tbody>
                {choices.map(choice => {
                    // fetch supplemental text, fallback to summary
                    let supplementalText = choice.supplemental || choice.summary || '';
                    if (typeof supplementalText[prices.activeKey] !== 'undefined') {
                        supplementalText = supplementalText[prices.activeKey] || '';
                    }

                    return (
                        <React.Fragment key={choice.choiceKey}>
                            <tr onClick={() => choice.setValue()}>
                                <td style={{ width: 20 }} className="blue">
                                    <div className={!clickTriggersPageChange && choice.hasValue() ? '' : 'd-none'}>
                                        <FontAwesomeIcon icon="check-circle" size="2x" />
                                    </div>
                                    <div className={!clickTriggersPageChange && choice.hasValue() ? 'd-none' : ''}>
                                        <FontAwesomeIcon icon="circle" size="2x" />
                                    </div>
                                </td>
                                <td colSpan={supplementalText ? 1 : 2}>
                                    {choice.name} <ChoiceInfoIcons choice={choice} />
                                </td>
                                {supplementalText.length > 0 && (
                                    <td className="text-sm-center" style={{ width: '30%' }}>
                                        {supplementalText}
                                    </td>
                                )}
                            </tr>
                            {choice.selectedAddon !== null && choice.hasValue() && (
                                <tr>
                                    <td></td>
                                    <td colSpan={supplementalText ? 1 : 2}>
                                        <ChoiceSelectedAddon layout={LAYOUT_LIST} choice={choice} />
                                    </td>
                                    {supplementalText.length > 0 && <td />}
                                </tr>
                            )}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table>
    </div>
);

const LayoutHtml = ({ choices, buttonsWithCheck, clickTriggersPageChange }) => (
    <div className="col-md-auto col-12">
        {choices.map(choice => (
            <div className="row justify-content-center mb-1" key={choice.choiceKey}>
                <div className="col-auto text-right">
                    <Button
                        className={`btn-rounded-small ${choice.icon.startsWith('http') ? 'p-0' : ''}`}
                        active={!clickTriggersPageChange && choice.hasValue()}
                        onClick={() => choice.setValue()}
                        withCheck={buttonsWithCheck}>
                        {choice.icon.startsWith('http') ? (
                            <img src={choice.icon} alt="" className="img-fluid" />
                        ) : (
                            <FontAwesomeIcon icon={refactorIconName(choice.icon)} size="2x" className="blue" />
                        )}
                    </Button>
                </div>
                <div className="col">
                    <span dangerouslySetInnerHTML={{ __html: choice.html.replace('\n', '<br/>') }} /> <ChoiceInfoIcons choice={choice} />
                </div>
                <div className="col-12">
                    {choice.selectedAddon !== null && choice.hasValue() && <ChoiceSelectedAddon layout={LAYOUT_HTML} choice={choice} />}
                </div>
            </div>
        ))}
    </div>
);

const ChoiceInfoIcons = ({ choice }) => (
    <span>
        {choice.info && <InfoPopover>{choice.info}</InfoPopover>}
        {choice.info && choice.euro && ' '}
        {choice.euro && <InfoPopover type="euro">{choice.euro}</InfoPopover>}
    </span>
);

class InputType extends Component {
    componentWillUnmount() {
        this.props.choice.setAddonValue('');
    }

    render() {
        const { choice, type, typeOptions } = this.props;

        switch (type) {
            case ADDON_CHOICETYPE_SELECT:
                return <Field.SelectField typeOptions={typeOptions} choice={choice} />;
            case ADDON_CHOICETYPE_RADIO:
                return <Field.RadioField type={type} choice={choice} typeOptions={typeOptions} />;
            case ADDON_CHOICETYPE_CHECKBOX:
                return <Field.CheckBoxField type={type} choice={choice} typeOptions={typeOptions} />;
            case SELECTED_ADDON_TYPE_INPUT:
                return <Field.InputField choice={choice} typeOptions={typeOptions} />;
            default:
                return <span>type not found!</span>;
        }
    }
}

InputType.propTypes = {
    choice: PropTypes.object,
    type: PropTypes.string,
};

const ChoiceSelectedAddon = ({ choice, layout }) => {
    const { type, typeOptions, help, label } = choice.selectedAddon;

    const getAddonType = () => {
        switch (type) {
            case SELECTED_ADDON_TYPE_INPUT:
                return SELECTED_ADDON_TYPE_INPUT;

            case SELECTED_ADDON_TYPE_CHOICE:
                if (!typeOptions.expanded) {
                    return ADDON_CHOICETYPE_SELECT;
                } else {
                    return typeOptions.multiple ? ADDON_CHOICETYPE_CHECKBOX : ADDON_CHOICETYPE_RADIO;
                }
        }
    };

    return (
        <div className={`${layout === LAYOUT_LIST ? 'mt-0' : 'mt-3'}`}>
            <div className="d-flex flex-column mb-2">
                <label className="mr-2" htmlFor={`${choice.choiceKey}_addon`}>
                    {label}
                </label>
                <div className={`icon-choice-addon-${layout} d-flex`}>
                    <InputType typeOptions={typeOptions} choice={choice} type={getAddonType()} />
                </div>
                {help && <small className="form-text text-muted">{help}</small>}
            </div>
        </div>
    );
};
