'use strict';

import React from 'react';
import ReactDOM from 'react-dom';

import AccountFinder from './AccountFinder/index';
import AccountFinderConfigTool from './AccountFinder/Config/ConfigTool';
import { ConstructionFinancingSmall, ConstructionFinancingLarge, ConstructionFinancingLoan } from './ConstructionFinancing/index';
import DemandCheck from './DemandCheck/index';
import DemandCheck2 from './DemandCheck2/index';
import FAQ from './FAQ/index';
import QuickFinder from './QuickFinder/index';
import './iframe-height-adjustment';
import './vendor';

import ToolsApplication from './ToolsApplication/index';

import moment from 'moment';
import de from 'moment/locale/de';
moment.locale('de');

import '../scss/flat.scss';

import 'babel-polyfill';

global.AccountFinder = (node, accountFinderId, bankSlug, data = {}) =>
    ReactDOM.render(<AccountFinder accountFinderId={accountFinderId} bankSlug={bankSlug} {...data} />, node);
global.AccountFinderConfigTool = (node, data = {}) => ReactDOM.render(<AccountFinderConfigTool {...data} />, node);

global.ConstructionFinancingSmall = (node, data = {}, defaults = {}) => ReactDOM.render(<ConstructionFinancingSmall {...data} defaults={defaults} />, node);
global.ConstructionFinancingLarge = (node, data = {}, defaults = {}) => ReactDOM.render(<ConstructionFinancingLarge {...data} defaults={defaults} />, node);
global.ConstructionFinancingLoan = (node, data = {}, defaults = {}) => ReactDOM.render(<ConstructionFinancingLoan {...data} defaults={defaults} />, node);
global.DemandCheck = (node, data = {}) => ReactDOM.render(<DemandCheck {...data} />, node);
global.DemandCheck2 = (node, data = {}) => ReactDOM.render(<DemandCheck2 {...data} />, node);
global.FAQ = (node, data = {}) => ReactDOM.render(<FAQ {...data} />, node);
global.QuickFinder = (node, data = {}) => ReactDOM.render(<QuickFinder {...data} />, node);
global.ToolsApplication = ToolsApplication;
