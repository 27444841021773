import React from 'react';
import { Btn, FormControl, PriceRow } from './Helper/FormControl';
import { getNextKey } from './Helper/nextKey';

const EMPTY_VALUE = {
    name: '',
    headerImg: '',
    footerImg: '',
    price: {
        cost: 0,
        priceFormat: 'monthly',
    },
};

export default ({ data, setValue, removeKey }) => {
    return (
        <div>
            {getServicePackages(data.servicePackages).map(servicePackage => (
                <div key={servicePackage.key} className="card mb-2">
                    <div className="card-body border-bottom">
                        <div className="row">
                            <div className="col">
                                <h2>{servicePackage.name}</h2>
                            </div>
                            <div className="col-auto">
                                <Btn delBtn={true} onClick={() => removeKey(servicePackage.key)} />
                            </div>
                        </div>
                    </div>

                    <div className="card-body border-bottom">
                        <FormControl label="Name" value={servicePackage.name} setValue={value => setValue(`${servicePackage.key}.name`, value)} />
                        <FormControl
                            label="Headerbild-URL"
                            value={servicePackage.headerImg}
                            setValue={value => setValue(`${servicePackage.key}.headerImg`, value)}
                        />
                        <FormControl
                            label="Footerbild-URL"
                            value={servicePackage.footerImg}
                            setValue={value => setValue(`${servicePackage.key}.footerImg`, value)}
                        />
                    </div>
                    <PriceRow setValue={setValue} data={data} item={servicePackage} />
                </div>
            ))}
            <button className="btn btn-outline-secondary mb-5" onClick={() => addServicePackage(setValue, data)}>
                Servicepaket hinzufügen
            </button>
        </div>
    );
};

const getServicePackages = servicePackages =>
    Object.keys(servicePackages).map(key => ({
        key,
        ...servicePackages[key],
    }));

const addServicePackage = (setValue, data) => {
    const nextKey = getNextKey(data.servicePackages, `sp`);
    setValue(`${nextKey}`, {
        ...data.servicePackages,
        ...EMPTY_VALUE,
        price: {
            cost: 0,
            priceFormat: 'monthly',
        },
    });
};
