'use strict';

import React, { Component } from 'react';
import $ from 'jquery';
import classNames from 'classnames';
import * as Analytics from '../analytics';

const DEFAULT_OPTIONS = {
    recipient: '',
    subject: '',
    required: ['name', 'email'],
    includePageTypes: [],
    disclaimer:
        'Die Speicherung und Weiterverarbeitung Ihrer vorstehend erhobenen personenbezogenen Daten dienen ausschließlich der ordnungsgemäßen Bearbeitung Ihrer Anfrage. Jedwede anderweitige Nutzung ist ausgeschlossen. Nach Beantwortung Ihrer Anfrage werden Ihre Daten gelöscht. Bitte beachten Sie im Übrigen auch unseren Datenschutzhinweis. Zu Ihrer Sicherheit werden Ihre Daten SSL-verschlüsselt übertragen.',
    disclaimerLinks: {},
    privacy: '',
    withAccountField: false,
    submitText: 'Anfrage abschicken',
    submittedResponseText: 'Vielen Dank, wir haben Ihre Nachricht erhalten. Wir melden uns in Kürze bei Ihnen!',
    submittedResponseImage: true,
};

export default class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            submitError: false,
        };

        this.fields = {};
    }

    getOptions() {
        const { options, refCode } = this.props;
        return {
            ...DEFAULT_OPTIONS,
            ...options,
            refCode,
            disclaimerLinks: {
                // mimic old behavior: in default disclaimer, the word to replace is "Datenschutzhinweis" with the url coming from "privacy"
                Datenschutzhinweis: options.privacy,
                ...options.disclaimerLinks,
            },
        };
    }

    getDisclaimerHtml() {
        const options = this.getOptions();

        let disclaimer = options.disclaimer;

        Object.keys(options.disclaimerLinks).forEach(word => {
            const link = options.disclaimerLinks[word];
            disclaimer = disclaimer.replace(word, `<a href="${link}" target="_blank">${word}</a>`);
        });

        return disclaimer;
    }

    submit(e) {
        e.stopPropagation();
        e.preventDefault();

        const options = this.getOptions();

        const html = options.includePageTypes.map(pageType => this.props.getPageTypeHtml(pageType));

        const recipient = options.refCode ? options.refCode.email : options.recipient;

        let contactData = {
            html: html,
            contact: {
                name: this.fields['name'].value,
                street: this.fields['street'].value,
                city: this.fields['city'].value,
                phone: this.fields['phone'].value,
                email: this.fields['email'].value,
                remark: this.fields['remark'].value,
            },
            mail: {
                to: recipient,
                subject: options.subject,
            },
        };

        if (options.withAccountField === true) {
            contactData.contact.account = this.fields['account'].value;
        }

        $.post(`/tools/${this.props.bankSlug}/contact/`, contactData, response => {
            if (response.success) {
                this.setState({ submitted: true, submitError: false });
            } else {
                this.setState({ submitted: false, submitError: true });
            }
        });

        Analytics.trackEvent('Component_ContactForm', 'submit');
    }

    render() {
        const { submitted, submitError } = this.state;

        const options = this.getOptions();

        const reffunc = refname => input => (this.fields[refname] = input);

        return (
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                    {submitted && (
                        <div className="d-flex flex-column justify-content-center">
                            <span className="text-center">{options.submittedResponseText}</span>
                            {options.submittedResponseImage && <img src={options.bankLogo} alt="BankLogo" className="img-fluid mt-3" />}
                        </div>
                    )}
                    {submitError && (
                        <div className="d-flex flex-column justify-content-center">
                            <span className="text-center bg-info border-danger border mb-5">
                                Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Administrator.
                            </span>
                        </div>
                    )}
                    {!submitted && (
                        <form onSubmit={e => this.submit(e)} className="contact-form">
                            <ContactFormField
                                label="Vor- und Nachname"
                                type="text"
                                reffunc={reffunc('name')}
                                required={options.required.indexOf('name') > -1}
                            />
                            <ContactFormField
                                label="Straße/Hausnummer"
                                type="text"
                                reffunc={reffunc('street')}
                                required={options.required.indexOf('street') > -1}
                            />
                            <ContactFormField label="PLZ/Ort" type="text" reffunc={reffunc('city')} required={options.required.indexOf('city') > -1} />
                            <ContactFormField label="Telefon" type="text" reffunc={reffunc('phone')} required={options.required.indexOf('phone') > -1} />
                            <ContactFormField label="E-Mail" type="email" reffunc={reffunc('email')} required={options.required.indexOf('email') > -1} />
                            {options.withAccountField && (
                                <ContactFormField
                                    label="Kontonummer"
                                    type="text"
                                    reffunc={reffunc('account')}
                                    required={options.required.indexOf('account') > -1}
                                />
                            )}
                            <ContactFormField
                                label="Bemerkung"
                                type="textarea"
                                reffunc={reffunc('remark')}
                                required={options.required.indexOf('remark') > -1}
                            />
                            <div className="form-group">
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" required="required" /> <span dangerouslySetInnerHTML={{ __html: this.getDisclaimerHtml() }} />
                                    </label>
                                </div>
                            </div>

                            <div className="form-group text-sm-right mt-2">
                                <button type="submit" className="btn btn-success">
                                    {options.submitText}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        );
    }
}

let fieldCounter = 0;

class ContactFormField extends React.Component {
    render() {
        const { label, type, required, reffunc } = this.props;

        const fieldId = 'field_' + fieldCounter++;

        return (
            <div className="form-group row">
                <label htmlFor={fieldId} className={classNames('col-sm-4', 'form-control-label', { required: required })}>
                    {label}
                </label>
                <div className="col-sm-8">
                    {type !== 'textarea' && <input type={type} id={fieldId} className="form-control" required={required ? 'required' : ''} ref={reffunc} />}
                    {type === 'textarea' && <textarea id={fieldId} className="form-control" required={required ? 'required' : ''} ref={reffunc} />}
                </div>
            </div>
        );
    }
}
